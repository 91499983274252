import { Link, styled } from '@mui/material';
import { User } from '../../../types';
import {
  hasDisplayProperty,
  isBusinessType,
  isPerspectiveClass,
  isStringALink,
} from '../ItemPropertiesUtil';
import { BusinessTypeTemplate } from '../../grid/templates/BusinesssTypeTemplate';
import { PerspectiveClassChip } from '../../chip/PerspectiveClassChip';
import { UserChip } from '../../chip/UserChip';
import { Chip, RetentionClassChip } from '../../chip';
import { Box } from '../../Box';
import { Typography } from '../../Typography';
import { useIcon } from '../../../hooks';
import { formatDate } from '../../../util';

const StyledImage = styled('img')``;
export const dateTimeRegex =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;

export const PropertyValueTemplate = ({
  item,
  isEditable,
}: {
  item: any;
  isEditable: boolean;
}) => {
  if (isPerspectiveClass(item)) {
    return (
      <div className={isEditable ? 'editable-cell' : 'noneditable-cell'}>
        <PerspectiveClassChip businessClass={item} />
      </div>
    );
  } else if (isBusinessType(item)) {
    return (
      <div className={isEditable ? 'editable-cell' : 'noneditable-cell'}>
        <BusinessTypeTemplate
          title={item._Display}
          information={item.DerivedPath}
          imageId={item._ImageId}
        />
      </div>
    );
  } else if (hasDisplayProperty(item))
    return (
      <div className={isEditable ? 'editable-cell' : 'noneditable-cell'}>
        <UserChip user={{ name: item._Display } as User} />
      </div>
    );
  else if (isStringALink(item)) {
    return (
      <div className={isEditable ? 'editable-cell' : 'noneditable-cell'}>
        <Link href={item} target='_blank' sx={{ textDecoration: 'none' }}>
          <Typography variant='body1'>{item}</Typography>
        </Link>
      </div>
    );
  } else if (Array.isArray(item?.value ?? item)) {
    const _itemValue = item?.value ?? item;
    const _isPerspective = _itemValue?.[0]?.['$type']?.indexOf('IT_') == 0;
    const _isRetention = _itemValue?.[0]?.['$type']?.includes(
      'BusinessTransaction'
    );
    const _isItemCollection =
      _itemValue?.[0]?.['$type']?.includes('ItemCollection');

    const getItemContent = (item, index) => {
      // Render Perspective Classes
      if (_isPerspective) return <PerspectiveClassChip businessClass={item} />;
      // Render Retention Classes
      else if (_isRetention) {
        return (
          <RetentionClassChip
            key={index}
            classification={item}
            sx={{ width: '100%', maxWidth: 'fit-content' }}
          />
        );
      }
      // Render Item Collection
      else if (_isItemCollection) {
        const getIcon = (id) => {
          if (id) {
            const { iconDetails } = useIcon({
              imageId: id,
            });

            return `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`;
          } else {
            return `${process.env.REACT_APP_ENC_IMC_URL}BusinessClasses/Documents/document header footer.svg`;
          }
        };

        return (
          <Chip
            key={`memberOf-${index}`}
            title={item?._Display}
            icon={
              <StyledImage
                src={getIcon(item._ImageId)}
                style={{ height: '1.5rem' }}
              />
            }
            isLink={true}
            sx={{ minHeight: '2.5rem' }}
          />
        );
      }
    };
    return (
      <div className={isEditable ? 'editable-cell' : 'noneditable-cell'}>
        <Box
          background='none'
          direction='row'
          style={{ flexWrap: 'wrap' }}
          gap='small'
        >
          {_itemValue?.map((item, index) => getItemContent(item, index))}
        </Box>
      </div>
    );
  } else {
    const dateObj = new Date(item);

    if (
      item &&
      dateObj &&
      !isNaN(dateObj?.getTime()) &&
      dateTimeRegex.test(item)
    ) {
      return (
        <div className={isEditable ? 'editable-cell' : 'noneditable-cell'}>
          <Typography variant='body1'>
            {formatDate(item, false)}
          </Typography>
        </div>
      );
    } else {
      return (
        <div className={isEditable ? 'editable-cell' : 'noneditable-cell'}>
          <Typography variant='body1'>{item}</Typography>
        </div>
      );
    }
  }
};
