import { ChevronDownFilled } from '@fluentui/react-icons';
import {
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { Box } from '../../../Box';

type BooleanFieldProps = {
  value: string;
  onChange: (e: SelectChangeEvent<string>) => void;
};

export const BooleanField = ({ value, onChange }: BooleanFieldProps) => {
  return (
    <Box background='none'>
      <Select
        value={value}
        variant='outlined'
        onChange={onChange}
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: '3rem',
          maxWidth: '10.875rem',
          paddingLeft: '0.75rem',
        }}
        IconComponent={ChevronDownFilled}
        input={<OutlinedInput notched={false} />}
        MenuProps={{ classes: { paper: 'filter-dropdown' } }}
      >
        <MenuItem value=''>
          <Typography variant='body2'></Typography>
        </MenuItem>
        <MenuItem value='true'>
          <Typography variant='body2'>Yes</Typography>
        </MenuItem>
        <MenuItem value='false'>
          <Typography variant='body2'>No</Typography>
        </MenuItem>
      </Select>
    </Box>
  );
};
