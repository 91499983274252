import { useState } from 'react';
import { Divider, Typography } from '@mui/material';
import {
  ItemManagementStatus,
  DisposalRequestStatus,
} from '../types';
import { Box } from './Box';
import { formatDate } from '../util';
import { DetailsRow } from './DetailsRow';
import { useGetItemDisposalDetailsQuery } from '../services';
import { DisposalRequestApprovers } from './DisposalRequestApprovers';
import { InfoPanel } from './InfoPanel';
import {
  Chip,
  DisposalRequestStatusChip,
  DisposalRequestStatusIndicator
} from './chip';
import { CommentText } from './CommentText';
import { DetailDialog } from './dialog';

import {
  ArrowClockwiseDashes20Regular,
  CheckmarkSquare20Regular,
  Delete20Regular,
  DismissSquare20Regular,
  ErrorCircle20Regular,
  New20Regular,
  PeopleQueue20Regular,
} from '@fluentui/react-icons';

export type DisposalDetailsPanelProps = {
  itemId?: string;
};

export const DisposalDetailsPanel = ({
  itemId,
}: DisposalDetailsPanelProps) => {
  const [addtlInfoDialogOpen, setAddtlInfoDialogOpen] = useState(false);
  const [addtlInfoDialogText, setAddtlInfoDialogText] = useState('');

  const {
    data: itemDisposalDetails,
  } = useGetItemDisposalDetailsQuery({ itemId: itemId });

  const _icon = {
    [DisposalRequestStatus.New]: <New20Regular />,
    [DisposalRequestStatus.InApproval]: <PeopleQueue20Regular />,
    [DisposalRequestStatus.Approved]: <CheckmarkSquare20Regular />,
    [DisposalRequestStatus.Rejected]: <DismissSquare20Regular />,
    [DisposalRequestStatus.InProgress]: <ArrowClockwiseDashes20Regular />,
    [DisposalRequestStatus.Complete]: <Delete20Regular />,
    [DisposalRequestStatus.Failed]: <ErrorCircle20Regular />,
  };

  // TODO: clean up below (for demo)
  const dateCompleted = itemDisposalDetails?.DisposalDetails?.value[0]?.CompletedDate;

  const addtlInfoDialogOpenHandler = (text: string) => {
    setAddtlInfoDialogOpen(true);
    setAddtlInfoDialogText(text);
  };

  const addtlInfoDialogCloseHandler = () => {
    setAddtlInfoDialogOpen(false);
    setAddtlInfoDialogText('');
  };

  const getDisposedByText = () => {
    let disposedByValue = '';

    itemDisposalDetails?.DisposalDetails?.value?.map((disposalDetails, index) => {
      const actionedById = disposalDetails?.DisposalRequest?.ActionedById;
      let actionedByName = '';

      disposalDetails?.DisposalRequest?.ApproverResponses.value.forEach((approvers) => {
        if (approvers.ApproverId === actionedById)
          actionedByName = approvers.Approver.DisplayName;
      });
      disposedByValue += `${actionedByName} (${index + 1})\r\n`;
    });

    return disposedByValue;
  };

  return (
    <Box padding='none' background='none' direction='row' gap='none' height={'100%'}>
      <DetailDialog
        open={addtlInfoDialogOpen}
        title='Additional Information'
        onClose={addtlInfoDialogCloseHandler}
      >
        {addtlInfoDialogText}
      </DetailDialog>
      <Box
        background='none'
        width='100%'
        style={{ overflow: 'overlay', paddingRight: '1rem', overflowY: 'auto' }}
      >
        {itemDisposalDetails && itemDisposalDetails.Status === ItemManagementStatus.Destroyed && (
          <>
            <InfoPanel
              inset='3.5rem'
              title='Disposal Details'
              style={{ paddingRight: '5.25rem', paddingTop: '0.988rem' }}
            >
              <DetailsRow
                label='Date Disposed'
                data={formatDate(dateCompleted)}
              />
              <DetailsRow
                label='Disposed by'
                data={getDisposedByText()}
              />
              <DetailsRow
                label='Disposal Action'
                data='Destroy'
              />
              <DetailsRow
                label='Additional Information'
                data={
                  <CommentText
                    comment={itemDisposalDetails?.DisposalDetails.value &&
                      itemDisposalDetails.DisposalDetails.value[0]?.AdditionalInformation}
                    onClick={addtlInfoDialogOpenHandler}
                  />
                }
              />
            </InfoPanel>
            <Divider
              style={{
                width: '88%',
                margin: '0 auto',
                borderColor: '#DCE1EF',
              }}
            />
          </>
        )}

        {itemDisposalDetails?.DisposalDetails ? (
          <InfoPanel
            inset='3.5rem'
            title='Disposal Approval'
            style={{ paddingRight: '5.25rem', paddingTop: '0.988rem' }}
          >
            {itemDisposalDetails?.DisposalDetails?.value.map((item, index) => (
              <Box key={index} background='none' style={{ overflow: 'auto', padding: '0 0 2rem 0', flex: 'none' }}>
                <DetailsRow
                  label='Disposal Requests'
                  data={
                    <Chip
                      key={index}
                      border={true}
                      title={item.DisposalRequest?.Name}
                      subTitle={item.DisposalRequest?.Name}
                      icon={_icon[item.DisposalRequest?.Status]}
                      internalChip={<DisposalRequestStatusIndicator status={item.DisposalRequest?.Status} />}
                    />}
                />
                <DetailsRow
                  label='Approvers'
                  data={
                    (item.DisposalRequest?.ApproverResponses?.value?.length ?? 0) > 0 ? (
                      <DisposalRequestApprovers
                        responses={item.DisposalRequest?.ApproverResponses?.value}
                        width={24}
                      />
                    ) : (
                      <>
                        <DisposalRequestStatusChip status={item.DisposalRequest?.Status} />
                      </>
                    )
                  }
                />
              </Box>
            ))}
          </InfoPanel>
        ) : (
          <Box background='none' style={{ padding: '3rem 0 3rem 5.25rem' }}>
            <Typography
              variant={'body1'}
            >
              No Disposal Details available
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
