import { Edit20Regular } from '@fluentui/react-icons';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { Box } from '../Box';
import { Button } from '../button';
import { BooleanField } from './components/item';

export type EditBooleanDialogProps = {
  open: boolean;
  title?: string;
  height?: string;
  fieldLabel: string;
  isLoading?: boolean;
  onClose?: () => void;
  propertyName?: string;
  propertyValue?: string;
  onSave?: (value: string, propertyName?: string) => void;
};

export const EditBooleanDialog = ({
  open,
  title,
  onSave,
  onClose,
  isLoading = false,
  fieldLabel,
  propertyName,
  propertyValue,
}: EditBooleanDialogProps) => {
  const theme = useTheme();

  const [value, setValue] = useState<string>(propertyValue);

  const onChange = (e: SelectChangeEvent<string>) => setValue(e.target.value);

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          maxWidth: '47.5rem',
          maxHeight: '20.5rem',
        },
      }}
    >
      <DialogTitle>
        <Edit20Regular />
        {title}
      </DialogTitle>
      <DialogContent>
        <Box background='none'>
          <Typography
            mb={1}
            variant='body1'
            sx={{ color: theme.palette.info.dark }}
          >
            {fieldLabel}
          </Typography>
          <BooleanField value={value} onChange={onChange} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          disabled={propertyValue === value || isLoading}
          onClick={() => onSave && onSave(value, propertyName)}
        >
          Save
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
