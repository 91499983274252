import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState } from 'react';
import { Box } from '../Box';
import { ListItem, ListItemButton, Typography } from '@mui/material';

import { ArrowMove20Regular, Person16Filled } from '@fluentui/react-icons';
import { DeleteButton } from '../button';
import { Approver } from '../../types';

export const SortableItem = ({
  id,
  name,
  index,
  onDelete,
  approver,
}: {
  id: string;
  name: string;
  index: number;
  onDelete: (id: string) => void;
  approver?: Approver;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleOnDelete = (_index: string) => {
    onDelete(_index);
  };

  return (
    <ListItem
      disablePadding
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ListItemButton>
        <Box direction='row' background='none'>
          <Typography variant='body2' mr={1}>
            {(index + 1).toString().padStart(2, '0')}
          </Typography>
          <Person16Filled />
          <Typography variant='body2' ml={1}>
            {name}
          </Typography>
        </Box>
        <Box direction='row' background='none' justifyContent='end'>
          <ArrowMove20Regular
            style={{
              visibility: isHovered ? 'visible' : 'hidden',
              alignSelf: 'center',
            }}
          />
          <DeleteButton
            onClick={() => handleOnDelete(id)}
            size='medium'
            className='sort_delete'
          />
        </Box>
      </ListItemButton>
    </ListItem>
  );
};
