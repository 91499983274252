import {
  Button as MuiButton,
  Typography as MuiTypography,
} from '@mui/material';
import { TextIconButton } from './button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ButtonProps } from '@mui/material/Button';
import * as React from 'react';

type MuiButtonProps = React.ComponentProps<typeof MuiButton>;
type MuiTypographyProps = React.ComponentProps<typeof MuiTypography>;

export type BreadcrumbsWithMenuProps = {
  text?: string;
  textVariant?: MuiTypographyProps['variant'];
  textFontSize?: MuiTypographyProps['fontSize'];
  color?: MuiButtonProps['color'] | 'white';
  hoverColor?: MuiButtonProps['color'] | 'white';
  crumbs: any[];
} & Omit<ButtonProps, 'color' | 'variant' | 'children'>;

export default function BreadcrumbsWithMenu({
  text,
  textVariant = 'button',
  textFontSize,
  color,
  hoverColor,
  startIcon,
  endIcon,
  sx,
  crumbs,
  onClick,
  ...props
}: BreadcrumbsWithMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        aria-labelledby='with-menu-demo-breadcrumbs'
        slotProps={{
          paper: {
            style: {
              height: '12rem',
            },
          },
        }}
      >
        {crumbs.slice(1, -4).map((x) => (
          <MenuItem
            onClick={() => onClick(x)
            }
          >
            {x.name}
          </MenuItem>
        ))}
      </Menu>
      {crumbs.length > 1 && (
        <Breadcrumbs
          sx={{ width: '100%', '& li': { width: '100%' } }}
          aria-label='breadcrumbs'
        >
          {crumbs.length < 6 ? (
            <>
              {crumbs[1] != undefined && (
                <TextIconButton
                  size='small'
                  startIcon={startIcon && startIcon}
                  color='white'
                  textVariant='body2'
                  onClick={() => onClick(crumbs.at(0))
                  }
                  text={(crumbs.at(0) ?? { name: '' }).name}
                  sx={{
                    textOverflow: 'ellipsis',
                    maxWidth: '20%',
                  }}
                />
              )}
              {crumbs[1] != undefined && (
                <TextIconButton
                  size='small'
                  hoverVariant='underline'
                  color='white'
                  textVariant='body2'
                  text={'/'}
                  sx={{
                    paddingX: '0.75rem',
                    display: 'inline-block',
                    minWidth: 'unset',
                    '& p': {
                      width: 'fit-content',
                    },
                  }}
                />
              )}

              {crumbs.slice(2, 5).map((x, i) => {
                return (
                  <>
                    {x != undefined && (
                      <TextIconButton
                        size='small'
                        hoverVariant='underline'
                        color='white'
                        textVariant='body2'
                        onClick={() => onClick(crumbs[i + 1])
                        }
                        text={(crumbs[i + 1] ?? { name: '' }).name}
                        sx={{
                          textOverflow: 'ellipsis',
                          maxWidth: `${17.5 - 2.5 * i}%`,
                        }}
                      />
                    )}
                    {x != undefined && (
                      <TextIconButton
                        size='small'
                        hoverVariant='underline'
                        color='white'
                        textVariant='body2'
                        text={'/'}
                        sx={{
                          paddingX: '0.75rem',
                          display: 'inline-block',
                          minWidth: 'unset',
                          '& p': {
                            width: 'fit-content',
                          },
                        }}
                      />
                    )}
                  </>
                );
              })}
            </>
          ) : (
            <>
              <TextIconButton
                size='small'
                hoverVariant='underline'
                color='white'
                textVariant='body2'
                startIcon={startIcon && startIcon}
                onClick={() => onClick(crumbs.at(0))
                }
                text={(crumbs[0] ?? { name: '' }).name}
                sx={{
                  textOverflow: 'ellipsis',
                  maxWidth: '20%',
                }}
              />
              {crumbs[1] != undefined && (
                <TextIconButton
                  size='small'
                  hoverVariant='underline'
                  color='white'
                  textVariant='body2'
                  text={'/'}
                  sx={{
                    paddingX: '0.75rem',
                    display: 'inline-block',
                    minWidth: 'unset',
                    '& p': {
                      width: 'fit-content',
                    },
                  }}
                />
              )}
              <TextIconButton
                size='small'
                hoverVariant='underline'
                color='white'
                textVariant='body2'
                onClick={handleClick}
                text={'...'}
                sx={{
                  textOverflow: 'ellipsis',
                  maxWidth: '17.5%',
                }}
              />
              {crumbs[1] != undefined && (
                <TextIconButton
                  size='small'
                  hoverVariant='underline'
                  color='white'
                  textVariant='body2'
                  text={'/'}
                  sx={{
                    paddingX: '0.75rem',
                    display: 'inline-block',
                    minWidth: 'unset',
                    '& p': {
                      width: 'fit-content',
                    },
                  }}
                />
              )}
              <TextIconButton
                size='small'
                hoverVariant='underline'
                color='white'
                textVariant='body2'
                onClick={() => onClick(crumbs.at(-4))
                }
                text={(crumbs.at(-4) ?? { name: '' }).name}
                sx={{
                  textOverflow: 'ellipsis',
                  maxWidth: '15%',
                }}
              />
              {crumbs[1] != undefined && (
                <TextIconButton
                  size='small'
                  hoverVariant='underline'
                  color='white'
                  textVariant='body2'
                  text={'/'}
                  sx={{
                    paddingX: '0.75rem',
                    display: 'inline-block',
                    minWidth: 'unset',
                    '& p': {
                      width: 'fit-content',
                    },
                  }}
                />
              )}
              <TextIconButton
                size='small'
                hoverVariant='underline'
                color='white'
                textVariant='body2'
                onClick={() => onClick(crumbs.at(-3))
                }
                text={(crumbs.at(-3) ?? { name: '' }).name}
                sx={{
                  textOverflow: 'ellipsis',
                  maxWidth: '12.5%',
                }}
              />
              {crumbs[1] != undefined && (
                <TextIconButton
                  size='small'
                  hoverVariant='underline'
                  color='white'
                  textVariant='body2'
                  text={'/'}
                  sx={{
                    paddingX: '0.75rem',
                    display: 'inline-block',
                    minWidth: 'unset',
                    '& p': {
                      width: 'fit-content',
                    },
                  }}
                />
              )}
              <TextIconButton
                size='small'
                hoverVariant='underline'
                color='white'
                textVariant='body2'
                onClick={() => onClick(crumbs.at(-2))
                }
                text={(crumbs.at(-2) ?? { name: '' }).name}
                sx={{
                  textOverflow: 'ellipsis',
                  maxWidth: '10%',
                }}
              />
              {crumbs[1] != undefined && (
                <TextIconButton
                  size='small'
                  hoverVariant='underline'
                  color='white'
                  textVariant='body2'
                  text={'/'}
                  sx={{
                    paddingX: '0.75rem',
                    display: 'inline-block',
                    minWidth: 'unset',
                    '& p': {
                      width: 'fit-content',
                    },
                  }}
                />
              )}
            </>
          )}
        </Breadcrumbs>
      )}
    </>
  );
}
