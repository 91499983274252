import styled from '@emotion/styled';
import { ListItem, ListItemButton } from '@mui/material';
import { memo } from 'react';
import { useIcon } from '../hooks';
import { RetentionClass24 } from '../icons';
import { Box } from './Box';
import { Type } from './SortableOptionList';
import { Typography } from './Typography';

const StyledImage = styled('img')``;

interface OptionSelectItemProps<T> {
  option: T;
  type?: Type;
  hasIcon: boolean;
  itemIcon: React.ReactNode;
  handleSelect?: (option: T) => void;
}

export const OptionSelectItem = memo(
  <
    T extends {
      ID?: string;
      _Display?: string;
      _ImageId?: string;
      FullPath?: string;
      RootParentColor?: string;
    }
  >({
    option,
    hasIcon,
    itemIcon,
    type = Type.DEFAULT,
    handleSelect,
  }: OptionSelectItemProps<T>) => {
    const { _Display: displayName, FullPath, RootParentColor } = option;

    const imageId =
      hasIcon && !itemIcon && !RootParentColor ? option?._ImageId : null;

    const { iconDetails } = useIcon({
      imageId,
    });

    const getOptionIcon = () => {
      // check if the icon is enabled and has item icon
      if (hasIcon && itemIcon) {
        return itemIcon;
      }
      // check if the icon is enabled and has icon details
      else if (hasIcon && iconDetails) {
        return (
          <StyledImage
            alt={iconDetails.AltText}
            style={{ height: '1.5rem' }}
            src={`${process.env.REACT_APP_ENC_IMC_URL}${iconDetails.Url}`}
          />
        );
      }
      // no icon will be displayed
      else {
        return null;
      }
    };

    return (
      <ListItem disablePadding>
        <ListItemButton onClick={() => handleSelect(option)}>
          {type === Type.DEFAULT || type === Type.PERSPECTIVE ? (
            <Box
              background='none'
              direction='row'
              alignItems='center'
              gap={0.5}
            >
              {getOptionIcon()}
              <Typography variant='body2'>{displayName}</Typography>
            </Box>
          ) : null}
          {/* Render For Retention Class */}
          {type === Type.RETENTION ? (
            <Box background='none' direction='row' alignItems='start' gap={0.5}>
              <RetentionClass24
                color={RootParentColor ?? undefined}
                style={{ flexShrink: 0 }}
              />
              <Box direction='column' background='none'>
                <Typography variant='body2'>{displayName}</Typography>
                <Typography variant='body3'>{FullPath}</Typography>
              </Box>
            </Box>
          ) : null}
        </ListItemButton>
      </ListItem>
    );
  }
);
