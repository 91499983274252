import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { Button } from '../button';

export type ConfirmDialogProps = {
  title?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  open?: boolean;
  message?: React.ReactNode;
  actionButtonText?: string;
  closeButtonText?: string;
  onAction?: () => void;
  onClose?: () => void;
};

export const ConfirmDialog = ({
  title,
  icon,
  open,
  message,
  actionButtonText,
  closeButtonText,
  onAction,
  onClose,
}: ConfirmDialogProps) => {
  const theme = useTheme();

  return (
    <Dialog
      open={!!open}
      PaperProps={{
        sx: {
          minWidth: '20rem',
          maxWidth: '30rem',
          minHeight: '4rem',
          maxHeight: '34rem',
        },
      }}
    >
      <DialogTitle>
        {!!icon && icon}
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onAction}>
          {actionButtonText}
        </Button>
        <Button variant='outlined' onClick={onClose}>
          {closeButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
