import { Box } from './Box';
import { AppsSection } from './AppsSection';
import { AppLogo } from './AppLogo';
import { CloseButton } from './button';
import { User } from '../types';
import { groupUserAppsByPurpose } from '../util/userapps';

export type AppsPaneProps = {
  title?: string;
  onClose: () => void;
  inset?: string | number;
  style?: React.CSSProperties;
  user: User;
  width?: string;
  height?: string;
};

export const AppsPane = ({
  title,
  inset = 0,
  style,
  onClose,
  user,
  width = '28.79rem',
  height = '93.5vh',
  ...rest
}: AppsPaneProps) => {
  const appsGroupedByPurpose = groupUserAppsByPurpose(user);

  return (
    <Box
      background='none'
      direction='column'
      height={height}
      width={width}
      justifyContent='space-around'
      alignItems='start'
      style={{ padding: '0 0 5.125rem 0' }}
    >
      <Box
        gap='xlarge'
        direction='row'
        width='22.5rem'
        background='none'
        alignItems='center'
        style={{ padding: '1.4375rem 2.1875rem 1.4375rem 1.5rem' }}
      >
        <Box
          background='none'
          justifyContent='center'
          alignItems='start'
          width='16.625rem'
          style={{ marginRight: '3.6rem' }}
        >
          <AppLogo colour='none' bannerColour='dark'></AppLogo>
        </Box>
        <CloseButton onClick={onClose} />
      </Box>
      <Box
        background='none'
        direction='column'
        justifyContent='start'
        alignItems='start'
        style={{ padding: '3.5rem 2rem' }}
      >
        {appsGroupedByPurpose.map((groupedByPurpose) => {
          return (
            <AppsSection
              key={groupedByPurpose.purpose}
              title={groupedByPurpose.purpose}
              applications={groupedByPurpose.applications}
            ></AppsSection>
          );
        })}
      </Box>
    </Box>
  );
};
