import { SVGProps } from 'react';
const SvgDestructionComplete24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10 5H14C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5ZM8.5 5C8.5 3.067 10.067 1.5 12 1.5C13.933 1.5 15.5 3.067 15.5 5H21.25C21.6642 5 22 5.33579 22 5.75C22 6.16421 21.6642 6.5 21.25 6.5H19.9309L18.7589 18.6112C18.5729 20.5334 16.9575 22 15.0263 22H8.97369C7.04254 22 5.42715 20.5334 5.24113 18.6112L4.06908 6.5H2.75C2.33579 6.5 2 6.16421 2 5.75C2 5.33579 2.33579 5 2.75 5H8.5ZM6.73416 18.4667C6.84577 19.62 7.815 20.5 8.97369 20.5H15.0263C16.185 20.5 17.1542 19.62 17.2658 18.4667L18.4239 6.5H5.57608L6.73416 18.4667Z'
      fill='#697186'
    />
    <path
      d='M15.8633 10.1598C16.0456 10.3728 16.0456 10.7181 15.8633 10.9311L11.6633 15.8402C11.4811 16.0533 11.1856 16.0533 11.0034 15.8402L9.13668 13.6584C8.95444 13.4454 8.95444 13.1 9.13668 12.887C9.31893 12.674 9.61441 12.674 9.79665 12.887L11.3333 14.6832L15.2034 10.1598C15.3856 9.94675 15.6811 9.94675 15.8633 10.1598Z'
      fill='#697186'
    />
  </svg>
);
export default SvgDestructionComplete24;
