import * as React from 'react';
import { SVGProps } from 'react';
const SvgImc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 45 53'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='m29.887 9.367 13.75 8.824c.538.343.863.938.863 1.577v21.625a1.875 1.875 0 0 1-2.82 1.62l-13.75-8.021A1.875 1.875 0 0 1 27 33.37V10.946c0-1.483 1.64-2.38 2.887-1.58Z'
      fill='#DCE1EF'
      stroke='url(#Imc_svg__a)'
    />
    <path
      d='M20.239 1.19 1.8 10.205A1.875 1.875 0 0 0 .75 11.89v20.635a1.875 1.875 0 0 0 2.638 1.713l18.437-8.194a1.875 1.875 0 0 0 1.113-1.714V2.876a1.875 1.875 0 0 0-2.7-1.685Z'
      fill='#DCE1EF'
      stroke='url(#Imc_svg__b)'
    />
    <path
      d='M44.5 20.688a1.875 1.875 0 0 0-2.699-1.685l-18.437 9.015a1.876 1.876 0 0 0-1.052 1.684v20.635a1.875 1.875 0 0 0 2.638 1.712l18.438-8.193a1.875 1.875 0 0 0 1.112-1.714V20.688Z'
      fill='#fff'
      stroke='url(#Imc_svg__c)'
    />
    <path
      d='M.75 33.888V12.116l16.529 9.09a1.874 1.874 0 0 1 .971 1.643v17.913a1.874 1.874 0 0 1-2.713 1.679l-13.75-6.875A1.876 1.876 0 0 1 .75 33.888Z'
      fill='#fff'
      stroke='url(#Imc_svg__d)'
    />
    <defs>
      <linearGradient
        id='Imc_svg__a'
        x1={65.75}
        y1={18.871}
        x2={-14.875}
        y2={9.496}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#5600D7' />
        <stop offset={1} stopColor='#00EFEA' stopOpacity={0.6} />
      </linearGradient>
      <linearGradient
        id='Imc_svg__b'
        x1={37.625}
        y1={34.496}
        x2={-13}
        y2={-4.254}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#5600D7' />
        <stop offset={1} stopColor='#00EFEA' stopOpacity={0.6} />
      </linearGradient>
      <linearGradient
        id='Imc_svg__c'
        x1={57.625}
        y1={43.246}
        x2={-3.625}
        y2={23.246}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#5600D7' />
        <stop offset={1} stopColor='#00EFEA' stopOpacity={0.6} />
      </linearGradient>
      <linearGradient
        id='Imc_svg__d'
        x1={35.75}
        y1={50.121}
        x2={-13}
        y2={13.871}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#5600D7' />
        <stop offset={1} stopColor='#00EFEA' stopOpacity={0.6} />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgImc;
