import { Fragment, useState } from 'react';
import { ApproverResponse, DisposalRequestResponseStatus } from '../types';
import { formatDate } from '../util/dateTime';
import { Box } from './Box';
import { CommentText } from './CommentText';
import { DetailsRow } from './DetailsRow';
import { DisposalRequestResponseStatusChip } from './chip/DisposalRequestResponseStatusChip';
import { DetailDialog } from './dialog/DetailDialog';

export type DisposalRequestResponsesProps = {
  responses?: ApproverResponse[];
};

export const DisposalRequestResponses = ({
  responses,
}: DisposalRequestResponsesProps) => {
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [commentDialogText, setCommentDialogText] = useState('');

  const commentBoxOpenHandler = (text: string) => {
    setCommentDialogOpen(true);
    setCommentDialogText(text);
  };

  const infoDialogCloseHandler = () => {
    setCommentDialogOpen(false);
    setCommentDialogText('');
  };

  return (
    <Box
      background='none'
      style={{
        overflow: 'visible',
      }}
    >
      <DetailDialog
        open={commentDialogOpen}
        title='Comment'
        onClose={infoDialogCloseHandler}
      >
        {commentDialogText}
      </DetailDialog>
      {responses?.map((approval: ApproverResponse, index: number) => {
        const key = `${approval?.ApproverId}-${index}`;
        if (
          approval.Status === DisposalRequestResponseStatus.Pending ||
          approval.Status === DisposalRequestResponseStatus.Waiting
        )
          return <Fragment key={key}></Fragment>;
        return (
          <Box
            background='none'
            style={{ marginBottom: '2.813rem' }}
            key={index}
          >
            <DetailsRow
              label='Decision'
              data={
                <DisposalRequestResponseStatusChip status={approval.Status} />
              }
              alignData
            />
            <DetailsRow
              label='Response By'
              data={approval.Approver?.DisplayName}
            />
            <DetailsRow
              label='Date Responded'
              data={formatDate(approval?.DateResponded!)}
            />
            <DetailsRow
              label='Response Comments'
              data={
                <Box background='none'>
                  <CommentText
                    comment={approval.ResponseComments}
                    numberOfLines={15}
                    variant='body2'
                    onClick={() =>
                      commentBoxOpenHandler(approval.ResponseComments)
                    }
                  />
                </Box>
              }
            />
          </Box>
        );
      })}
    </Box>
  );
};
