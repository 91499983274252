import { useState } from 'react';
import { Approver } from '../types';

type UseApproversReturnType = {
  selectedApprovers: Approver[];
  setSelectedApprovers: React.Dispatch<React.SetStateAction<Approver[]>>;
  isApproversListValid: boolean;
  setIsApproversListValid: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useApprovers = (
  initialApprovers: Approver[]
): UseApproversReturnType => {
  const [selectedApprovers, setSelectedApprovers] =
    useState<Approver[]>(initialApprovers);
  const [isApproversListValid, setIsApproversListValid] =
    useState<boolean>(true);

  return {
    selectedApprovers,
    setSelectedApprovers,
    isApproversListValid,
    setIsApproversListValid,
  };
};
