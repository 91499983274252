import {
  useGetIconByTypeDefIdQuery,
  useGetIconQuery,
} from '../services/itemApi';
import { getIcon } from './hookUtils';

type useIconProps = {
  imageId: string;
  typeDefId?: string;
  fallbackObj?: any;
};

const getIconByImageId = ({ imageId, skip }) => {
  const { data } = useGetIconQuery({ imageId }, { skip });
  return data || null;
};

const getIconByTypeDefId = ({ typeDefId, skip }) => {
  const { data } = useGetIconByTypeDefIdQuery({ typeDefId }, { skip });
  return data || null;
};

export const useIcon = ({ imageId, typeDefId, fallbackObj }: useIconProps) => {
  const data = imageId
    ? getIconByImageId({ imageId, skip: !imageId })
    : getIconByTypeDefId({ typeDefId, skip: !typeDefId });
  const iconDetails = getIcon(data ?? fallbackObj);

  return { iconDetails };
};
