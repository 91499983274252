import { IconButton, Typography, styled } from '@mui/material';
import { useTooltip } from '../hooks';
import { Box } from './Box';
import { Tooltip } from './Tooltip';
import { MenuButton } from './button';
import { Indicator } from './chip/Indicator';
import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MToolTop from '@mui/material/Tooltip';

import { ArrowDownload20Regular, Link20Regular } from '@fluentui/react-icons';
export type ItemSummaryTitleProps = {
  icon?: React.ReactNode;
  isMoreButtonAvailable: boolean;
  displayName: string;
  businessType?: string;
  itemType?: string;
  onDownloadClick?: () => void;
  onCopyLinkClick?: () => void;
};
// TODO: MenuItem Component need to be refactored out of here
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: 0,
  paddingRight: '3rem',
  width: '100%', // This will make the menu item take the full width of its parent
  '&:hover': {
    color: '#ffffff',
    '& .menu-icon': {
      color: '#ffffff',
    },
  },
}));

export const ItemSummaryTitle = ({
  icon,
  displayName,
  businessType,
  itemType,
  isMoreButtonAvailable = false,
  onDownloadClick,
  onCopyLinkClick,
}: ItemSummaryTitleProps) => {
  const { dataRef, title } = useTooltip(displayName);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      background='none'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      gap='medium'
      height={4.5}
      style={{ padding: '0rem 1.5rem' }}
    >
      {icon && icon}
      <Tooltip title={title} arrow>
        <Typography
          variant='h1'
          style={{
            margin: '-0.25rem',
            maxWidth: '37.5rem',
          }}
          ref={dataRef}
        >
          {displayName}
        </Typography>
      </Tooltip>
      {businessType && (
        <Indicator
          label={businessType}
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        />
      )}
      {itemType && <Indicator label={itemType} />}
      <div style={{ flexGrow: 1 }}></div>
      <MToolTop title=''>
        <MenuButton
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          size='small'
          key='small'
          color='primary'
        />
      </MToolTop>

      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isMoreButtonAvailable && (
          <StyledMenuItem
            onClick={() => {
              onDownloadClick && onDownloadClick();
            }}
          >
            <IconButton
              className='menu-icon'
              color='primary'
              size='large'
              style={{ padding: 0 }}
            >
              <ArrowDownload20Regular />
            </IconButton>
            <Typography
              variant={'body2'}
              color='inherit'
              noWrap
              fontSize={'inherit'}
              style={{ flexGrow: 1 }}
              textTransform='none'
            >
              Download content
            </Typography>
          </StyledMenuItem>
        )}
        <StyledMenuItem
          onClick={() => {
            onCopyLinkClick && onCopyLinkClick();
          }}
        >
          <IconButton
            className='menu-icon'
            color='primary'
            size='large'
            style={{ padding: 0 }}
          >
            <Link20Regular />
          </IconButton>
          <Typography
            variant={'body2'}
            color='inherit'
            noWrap
            fontSize={'inherit'}
            textTransform='none'
            style={{ flexGrow: 1 }}
          >
            Copy link
          </Typography>
        </StyledMenuItem>
      </Menu>
    </Box>
  );
};
