import { Typography } from '@mui/material';
import { Fragment, ReactNode, useEffect, useState } from 'react';

type MuiTypographyProps = React.ComponentProps<typeof Typography>;

export type MultilineTypographyProps = { children?: string } & Omit<
  MuiTypographyProps,
  'children'
>;

/**
 * MultilineTypography - wrapper for Mui Typography to support text with newline characters
 * @param children text to display
 * @extends Typography
 */
export const MultilineTypography = ({
  children = '',
  ...props
}: MultilineTypographyProps) => {
  const [_children, setChildren] = useState<ReactNode>(children);

  useEffect(() => {
    setChildren(
      children?.split('\n').map((c, i) => (
        <Fragment key={i}>
          {i !== 0 && <br />}
          {c}
        </Fragment>
      )) ?? ''
    );
  }, [children]);

  return <Typography {...props}>{_children}</Typography>;
};

export default MultilineTypography;
