import { Box } from './Box';
import { Typography, useTheme } from '@mui/material';

export type ApplicationButtonProps = {
  name: string;
  Logo: (props: any) => JSX.Element;
  size?: 'small' | 'medium';
  className?: string;
  url?: string;
};

export const ApplicationButton = ({
  name,
  Logo,
  size = 'small',
  className,
  url,
}: ApplicationButtonProps) => {
  const theme = useTheme();

  let _className = `ecs-application`;

  //can apply custom className
  _className = _className + (className != null ? `-${className}` : '');
  
  const [width, height] = size == 'medium' ? [6.875, 8.75] : [5.5, 7];
  const [logoWidth, logoHeight] = size == 'medium' ? [4.375, 5] : [3.5, 4];

  // Handle onClick event
  const handleClick = () => {
    if (url) {
      window.open(url, '_blank'); // Opens the URL in a new window/tab
    }
  };

  return (
    //could also have used the Button component
    <Box
      className={_className}
      rounded='all'
      padding={'medium'}
      background='alt-light'
      alignItems='center'
      gap={'small'}
      justifyContent='space-between'
      height={height}
      width={width}
      onClick={handleClick} // Add onClick event handler
      style={{ cursor: url ? 'pointer' : 'default' }} // Change cursor style if url is provided
    >
      <Box
        className={'ecs-application-sub-pane'}
        width={logoWidth}
        height={logoHeight}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Logo />
      </Box>
      <Box
        className={'ecs-application-sub-pane'}
        direction='column'
        justifyContent={'center'}
      >
        <Typography
          align='center'
          variant={'body2'}
          color={theme.palette.info.dark}
          fontSize={size == 'medium' ? '0.875rem' : '0.75rem'}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};
