import {
  DisposalRequestResponseStatus,
  ApproverResponse,
  Approver,
  DisposalRequest,
  DisposalRequestStatus,
} from '../types';
import { Box, BoxProps } from './Box';
import { Typography, useTheme } from '@mui/material';
import { DisposalRequestResponseStatusText } from './DisposalRequestResponseStatusText';
import { DisposalRequestTempApprovers } from './DisposalRequestTempApprovers';
import { useEffect, useState } from 'react';

export type DisposalRequestApproversProps = {
  responses?: ApproverResponse[];
  tempSelectedApprovers?: Approver[];
  width?: BoxProps['width'];
};

export type DisposalRequestApproversPanelProps = {
  approvers: Approver[];
  disposalRequest: DisposalRequest;
  tempSelectedApprovers?: Approver[];
  width?: BoxProps['width'];
};

export const DisposalRequestApproversPanel = ({
  approvers,
  disposalRequest,
}: DisposalRequestApproversPanelProps) => {
  const [displayedApprovers, setDisplayedApprovers] = useState<Approver[]>([]);
  const typeOrder = {
    [DisposalRequestResponseStatus.Approved]: 1,
    [DisposalRequestResponseStatus.Delegated]: 2,
    [DisposalRequestResponseStatus.Pending]: 2,
    [DisposalRequestResponseStatus.Waiting]: 2,
  };

  useEffect(() => {
    const approvedRequestsCount = approvers?.filter(
      (approver) => approver.Status === DisposalRequestResponseStatus.Approved
    ).length;

    let sortedApprovers = approvers;

    // Custom sorting is only done when DR is New or is Rejected, where the user can
    // update the Approvers before moving the DR to InApproval
    // Else, we follow the Order that is applied to the Approver list from the DR request value
    if (
      disposalRequest.Status === DisposalRequestStatus.New ||
      disposalRequest.Status === DisposalRequestStatus.Rejected
    ) {
      sortedApprovers = approvers?.sort((a, b) => {
          const typeOrderA = typeOrder[a.Status];
          const typeOrderB = typeOrder[b.Status];

          if (typeOrderA < typeOrderB) return -1;
          if (typeOrderA > typeOrderB) return 1;

          if (a.Order < b.Order) return -1;
          if (a.Order > b.Order) return 1;

          return 0;
        }).map((approver, index) => {
          if (index === approvedRequestsCount) {
            return {
              ...approver,
              Status: DisposalRequestResponseStatus.Pending,
            };
          }
    
          if (
            approver.Status !== DisposalRequestResponseStatus.Approved &&
            approver.Status !== DisposalRequestResponseStatus.Rejected &&
            approver.Status !== DisposalRequestResponseStatus.Delegated
          ) {
            return {
              ...approver,
              Status: DisposalRequestResponseStatus.Waiting,
            };
          }
    
          return approver;
        });
    } else {
      sortedApprovers = approvers.sort((a, b) => {
        return a.Order - b.Order;
      })
    }

    setDisplayedApprovers(sortedApprovers);
  }, [approvers]);

  return <DisposalRequestTempApprovers approvers={displayedApprovers} />;
};
export const DisposalRequestApprovers = ({
  responses,
  tempSelectedApprovers,
  width,
}: DisposalRequestApproversProps) => {
  const theme = useTheme();

  return (
    <Box
      background='none'
      style={{
        overflow: 'visible',
        minWidth: '14rem',
        maxWidth: '20rem',
      }}
      width={width}
      gap='small'
      shrink
    >
      {responses
        ?.slice()
        .sort((a: ApproverResponse, b: ApproverResponse) => {
          return a.Order - b.Order;
        })
        .map((response: ApproverResponse, index: number) => {
          let pendingRequest =
            response.Status === DisposalRequestResponseStatus.Pending;

          const sx = {
            fontWeight: pendingRequest ? '800' : '400',
            color: pendingRequest
              ? theme.palette.info.dark
              : theme.palette.info.main,
          };
          return (
            <Box background='none' direction='row' key={response.ID}>
              <Typography
                variant='body2'
                sx={{
                  minWidth: '2.25rem',
                  maxWidth: '2.25rem',
                  ...sx,
                }}
              >
                {(index + 1).toString().padStart(2, '0')}
              </Typography>
              <Box
                background='none'
                alignItems='start'
                style={{ alignSelf: 'stretch' }}
              >
                <Typography variant='body2' sx={sx} noWrap>
                  {response.Approver?.DisplayName}
                </Typography>
              </Box>
              <DisposalRequestResponseStatusText
                status={response.Status}
                width={'5rem'}
              />
            </Box>
          );
        })}

      {!!tempSelectedApprovers && (tempSelectedApprovers.length ?? 0) > 0 && (
        <DisposalRequestTempApprovers
          approvers={tempSelectedApprovers}
          startNumber={responses.length ?? 0}
        />
      )}
    </Box>
  );
};
