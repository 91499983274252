import { RefObject, useEffect, useRef, useState } from 'react';
import { config } from '../config';
import { FileContent, Item } from '../types';
import { acquireToken } from '../util';
import { initializeVideoViewer } from '@pdftron/webviewer-video';
import { initializeAudioViewer } from '@pdftron/webviewer-audio';

import WebViewer from '@pdftron/webviewer';

// https://docs.apryse.com/documentation/web/guides/file-format-support/
export const APRYSE_WEBVIEWER_SUPPORTED_FILES = [
  'pdf',
  'fdf',
  'xfdf', // PDF FORMATS
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx', // MICROSOFT OFFICE FORMATS
  'rtf',
  'odt',
  'ods',
  'odp',
  'wpf', // DOCUMENT FORMATS
  'jpg',
  'jfif',
  'png',
  'tif', // IMAGE FORMATS
];

export const APRYSE_WEBVIEWER_SUPPORTED_MEDIA = [
  'mp4',
  'ogg',
  'webm',
  'mp3',
  'wab',
  'flac',
];

export type UseFileContentProps = {
  id?: string,
  item: Item;
  viewer: RefObject<HTMLSelectElement>;
  rowData?: any;
};

export const useFileDetails = ({
  id,
  item,
  viewer,
  rowData,
}: UseFileContentProps) => {
  const [fileDetails, setFileDetails] = useState<FileContent>();
  const [htmlData, setHtmlData] = useState('');
  const [docBlobUrl, setDocBlobUrl] = useState('');
  const [pdfJsonBlobUrl, setPdfJsonBlobUrl] = useState(null);
  const [error, setError] = useState(null);

  const typeDefId = item?.TypeDefId;
  const itemId = item?.ID;
  const fileExtension = item?.StorageObject?.FileExtension;
  const referenceType = item?.TypeDef?.Name;
  const eigenDisplayViewContentId = item?.EigenDisplayViewContentId

  const licenseKey = 'EncompaaS Software Ltd (encompaas.cloud):OEM:Encompaas::B+:AMS(20240509):D7A59CCD0497C60A0360B13AC982537860612F9DE740EDA8DD047B8A9D242E8E22CAB6F5C7';

  useEffect(() => {
    setError(null);
    setHtmlData('');
    setDocBlobUrl('');
    setPdfJsonBlobUrl(null);
  }, [id]);

  useEffect(() => {
    if (!APRYSE_WEBVIEWER_SUPPORTED_FILES.includes(fileExtension?.toLowerCase()) || eigenDisplayViewContentId) return;
    (async () => {
      const token = await acquireToken();
      const API_URL: string = `${config.API_BASE_URL}/${typeDefId}('${itemId}')/Preview`;
      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            Authorization: `bearer ${token}`,
          },
        });

        if (!response.ok) {
          setError('HTTP error occurred');
        } else {
          if (response.headers.get('content-type') === 'application/octet-stream') {
            const data = await response.blob();
            const blobUrl = URL.createObjectURL(data);
            setDocBlobUrl(blobUrl);
          }
        }
      } catch (error) {
        setError('An error occurred while fetching HTML data.');
      }
    })()
  }, [typeDefId, itemId, fileExtension, eigenDisplayViewContentId]);

  useEffect(() => {
    if (!APRYSE_WEBVIEWER_SUPPORTED_FILES.includes(fileExtension?.toLowerCase()) || !eigenDisplayViewContentId) return;
    (async () => {
      const token = await acquireToken();
      const API_URL: string = `${config.API_BASE_URL}/${typeDefId}('${itemId}')/EigenDisplayViewContent/$value`;
      try {
        const response = await fetch(API_URL, {
          method: 'GET',
          headers: {
            Authorization: `bearer ${token}`,
          },
        });

        if (response.status !== 200) {
          setError('HTTP error occurred');
        } else {
          if (response.headers.get('content-type') === 'application/octet-stream') {
            const data = await response.json();
            if (data) {
              for (var key in data) {
                const jsonString = JSON.stringify(data[key]);
                const blob = new Blob([jsonString], { type: 'application/json' });
                const blobUrl = URL.createObjectURL(blob);
                const eigenViewerUrl = '/pdf-highlighter/index.html?obj=' + encodeURIComponent(blobUrl);
                const iframeSrc = rowData ? eigenViewerUrl + '#search=' + encodeURIComponent(JSON.stringify(rowData)) : eigenViewerUrl;
                console.log(rowData);
                setPdfJsonBlobUrl(iframeSrc);
              }
            }
          }
        }
      } catch (error) {
        setError('An error occurred while fetching HTML data.');
      }
    })()
  }, [typeDefId, itemId, fileExtension, eigenDisplayViewContentId, rowData]);

  useEffect(() => {
    if (docBlobUrl && !eigenDisplayViewContentId) {
        WebViewer(
          {
            path: '/lib',
            licenseKey: licenseKey,
            disabledElements: [
              'printButton',
              'downloadButton',
              'saveAsButton',
              'toggleCompareModeButton',
              'toggleNotesButton',
              'selectToolButton',
            ],
          },
          viewer.current
        ).then(async instance => {
          instance.UI.loadDocument(docBlobUrl, { filename: 'file.' + fileExtension?.toLowerCase() });
          instance.UI.disableElements(['ribbons']);
          instance.UI.disableElements(['toolbarGroup-Shapes']);
          instance.UI.disableElements(['toolbarGroup-Edit']);
          instance.UI.disableElements(['toolbarGroup-Insert']);
          instance.UI.disableElements(['toolbarGroup-Annotate']);

          const Feature = instance.UI.Feature as any;
          instance.UI.disableFeatures([Feature.Copy, Feature.Print, Feature.selectToolButton]);

          const { documentViewer } = instance.Core;
          documentViewer.addEventListener('documentLoaded', () => {

          });
        });
    }
  }, [docBlobUrl, eigenDisplayViewContentId]);

  useEffect(() => {
    if (referenceType === 'PostBasedConversation' || referenceType === 'Email') {
      (async () => {
        const token = await acquireToken();

        const API_URL = `${config.API_BASE_URL}/Item('${itemId}')/Preview`;

        try {
          const response = await fetch(API_URL, {
            method: 'POST',
            headers: {
              Authorization: `bearer ${token}`,
            },
          });

          if (!response.ok) {
            setError('HTTP error occurred');
          } else {
            if (response.headers.get('content-type') === 'application/octet-stream') {
              const blob = await response.blob();
              const htmlData = await blob.text();
              setHtmlData(htmlData);
            }
          }
        } catch (error) {
          setError('An error occurred while fetching HTML data.');
        }
      })();
    }
  }, [referenceType, itemId]);

  useEffect(() => {
    if (!typeDefId || !itemId || !WebViewer || !APRYSE_WEBVIEWER_SUPPORTED_MEDIA.includes(fileExtension?.toLowerCase())) return;

    /** IMPORTANT: The embedded license key for Apryse technologies is the property of EncompaaS.  Use of this technology via EncompaaS does not imply any right to reverse engineer the key, use it for any other purpose or to distribute it.  
    /* These activities will constitute a violation of EncompaaS licensing and will result in prosecution and likely result in compensation being soiught for this license breach.  Do not copy or distribute this license key. */
    /* licenseKey: 'EncompaaS Software Ltd (encompaas.cloud):OEM:Encompaas::B+:AMS(20240509):D7A59CCD0497C60A0360B13AC982537860612F9DE740EDA8DD047B8A9D242E8E22CAB6F5C7', /** IMPORTANT 2: THIS KEY IS ENCOMPAAS PROPERTY, DO NOT COPY OR DISTRIBUTE. */

    switch (fileExtension?.toLowerCase()) {
      case 'mp4':
      case 'ogg':
      case 'webm': {
        WebViewer(
          {
            path: '/lib',
            enableAnnotations: false,
          },
          viewer.current
        ).then(async instance => {
          // Extends WebViewer to allow loading HTML5 videos (mp4, ogg, webm).
          const {
            loadVideo,
          } = await initializeVideoViewer(
            instance,
            {
              license: '',
            },
            instance.UI.disableElements(['selectToolButton', 'video-ViewerOptions', 'menuButton']) as any,
          );
          // Load a video at a specific url. Can be a local or public link
          // If local it needs to be relative to lib/ui/index.html.
          // Or at the root. (eg '/video.mp4')
          const videoUrl = fileDetails?.ContentUrl?.Url;
          loadVideo(videoUrl);

          // Hide comment button 
          const buttonElement = document.querySelector('.Button[data-element="toggleNotesButton"]') as HTMLElement;
          if (!!buttonElement) {
            buttonElement.style.display = 'none';
          }

        });
        break;
      }
      case 'mp3':
      case 'wav':
      case 'FLAC': {
        WebViewer(
          {
            path: '/lib',
            enableAnnotations: false,
          },
          viewer.current,
        ).then(async instance => {
          // Extends WebViewer to allow loading media files (.mp3, .mp4, ogg, webm, etc.)
          const {
            loadAudio,
          } = await initializeAudioViewer(
            instance,
            {
              license: '',
            }
          );

          // Load a media element at a specific url. Can be a local or public link
          // If local it needs to be relative to lib/ui/index.html.
          // Or at the root. (eg '/audio.mp3')
          const audioUrl = fileDetails?.ContentUrl?.Url;
          loadAudio(audioUrl);
        });
        break;
      }
    }

  }, [fileDetails, viewer, typeDefId, itemId, fileExtension]);

  return { fileDetails, htmlData, docBlobUrl, pdfJsonBlobUrl, error }
}
