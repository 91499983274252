import { ArrowExit20Regular } from '@fluentui/react-icons';
import { Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { Button } from '../button';
import { CustomActionModal } from './CustomActionModal';

export type DisposalRequestRemovalDialogProps = {
  title?: string;
  open?: boolean;
  onClose?: () => void;
  onAction?: (actionButtonTitle: string) => void;
};

export const DisposalRequestRemovalDialog = ({
  title,
  open,
  onClose,
  onAction,
}: DisposalRequestRemovalDialogProps) => {
  const theme = useTheme();

  const [comment, setComment] = useState('');

  return (
    <CustomActionModal
      title={title}
      onClose={onClose}
      size='small'
      open={open}
      icon={<ArrowExit20Regular style={{ marginRight: '0.75rem' }} />}
      actionButton={
        <Button variant='contained' onClick={() => onAction!(comment)}>
          Remove
        </Button>
      }
    >
      <Typography variant='body1' sx={{ paddingBottom: '1rem' }}>
        Removing items from this Disposal Request will return their disposal
        status to Triggered, for the target Retention Class, so that they can be
        assigned to Disposal Requests in the future.
      </Typography>
      <Typography variant='body1'>Proceed?</Typography>
    </CustomActionModal>
  );
};
export default DisposalRequestRemovalDialog;
