import { Edit20Regular } from '@fluentui/react-icons';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useIcon } from '../../hooks';
import { useGetBusinessTypesQuery } from '../../services';
import { BusinessTypesValue } from '../../types';
import { Box } from '../Box';
import { Button } from '../button';
import { BusinessTypeField } from './components/item';

export type EditBusinessTypeDialogProps = {
  open: boolean;
  title?: string;
  height?: string;
  fieldLabel: string;
  onClose?: () => void;
  propertyName?: string;
  propertyDetails: BusinessTypesValue;
  onSave?: (value: BusinessTypesValue, propertyName?: string) => void;
};

export const EditBusinessTypeDialog = ({
  open,
  title,
  onSave,
  onClose,
  fieldLabel,
  propertyName,
  propertyDetails,
}: EditBusinessTypeDialogProps) => {
  const theme = useTheme();

  const [selectedBusinessType, setSelectedBusinessType] =
    useState<BusinessTypesValue | null>(propertyDetails);

  const imageId = selectedBusinessType?._ImageId;

  const { iconDetails } = useIcon({
    imageId,
  });

  const { data } = useGetBusinessTypesQuery({});

  const handleSelectBusinessType = useCallback(
    (businessType: BusinessTypesValue) => {
      setSelectedBusinessType(businessType);
    },
    [propertyDetails]
  );

  const handleDelete = useCallback(() => {
    setSelectedBusinessType(null);
  }, [propertyDetails]);

  const currentBusinessType = selectedBusinessType?.Caption;
  const businessTypes: BusinessTypesValue[] = data?.value?.filter(
    (businessType) => businessType.Caption !== currentBusinessType
  );

  const disabledSaveButton =
    selectedBusinessType?.Name === propertyDetails?.Name ||
    selectedBusinessType === propertyDetails;

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          maxWidth: '47.5rem',
          maxHeight: '42.438rem',
        },
      }}
    >
      <DialogTitle>
        <Edit20Regular />
        {title}
      </DialogTitle>
      <DialogContent>
        <Box background='none'>
          <Typography
            mb={1}
            variant='body1'
            sx={{ color: theme.palette.info.dark }}
          >
            {fieldLabel}
          </Typography>
          <BusinessTypeField
            iconDetails={iconDetails}
            handleDelete={handleDelete}
            businessTypes={businessTypes}
            selectedBusinessType={selectedBusinessType}
            handleSelectBusinessType={handleSelectBusinessType}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={disabledSaveButton}
          variant='contained'
          onClick={() => !!onSave && onSave(selectedBusinessType, propertyName)}
        >
          Save
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
