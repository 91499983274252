import { Divider, styled, Typography } from '@mui/material';
import {
  Item,
  BusinessType,
  ItemIconValue,
  BusinessTypeProperties,
  BusinessTypePropDefsValue,
  BusinessTypePropertyValues,
  ItemBusinessClass,
  TreeGridProperties,
  BusinessTypesValue,
  ItemBusinessObject,
  DisposalRequest,
  DisposalRequestStatus,
} from '../../types';
import { InfoPanel } from '../InfoPanel';
import { DetailsRow } from '../DetailsRow';
import { PerspectiveClassChip, Chip } from '../chip';
import { isPerspectiveClass, hasDisplayProperty } from './ItemPropertiesUtil';
import { Box } from '../Box';
import { TextIconButton } from '../button';
import { useState } from 'react';
import {
  isDate,
  isEnum,
  isEntity,
  isDouble,
  isBoolean,
  isDateTime,
  isMultiline,
  isSingleLine,
  isInteger,
} from '../../components/item/utils';
import { Add12Regular } from '@fluentui/react-icons';
import {
  EditTextDialog,
  EditEnumDialog,
  EditDateDialog,
  EditNumberDialog,
  EditBooleanDialog,
  EditDateTimeDialog,
  EditCustomTypeDialog,
  EditBusinessTypeDialog,
} from '../dialog';
import { EditPerspectiveClassDialog } from '../dialog/EditPerspectiveClassDialog';
import { useBusinessTypePanel } from '../../hooks/useBusinessTypePanel';
import { useSaveItemDetailsMutation } from '../../services';
import { formatDate } from '../../util';

enum PropertyNames {
  BUSINESS_TYPE = 'BusinessType',
}

export type ItemBusinessTypePanelProps = {
  item: Item;
  hasEditPermission?: boolean;
  disposalRequest: DisposalRequest;
  treeGridProperties: TreeGridProperties;
  businessTypeIconDetails?: ItemIconValue;
  businessTypeProperties?: BusinessTypeProperties;
  businessTypePropertyValues?: BusinessTypePropertyValues;
};

type EditDialogProps = {
  open: boolean;
  title?: string;
  fieldLabel?: string;
  propertyName?: string;
  propertyValue?: string;
  propertyDetails?: BusinessTypePropDefsValue | BusinessType;
};

const StyledImage = styled('img')``;

const displayPropertyValue = (obj) => {
  return (
    <>
      {Object.keys(obj).map((key) => {
        if (isPerspectiveClass(obj[key])) {
          return <PerspectiveClassChip businessClass={obj[key]} />;
        } else if (typeof obj[key] === 'object') {
          return displayPropertyValue(obj[key]);
        } else
          return (
            <Typography
              variant='body2'
              key={`${obj[key]}-${key}`}
              sx={{ paddingBottom: '0.25rem' }}
            >
              {obj[key]}
            </Typography>
          );
      })}
    </>
  );
};

const getObjectProperties = (obj, prefix) => {
  let result = {};

  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      key.startsWith(prefix) &&
      !key.endsWith('Id')
    ) {
      if (typeof obj[key] === 'object' && !isPerspectiveClass(obj[key])) {
        result = {
          ...result,
          [key]: getObjectProperties(obj[key], prefix),
        };
      } else {
        result = { ...result, [key]: obj[key] };
      }
    }
  }

  return result;
};

const getContentData = (propertyValue, dataType) => {
  if (dataType === 'Date' || dataType === 'DateTime') {
    const isDateTime = dataType === 'DateTime';

    const formattedDate = propertyValue
      ? formatDate(propertyValue, isDateTime)
      : '';

    return (
      <Typography variant='body2' sx={{ minHeight: '2.5rem' }}>
        {formattedDate}
      </Typography>
    );
  }

  if (isPerspectiveClass(propertyValue)) {
    return <PerspectiveClassChip businessClass={propertyValue} />;
  }

  if (hasDisplayProperty(propertyValue)) {
    const prefix = 'CT_';

    // get only the properties with CT_ prefix and _1 suffix
    const matchingProperties = getObjectProperties(propertyValue, prefix);

    return (
      <Box background='none'>{displayPropertyValue(matchingProperties)}</Box>
    );
  }

  if (propertyValue?.value) {
    return propertyValue.value.map((val, index) => {
      const prefix = 'CT_';
      // get only the properties with CT_ prefix and _1 suffix
      const matchingProperties = getObjectProperties(val, prefix);

      return (
        <Box
          background='none'
          style={{
            marginBottom:
              index + 1 < propertyValue?.value?.length ? '2rem' : '0',
          }}
        >
          {displayPropertyValue(matchingProperties)}
        </Box>
      );
    });
  }

  if (propertyValue) {
    return (
      <Typography
        variant='body2'
        sx={{ minHeight: '2.5rem', whiteSpace: 'pre-wrap' }}
      >
        {`${propertyValue}`}
      </Typography>
    );
  }

  return null;
};

export const ItemBusinessTypePanel = ({
  item,
  hasEditPermission,
  disposalRequest,
  treeGridProperties,
  businessTypeProperties,
  businessTypeIconDetails,
  businessTypePropertyValues,
}: ItemBusinessTypePanelProps) => {
  const [dialogProps, setDialogProps] = useState<EditDialogProps>({
    open: false,
  });

  const { formDetails } = useBusinessTypePanel({
    item,
    businessTypeProperties,
    businessTypePropertyValues,
  });

  const [saveItemDetails, { isLoading }] = useSaveItemDetailsMutation();

  const setDialogDetails = ({
    open,
    title,
    fieldLabel,
    propertyName,
    propertyValue,
    propertyDetails,
  }: EditDialogProps) => {
    setDialogProps({
      open,
      title,
      fieldLabel,
      propertyName,
      propertyValue,
      propertyDetails,
    });
  };

  const businessTypeId = item.BusinessObject
    ? item.BusinessObject.TypeDefId
    : item.BusinessType
    ? item.BusinessType.ID
    : item.BusinessTypeId;

  let businessObject = item.BusinessObject;
  if (!businessObject) {
    businessObject = {
      ID: null,
      TypeDefId: businessTypeId,
    } as ItemBusinessObject;
  }

  const handleClose = () => {
    setDialogProps({
      open: false,
    });
  };

  const handleSave = async (value, propertyName) => {
    // this is to prevent from calling the API again.
    if (isLoading) return;

    const { ID, TypeDefId } = item;

    let formData = {};

    // Construct Business Type Request
    if (propertyName === PropertyNames.BUSINESS_TYPE) {
      formData = { BusinessType: value };
    }

    // Construct Custom Type Request (Business Type Properties)
    else {
      formData = {
        ...formDetails,
        BusinessObject: {
          ...formDetails.BusinessObject,
          [propertyName]: value?.[propertyName] ?? value ?? null,
        },
      };
    }

    const requestObj = {
      ID,
      TypeDefId,
      formDetails: formData,
    };

    try {
      const result = await saveItemDetails(requestObj);
      setDialogDetails({ open: false });

      console.log('Item Details updated:', result);
    } catch (error) {
      // Handle error
      console.error('Error on updating the item details:', error);
      setDialogDetails({ open: false });
    }
  };

  const renderEditDialog = () => {
    const {
      propertyDetails,
      open,
      title,
      propertyName,
      propertyValue,
      fieldLabel,
    } = dialogProps || {};
    const fieldDetails = { ...propertyDetails } as BusinessTypePropDefsValue;

    const isDateType = isDate(fieldDetails);
    const isDateTimeType = isDateTime(fieldDetails);
    const isEnumType = isEnum(fieldDetails);
    const isEntityType = isEntity(fieldDetails);

    const isBooleanType = isBoolean(fieldDetails);
    const isMultilineTextField = isMultiline(fieldDetails);
    const isSingleLineTextField = isSingleLine(fieldDetails);
    const isDoubleType = isDouble(fieldDetails);
    const isIntegerType = isInteger(fieldDetails);
    const isPerspectiveClassType =
      isEntity(fieldDetails) &&
      fieldDetails?.PropTypeDef?.Name?.indexOf('IT_') == 0;

    // Render Business Type Dialog
    if (propertyName === PropertyNames.BUSINESS_TYPE) {
      return (
        <EditBusinessTypeDialog
          open={open}
          title={title}
          onSave={handleSave}
          onClose={handleClose}
          fieldLabel={fieldLabel}
          propertyName={propertyName}
          propertyDetails={propertyDetails as unknown as BusinessTypesValue}
        />
      );
    }
    // Render Multiline or Single line text field Dialog
    if (isMultilineTextField || isSingleLineTextField) {
      return (
        <EditTextDialog
          rows={12}
          open={open}
          title={title}
          label={fieldLabel}
          onSave={handleSave}
          text={propertyValue}
          onClose={handleClose}
          propertyName={propertyName}
          multiline={isMultilineTextField}
          style={{
            maxWidth: '47.5rem',
            maxHeight: isMultilineTextField ? '36.375rem' : '20.25rem',
            width: '100%',
            height: '100%',
          }}
        />
      );
    }
    // Render Boolean field Dialog
    if (isBooleanType) {
      return (
        <EditBooleanDialog
          open={open}
          title={title}
          onSave={handleSave}
          onClose={handleClose}
          isLoading={isLoading}
          fieldLabel={fieldLabel}
          propertyName={propertyName}
          propertyValue={propertyValue}
        />
      );
    }
    // Render Date field Dialog
    if (isDateType) {
      return (
        <EditDateDialog
          open={open}
          title={title}
          onSave={handleSave}
          onClose={handleClose}
          isLoading={isLoading}
          fieldLabel={fieldLabel}
          propertyName={propertyName}
          propertyValue={formatDate(propertyValue, false)}
        />
      );
    }

    // Render DateTime field Dialog
    if (isDateTimeType) {
      return (
        <EditDateTimeDialog
          open={open}
          title={title}
          onSave={handleSave}
          onClose={handleClose}
          isLoading={isLoading}
          fieldLabel={fieldLabel}
          propertyName={propertyName}
          propertyValue={formatDate(propertyValue)}
        />
      );
    }
    // Render Number type Dialog
    if (isDoubleType || isIntegerType) {
      return (
        <EditNumberDialog
          open={open}
          title={title}
          onSave={handleSave}
          onClose={handleClose}
          isLoading={isLoading}
          fieldLabel={fieldLabel}
          propertyName={propertyName}
          propertyValue={propertyValue}
        />
      );
    }
    // Render Enum type Dialog
    if (isEnumType) {
      return (
        <EditEnumDialog
          open={open}
          title={title}
          onSave={handleSave}
          onClose={handleClose}
          isLoading={isLoading}
          fieldLabel={fieldLabel}
          propertyName={propertyName}
          propertyValue={propertyValue}
          propertyDetails={fieldDetails}
        />
      );
    }
    // Render Perspective Class
    if (isPerspectiveClassType) {
      return (
        <EditPerspectiveClassDialog
          open={open}
          title={title}
          onSave={handleSave}
          onClose={handleClose}
          isLoading={isLoading}
          fieldLabel={fieldLabel}
          propertyName={propertyName}
          propertyDetails={fieldDetails}
          propertyValue={propertyValue as unknown as ItemBusinessClass}
        />
      );
    }
    // Render Custom Type
    if (isEntityType) {
      return (
        <EditCustomTypeDialog
          open={open}
          title={title}
          itemId={item?.ID}
          onSave={handleSave}
          isLoading={isLoading}
          onClose={handleClose}
          typeDefId={item?.TypeDefId}
          propertyName={propertyName}
          propertyDetails={fieldDetails}
          treeGridProperties={treeGridProperties}
          businessObject={formDetails?.BusinessObject}
          formValue={formDetails?.BusinessObject[propertyName]}
        />
      );
    }
  };

  const isItemStatusDestroyed = item?.Status === 'Destroyed';
  const hasBusinessTypeProperties = item?.BusinessType;

  // Add and Edit variables for Business Type
  const hasAddButtonBusinessType =
    hasEditPermission &&
    !item?.BusinessType &&
    !isItemStatusDestroyed &&
    disposalRequest?.Status !== DisposalRequestStatus.InProgressOrComplete;
  let hasEditButtonBusinessType =
    hasEditPermission &&
    !!item?.BusinessType &&
    disposalRequest?.Status !== DisposalRequestStatus.InProgressOrComplete;

  return (
    <>
      {hasAddButtonBusinessType || hasBusinessTypeProperties ? (
        <>
          <InfoPanel
            inset='3.5rem'
            title={`${
              item?.BusinessType?._Display ?? 'Business Type'
            } Properties`}
            style={{ paddingRight: '5.25rem', paddingTop: '0.988rem' }}
          >
            <DetailsRow
              alignData
              key='business-type'
              label='Business Type'
              data={
                hasAddButtonBusinessType ? (
                  <Box background='none' direction='row'>
                    <TextIconButton
                      size='medium'
                      color='primary'
                      textVariant='body2'
                      startIcon={<Add12Regular />}
                      onClick={() =>
                        setDialogDetails({
                          open: true,
                          title: 'Add Business Type',
                          fieldLabel: 'Business Type',
                          propertyDetails: item?.BusinessType,
                          propertyName: PropertyNames.BUSINESS_TYPE,
                        })
                      }
                      text='Add business type'
                    />
                  </Box>
                ) : (
                  <Box
                    background='none'
                    width='100%'
                    style={{
                      maxWidth: 'fit-content',
                    }}
                  >
                    <Chip
                      sx={{ width: 'auto' }}
                      title={item?.BusinessType?._Display}
                      subTitle={item?.BusinessType?.DerivedPath}
                      icon={
                        businessTypeIconDetails ? (
                          <StyledImage
                            style={{
                              width: '1.5rem',
                              height: '1.5rem',
                            }}
                            alt={businessTypeIconDetails?.AltText}
                            src={`${process.env.REACT_APP_ENC_IMC_URL}${businessTypeIconDetails?.Url}`}
                          />
                        ) : null
                      }
                    />
                  </Box>
                )
              }
              onEdit={
                hasEditButtonBusinessType
                  ? () =>
                      setDialogDetails({
                        open: true,
                        title: 'Edit Business Type',
                        fieldLabel: 'Business Type',
                        propertyDetails: item?.BusinessType,
                        propertyName: PropertyNames.BUSINESS_TYPE,
                      })
                  : undefined
              }
            />
            {/* Display Business Type Properties */}
            {hasBusinessTypeProperties
              ? businessTypeProperties?.PropDefs?.value?.map(
                  (businessTypeProperty: BusinessTypePropDefsValue) => {
                    const dataType = businessTypeProperty.PrimitiveDataType;
                    const propertyValue =
                      businessTypePropertyValues?.[businessTypeProperty.Name];

                    const hasAddButton = !propertyValue && hasEditPermission;

                    hasEditButtonBusinessType =
                      hasEditPermission && !!propertyValue;

                    return (
                      <DetailsRow
                        key={businessTypeProperty.Name}
                        label={businessTypeProperty.Caption}
                        data={
                          <Box background='none'>
                            {hasAddButton ? (
                              <TextIconButton
                                size='medium'
                                color='primary'
                                textVariant='body2'
                                startIcon={<Add12Regular />}
                                onClick={() =>
                                  setDialogDetails({
                                    open: true,
                                    propertyValue,
                                    propertyDetails: businessTypeProperty,
                                    propertyName: businessTypeProperty.Name,
                                    fieldLabel: businessTypeProperty.Caption,
                                    title: `Add ${businessTypeProperty.Caption}`,
                                  })
                                }
                                text={`Add ${businessTypeProperty.Caption.toLowerCase()}`}
                                sx={{ height: 'auto' }}
                              />
                            ) : (
                              <Box background='none'>
                                {getContentData(propertyValue, dataType)}
                              </Box>
                            )}
                          </Box>
                        }
                        onEdit={
                          hasEditButtonBusinessType
                            ? () => {
                                setDialogDetails({
                                  open: true,
                                  propertyValue,
                                  propertyDetails: businessTypeProperty,
                                  propertyName: businessTypeProperty.Name,
                                  fieldLabel: businessTypeProperty.Caption,
                                  title: `Edit ${businessTypeProperty.Caption}`,
                                });
                              }
                            : null
                        }
                      />
                    );
                  }
                )
              : null}
          </InfoPanel>
          <Divider
            style={{
              width: '88%',
              margin: '0 auto',
              borderColor: '#DCE1EF',
            }}
          />
          {renderEditDialog()}
        </>
      ) : null}
    </>
  );
};
