import { useEffect, useState } from 'react';
import { MultilineTypography } from '../MultilineTypography';
import { TabPanel, TabPanelTab } from '../TabPanel';
import DetailDialog, { DetailDialogProps } from './DetailDialog';

export type DetailTabDialogProps = {
  tabs?: TabPanelTab[];
} & Omit<DetailDialogProps, 'children'>;

export const DetailTabDialog = ({
  tabs = [],
  ...props
}: DetailTabDialogProps) => {
  const [_tabs, setTabs] = useState<TabPanelTab[]>([]);

  useEffect(() => {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        children:
          typeof tab?.children === 'string' ? (
            <MultilineTypography variant='body1' mt={'1.875rem'}>
              {tab?.children}
            </MultilineTypography>
          ) : (
            tab?.children
          ),
      }))
    );
  }, [tabs]);

  return (
    <DetailDialog {...props}>
      {(tabs?.length ?? 0) === 1 ? (
        tabs[0].children
      ) : (
        <TabPanel tabs={_tabs} divider={false} />
      )}
    </DetailDialog>
  );
};
export default DetailTabDialog;
