import {
  useGetDisposalRequestRetentionConflictExceptionsQuery,
  useGetDisposalRequestAllExceptionsQuery,
  useGetDisposalRequestRejectedInOtherRequestExceptionsQuery,
  useGetItemExceptionsQuery
} from '../services';
import { DisposalDetailsCollection } from '../types';

interface UseDisposalRequestParams {
  id?: string;
  userApiError?: boolean;
}
interface UseExceptionRequestParams {
  itemId?: string;
  disposalId?: string;
  userApiError?: boolean;
}

//Used for exceptions on the disposal request details page 
export const useDisposalDetailsExceptions = ({
  id,
  userApiError = false,
}: UseDisposalRequestParams) => {
  const {
    data: actioningData,
    isSuccess: actioningIsSuccess,
    isError: actioningIsError,
    error: actioningError,
  } = useGetDisposalRequestRetentionConflictExceptionsQuery(
    {
      requestId: id!,
    },
    { skip: !id }
  );

  const {
    data: rejectedData,
    isSuccess: rejectedIsSuccess,
    isError: rejectedIsError,
    error: rejectedError,
  } = useGetDisposalRequestRejectedInOtherRequestExceptionsQuery(
    {
      requestId: id!,
    },
    { skip: !id }
  );

  const error = [rejectedError, actioningError];
  const isSuccess = actioningIsSuccess && rejectedIsSuccess;
  const isError = actioningIsError && rejectedIsError;

  if (isError && (rejectedError || actioningError)) {
    console.error(error);
  }

  const transformedData = userApiError
    ? undefined
    : {
        RetentionConflict:
          ((actioningData && actioningData['@odata.count']) ?? 0) > 0,
        RejectedInOtherRequest:
          ((rejectedData && rejectedData['@odata.count']) ?? 0) > 0,
      };

  return { data: transformedData, isSuccess, isError };
};

//Used for items exceptions on a per item basis
export const useItemExceptions = ({
  disposalId,
  itemId,
  userApiError
}: UseExceptionRequestParams) => {
  const {
    data,
    isSuccess,
    isError,
    error
  } = useGetItemExceptionsQuery(
    {
      itemId: itemId!,
      disposalId: disposalId,
    },
  );

  if (isError && error) {
    console.error(error);
  }

  const value  = (data as unknown as DisposalDetailsCollection)?.value ?? null
  const transformedData = (() => {
    switch (true) {
      case userApiError == true:
        return undefined
      case value === null:
        return null
      case value !== null:
        //transform FailureDetail property into FailedToDestroy property
        if ((value[0] as any).FailureDetail !== "None" && (value[0] as any).FailureDetail !== "DisposalSuccessful") {
          const resultWithFailedToDestroy = {
            ...value[0],
            FailedToDestroy: true,
          }

          console.log('resultWithFailedToDestroy', resultWithFailedToDestroy)
          return resultWithFailedToDestroy;
        }

        return {
          ...value[0],
          FailedToDestroy: false,
        }
      default:
        return null;
    }
  })()

  return { data: transformedData, isSuccess, isError };
};

//Used for displaying the total excpetion count for disposal details exceptions on a per disposal request basis
export const useDisposalRequestExceptions = ({
  id,
  userApiError
}: UseDisposalRequestParams) => {
  const {
    data,
    isSuccess,
    isError,
    error
  } = useGetDisposalRequestAllExceptionsQuery(
    {
      requestId: id,
    },
  );

  if (isError && error) {
    console.error(error);
  }

  const transformedData = userApiError
    ? undefined
    : {
        count:
          (data && data['@odata.count']) ?? 0,
      };


  return { data: transformedData, isSuccess, isError };
};
