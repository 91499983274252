import { useEffect, useRef, useState } from 'react';

export const useTooltip = (data) => {
  const dataRef = useRef(null);
  
  const [hasTooltip, setHasTooltip] = useState<boolean>();

  const toggleTooltip = () => {
    const containerHeight = dataRef.current?.clientHeight;
    const textHeight = dataRef.current?.scrollHeight;

    const containerWidth = dataRef.current?.clientWidth;
    const textWidth = dataRef.current?.scrollWidth;

    if (textHeight > containerHeight || textWidth > containerWidth) setHasTooltip(true);
    else setHasTooltip(false);
  }

  useEffect(() => {
    toggleTooltip();
    window.addEventListener("resize", toggleTooltip);
    return () => window.removeEventListener("resize", toggleTooltip)
  }, [data]);


  return {
    dataRef,
    hasTooltip,
    title: hasTooltip ? data : ''
  };
};

