import { Configuration, RedirectRequest } from '@azure/msal-browser';
import { config } from '../config';

// TODO:
// - move hardcoded ids to .env file
export const msalConfiguration: Configuration = {
  auth: {
    clientId: config.MSAL_CLIENT_ID,
    authority: config.MSAL_AUTHORITY,
    redirectUri: '/',
    postLogoutRedirectUri: config.REACT_APP_ENC_LANDING_APP_URL,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest: RedirectRequest = {
  scopes: [
    config.MSAL_SCOPE,
  ],
};
