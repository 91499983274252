import { Divider, useTheme } from '@mui/material';
import { Box } from './Box';
import { HomeButton, ParentNavigationButton } from './button/CustomButton';

export type ItemNavigationBarProps = {
  parentName?: string;
  onHome?: () => void;
  onParent?: () => void;
};

export const ItemNavigationBar = ({
  parentName = 'Disposal Request',
  onHome,
  onParent,
}: ItemNavigationBarProps) => {
  const theme = useTheme();
  return (
    <Box
      background='dark'
      direction='row'
      rounded='top'
      height={3.25}
      alignItems='center'
      justifyContent='start'
      gap='xsmall'
      style={{ padding: '0 1rem' }}
    >
      <HomeButton
        color='white'
        onClick={onHome && onHome}
        data-testid='item-nav-bar-home-btn'
      />
      <Divider
        orientation='vertical'
        style={{
          borderColor: theme.palette.info[800],
          height: '1.25rem',
          marginRight: '0.25rem',
        }}
      />
      {onParent && (
        <ParentNavigationButton
          size='small'
          color='white'
          onClick={onParent}
          text={parentName}
          data-testid='item-nav-bar-parent-btn'
        />
      )}
    </Box>
  );
};
