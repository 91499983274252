import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Box } from './Box';
import { List, TextField, useTheme } from '@mui/material';

import {
  ChevronDown20Regular,
  ChevronUp20Regular,
} from '@fluentui/react-icons';
import { Button, DeleteButton } from './button';
import React from 'react';
import { OptionSelectItem } from './OptionSelectItem';
import { Typography } from './Typography';

// Define generic types for the component props
interface OptionSelectProps<T> {
  options: T[];
  hasIcon?: boolean;
  placeholder: string;
  handleDelete?: () => void;
  itemIcon?: React.ReactNode;
  isValidOptionList?: boolean;
  selectedOption: React.ReactNode;
  handleSelect?: (option: T) => void;
  handleSearch?: (searchTerm: string, searchResult?: T[]) => void;
}

export const OptionSelect = memo(
  <
    T extends {
      ID: string;
      _Display: string;
      _ImageId: string;
    }
  >({
    options,
    itemIcon,
    placeholder,
    handleDelete,
    handleSearch,
    handleSelect,
    hasIcon = true,
    selectedOption,
    isValidOptionList = true,
  }: OptionSelectProps<T>) => {
    const theme = useTheme();

    const searchTermRef = useRef<string>();

    const [optionList, setOptionList] = useState<T[]>(options);
    const [showOptionList, setShowOptionList] = useState<boolean>(false);

    useEffect(() => {
      if (!options?.length) return;
      const searchTerm = searchTermRef.current;

      if (searchTerm) {
        const searchResult = performSearch(searchTerm);

        setOptionList(searchResult);
      } else {
        setOptionList(options);
      }
    }, [options]);

    const borderColor = isValidOptionList
      ? theme.palette.info[600]
      : theme.palette.warning.main;

    const onSearch = useCallback(
      (event) => {
        // perform default search
        const searchTerm = event.target.value;

        const searchResult = performSearch(searchTerm);

        setOptionList(searchResult);

        /**
         * Returns search term and search result (optional).
         *
         * @param {string} searchTerm term to search.
         * @param {T[]} searchResult search result based on the given search term.
         */
        handleSearch && handleSearch(searchTerm, searchResult);
      },
      [options]
    );

    const performSearch = useCallback(
      (searchTerm: string) => {
        searchTermRef.current = searchTerm;

        const searchResult: T[] = options?.filter((option: T) => {
          const formattedOption = option?._Display.toLowerCase();
          const formattedSearchTerm = searchTerm.toLowerCase();

          return formattedOption.includes(formattedSearchTerm);
        });

        return searchResult;
      },
      [options]
    );

    const renderSelectedOption = () => {
      // if no selected option, render the placeholder.
      if (!selectedOption) {
        return placeholder;
      }

      // if the selected option is a string
      if (typeof selectedOption === 'string') {
        return <Typography variant='body2'>{selectedOption}</Typography>;
      }

      // render a custom element for the selected option.
      return selectedOption;
    };

    return (
      <Box
        width='25rem'
        background='none'
        style={{ marginBottom: '0.75rem', marginTop: '0.5rem' }}
      >
        <Box background='none'>
          <Button
            color='info'
            variant='outlined'
            endIcon={
              <Box background='none' direction='row' alignItems='center'>
                {selectedOption && !!handleDelete ? (
                  <DeleteButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete();
                    }}
                    size='medium'
                    sx={{
                      width: '1.25rem',
                      height: '1.25rem',
                      marginRight: '0.25rem',
                    }}
                  />
                ) : null}
                {showOptionList ? (
                  <ChevronUp20Regular color={theme.palette.primary.main} />
                ) : (
                  <ChevronDown20Regular color={theme.palette.primary.main} />
                )}
              </Box>
            }
            sx={{
              borderColor,
              width: '25rem',
              height: '3rem',
              fontFamily: 'Avenir Roman',
              justifyContent: 'space-between',
            }}
            onClick={() => {
              setShowOptionList((prevState) => !prevState);
            }}
          >
            {renderSelectedOption()}
          </Button>
          <Box
            background='none'
            style={{
              position: 'absolute',
              background: 'white',
              marginTop: '3.313rem',
              borderRadius: '0.5rem',
              display: showOptionList ? 'unset' : 'none',
              boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.15)',
              border: `0.063rem solid ${theme.palette.info[600]}`,
            }}
            direction='column'
          >
            <TextField
              size='small'
              sx={{
                height: '2.5rem',
                width: '23.4rem',
                margin: '0.5rem 0.75rem',
              }}
              onChange={(event) => onSearch(event)}
              placeholder='Search'
            />
            {optionList?.length ? (
              <List
                sx={{
                  padding: 0,
                  width: '25rem',
                  overflow: 'auto',
                  maxHeight: '11rem',
                  borderRadius: '0.5rem',
                }}
              >
                {optionList.map((option: T, index: number) => (
                  <OptionSelectItem
                    option={option}
                    hasIcon={hasIcon}
                    itemIcon={itemIcon}
                    handleSelect={handleSelect}
                    key={`${option?.ID}${index}`}
                  />
                ))}
              </List>
            ) : (
              <Typography variant='body2' ml={2} mb={2}>
                No records to display
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);
