import { ListItemButton, Typography } from '@mui/material';
import { memo } from 'react';
import { useIcon } from '../hooks';
import { ItemIconValue } from '../types';
import { Box } from './Box';
import { StyledImage } from './SortableOptionList';

interface CollapsibleGroupedItem<T> {
  idx?: number;
  filteredOption: T;
  handleSelect: (option: T) => void;
}

export const getOptionIcon = (iconDetails: ItemIconValue) => {
  // check if the icon is enabled and has icon details
  if (iconDetails) {
    return (
      <StyledImage
        alt={iconDetails.AltText}
        style={{ height: '1.5rem' }}
        src={`${process.env.REACT_APP_ENC_IMC_URL}${iconDetails.Url}`}
      />
    );
  }

  // no icon will be displayed
  return null;
};

export const CollapsibleGroupedItem = memo(
  <
    T extends {
      ID?: string;
      _Display?: string;
      _ImageId?: string;
    }
  >({
    idx,
    filteredOption,
    handleSelect,
  }: CollapsibleGroupedItem<T>) => {
    const { _ImageId: imageId } = filteredOption;

    const { iconDetails } = useIcon({
      imageId,
    });

    return (
      <ListItemButton
        key={idx}
        sx={{ paddingLeft: '4rem' }}
        onClick={() => handleSelect(filteredOption)}
      >
        <Box background='none' direction='row' alignItems='center'>
          {getOptionIcon(iconDetails)}
          <Typography variant='body2' ml={1}>
            {filteredOption._Display}
          </Typography>
        </Box>
      </ListItemButton>
    );
  }
);
