import { Box } from './Box';
import { DisposalRequestStatus } from '../types';
import {
  DisposalRequestProgressStatus,
  DisposalRequestProgressIndicatorStatus,
} from './DisposalRequestProgressIndicatorStatus';

export type DisposalRequestProgressIndicatorProps = {
  requestStatus?: DisposalRequestStatus;
  withWarning?: boolean;
  isBlocked?: boolean;
  multipleRetentionClasses?: boolean;
};

const getProgressStatus = (
  requestStatus,
  isBlocked,
  withWarning,
  multipleRetentionClasses
) => {
  switch (requestStatus) {
    case DisposalRequestStatus.InApproval:
      return {
        indicator1: DisposalRequestProgressStatus.COMPLETE,
        indicator2: DisposalRequestProgressStatus.IN_PROGRESS,
        indicator3: DisposalRequestProgressStatus.PENDING,
        // indicator4: DisposalRequestProgressStatus.PENDING,
      };
    case DisposalRequestStatus.Approved:
      return {
        indicator1: DisposalRequestProgressStatus.COMPLETE,
        indicator2: DisposalRequestProgressStatus.COMPLETE,
        indicator3: isBlocked
          ? DisposalRequestProgressStatus.BLOCKED
          : DisposalRequestProgressStatus.PENDING,
        // indicator4: DisposalRequestProgressStatus.PENDING,
      };
    case DisposalRequestStatus.InProgress:
    case DisposalRequestStatus.Failed:
      return {
        indicator1: DisposalRequestProgressStatus.COMPLETE,
        indicator2: DisposalRequestProgressStatus.COMPLETE,
        indicator3: DisposalRequestProgressStatus.IN_PROGRESS,
        // indicator4: DisposalRequestProgressStatus.PENDING,
      };
    case DisposalRequestStatus.Complete:
      return {
        indicator1: DisposalRequestProgressStatus.COMPLETE,
        indicator2: DisposalRequestProgressStatus.COMPLETE,
        indicator3: DisposalRequestProgressStatus.COMPLETE,
        // indicator4: DisposalRequestProgressStatus.COMPLETE,
      };
    case DisposalRequestStatus.Rejected:
      return {
        indicator1: DisposalRequestProgressStatus.COMPLETE,
        indicator2: DisposalRequestProgressStatus.REJECTED,
        indicator3: DisposalRequestProgressStatus.PENDING,
        // indicator4: DisposalRequestProgressStatus.PENDING,
      };
    default:
      return {
        indicator1: multipleRetentionClasses
          ? DisposalRequestProgressStatus.COMPLETE
          : DisposalRequestProgressStatus.IN_PROGRESS,
        indicator2: DisposalRequestProgressStatus.PENDING,
        indicator3: DisposalRequestProgressStatus.PENDING,
        // indicator4: DisposalRequestProgressStatus.PENDING,
      };
  }
};

export const DisposalRequestProgressIndicator = ({
  requestStatus,
  withWarning,
  isBlocked,
  multipleRetentionClasses,
  ...rest
}: DisposalRequestProgressIndicatorProps) => {
  if (
    ![
      DisposalRequestStatus.New,
      DisposalRequestStatus.InApproval,
      DisposalRequestStatus.Approved,
      DisposalRequestStatus.Rejected,
      DisposalRequestStatus.Complete,
      DisposalRequestStatus.InProgress,
      DisposalRequestStatus.Failed,
    ].includes(requestStatus as DisposalRequestStatus)
  )
    return null;

  const { indicator1, indicator2, indicator3 } = getProgressStatus(
    requestStatus,
    isBlocked,
    withWarning,
    multipleRetentionClasses
  );

  return (
    <Box gap='medium' direction='row' background='none' {...rest}>
      <DisposalRequestProgressIndicatorStatus
        progressStatus={indicator1}
        sequenceNumber='1'
        label='Prepare Request'
        withWarning={
          (requestStatus === DisposalRequestStatus.New &&
            multipleRetentionClasses) ||
          withWarning == true
        }
      />
      <DisposalRequestProgressIndicatorStatus
        progressStatus={indicator2}
        sequenceNumber='2'
        label='Request Approval'
        withWarning={
          requestStatus === DisposalRequestStatus.InApproval ||
          withWarning == true
        }
      />
      <DisposalRequestProgressIndicatorStatus
        progressStatus={indicator3}
        sequenceNumber='3'
        label='Action Disposal'
        withWarning={false}
        isBlocked={requestStatus === DisposalRequestStatus.Failed}
        isLast
      />
    </Box>
  );
};
