import { ChevronDownFilled } from '@fluentui/react-icons';
import {
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Tooltip } from './Tooltip';

export type FilterDropdownProps = {
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  menuOptions: string[];
};

export const FilterDropdown = ({
  value,
  onChange,
  menuOptions,
}: FilterDropdownProps) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      variant='outlined'
      input={<OutlinedInput notched={false} />}
      IconComponent={ChevronDownFilled}
      sx={{
        width: '12.75rem',
        margin: '0 1.75rem 0 0',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
      MenuProps={{ classes: { paper: 'filter-dropdown' } }}
    >
      {menuOptions?.map((option, index) => {
        const dataRef = useRef(null);

        const [hasTooltip, setHasTooltip] = useState<boolean>();

        useEffect(() => {
          const containerWidth = dataRef.current?.clientWidth;
          const textWidth = dataRef.current?.scrollWidth;

          if (textWidth > containerWidth) setHasTooltip(true);
          else setHasTooltip(false);
        }, [option]);

        return option
          ? [
              <MenuItem key={index} value={option}>
                <Tooltip title={hasTooltip ? option : ''} arrow placement='top'>
                  <Typography variant='body2' ref={dataRef}>
                    {option}
                  </Typography>
                </Tooltip>
              </MenuItem>,
            ]
          : null;
      })}
    </Select>
  );
};
