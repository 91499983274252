import { Alert } from './Alert';

type DisposalRequestAlertProps = {
  open: boolean;
  title?: string;
  message?: string;
  onClose: () => void;
};

export const DisposalRequestSuccessAlert = ({
  open = false,
  onClose,
  title = 'Disposal Request Approved',
  message = 'Your response was successfully submitted.',
}: DisposalRequestAlertProps) => {
  return (
    <Alert
      alertType='success'
      title={title}
      message={message}
      open={open}
      onClose={onClose}
      autoHideDuration={10000}
    />
  );
};

export const DisposalRequestWarningAlert = ({
  open = false,
  title = 'Response submission failure',
  message = 'Your response submission failed. Please try again, or contact your Administrator.',
  onClose,
}: DisposalRequestAlertProps) => {
  return (
    <Alert
      alertType='warning'
      title={title}
      message={message}
      open={open}
      onClose={onClose}
    />
  );
};
