import {
  Add16Regular,
  Edit16Regular,
  Delete16Regular,
  Checkmark20Filled,
} from '@fluentui/react-icons';
import {
  Sort,
  Edit,
  Freeze,
  Resize,
  Inject,
  Toolbar,
  ColumnDirective,
  TreeGridComponent,
  ColumnsDirective,
} from '@syncfusion/ej2-react-treegrid';
import { forwardRef, memo, RefObject, useMemo } from 'react';
import { useCustomTypeTreeGrid } from '../hooks/useCustomTypeTreeGrid';

import { BusinessTypePropDefsValue } from '../types';
import { formatCustomTypeDate } from '../util';
import { Box } from './Box';
import { TextIconButton } from './button';
import { PerspectiveClassChip } from './chip';
import { isPerspectiveClass } from './item/ItemPropertiesUtil';
import { isBoolean, isDate, isDateTime } from './item/utils';
import { SearchField } from './SearchField';

export type CustomTypeTreeGridProps = {
  treeGridData?: any[];
  propertyDetails: BusinessTypePropDefsValue;
  handleAdd: (selectedRow?: any) => void;
  handleDelete: (selectedRow: any) => void;
  handleActionComplete: (args) => void;
};

export const CustomTypeTreeGrid = forwardRef(
  (
    {
      propertyDetails,
      treeGridData,
      handleAdd,
      handleDelete,
      handleActionComplete,
    }: CustomTypeTreeGridProps,
    treeGridRef: RefObject<TreeGridComponent>
  ) => {
    const {
      editOptions,
      isAllowAdding,
      isAllowEditing,
      isAllowDeleting,
      editTemplate,
      load,
      onSearch,
      handleEdit,
      onActionBegin,
      onClickAddButton,
      handleRowSelecting,
      onClickDeleteButton,
    } = useCustomTypeTreeGrid({
      treeGridData,
      treeGridRef,
      propertyDetails,
      handleAdd,
      handleDelete,
    });

    const hoverAttributes = { class: 'editable-column' };

    const memoizedValueColumnTemplate = useMemo(() => {
      return (item) => {
        const isEditable =
          !item?.fieldDetails?.IsCollection && !item?.childRecords?.length;
        // Render perspective class
        if (item.value && isPerspectiveClass(item.value))
          return (
            <div className={isEditable ? 'editable-cell' : ''}>
              <PerspectiveClassChip businessClass={item.value} />
            </div>
          );
        // Render boolean
        else if (
          (item.value !== null || item.value !== '') &&
          isBoolean(item?.fieldDetails)
        ) {
          let booleanValue = '';
          if (item.value === true || item.value === 'true')
            booleanValue = 'Yes';
          else if (item.value === false || item.value === 'false')
            booleanValue = 'No';

          return (
            <div className={isEditable ? 'editable-cell' : ''}>
              {booleanValue}
            </div>
          );
        }
        // Render date
        else if (
          item.value &&
          (isDate(item?.fieldDetails) || isDateTime(item?.fieldDetails))
        )
          return (
            <div className={isEditable ? 'editable-cell' : ''}>
              {formatCustomTypeDate(item.value, isDateTime(item?.fieldDetails))}
            </div>
          );

        return (
          <div className={isEditable ? 'editable-cell' : ''}>{item?.value}</div>
        );
      };
    }, []);

    const memoizedImportantColumnTemplate = useMemo(() => {
      return (item) => {
        switch (item.important) {
          case 'Yes':
            return (
              <Box background='none'>
                <Checkmark20Filled />
              </Box>
            );
          case 'No':
            return '';
          default:
            return item.important;
        }
      };
    }, []);

    return (
      <>
        <Box
          direction='row'
          background='none'
          alignItems='center'
          justifyContent='space-between'
          style={{ marginBottom: 0, paddingTop: 0, minHeight: '5.5rem' }}
        >
          <SearchField
            height='3rem'
            width='17.188rem'
            onHandleSearch={onSearch}
          />
          <Box background='none' direction='row' justifyContent='end'>
            <TextIconButton
              text='Add'
              startIcon={<Add16Regular />}
              sx={{ padding: '0.5rem 1.5rem' }}
              onClick={onClickAddButton}
              disabled={!isAllowAdding}
            />
            <TextIconButton
              text='Delete'
              startIcon={<Delete16Regular />}
              sx={{ padding: '0.5rem 1.5rem' }}
              onClick={onClickDeleteButton}
              disabled={!isAllowDeleting}
            />
            <TextIconButton
              text='Edit'
              startIcon={<Edit16Regular />}
              sx={{ padding: '0.5rem 1.5rem' }}
              onClick={handleEdit}
              disabled={!isAllowEditing}
            />
          </Box>
        </Box>
        <Box background='none'>
          <TreeGridComponent
            height='100%'
            enablePersistence
            ref={treeGridRef}
            allowSorting={true}
            treeColumnIndex={0}
            childMapping='Items'
            allowResizing={true}
            editSettings={editOptions}
            className='item-custom-type-tree-grid'
            id='item-custom-type-tree-grid'
            load={load}
            actionBegin={onActionBegin}
            rowSelecting={handleRowSelecting}
            actionComplete={handleActionComplete}
          >
            <ColumnsDirective>
              <ColumnDirective
                width='448'
                field='propertyName'
                allowEditing={false}
                headerText='PROPERTY NAME'
              />
              <ColumnDirective
                width='448'
                field='value'
                edit={editTemplate}
                headerText='PROPERTY VALUE'
                template={memoizedValueColumnTemplate}
                type='string'
                customAttributes={hoverAttributes}
              />
              <ColumnDirective
                field='important'
                allowEditing={false}
                headerText='IMPORTANT'
                template={memoizedImportantColumnTemplate}
              />
            </ColumnsDirective>
            <Inject services={[Sort, Edit, Toolbar, Resize, Freeze]} />
          </TreeGridComponent>
        </Box>
      </>
    );
  }
);

export const MemoizedCustomTypeTreeGrid = memo(CustomTypeTreeGrid);
