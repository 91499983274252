import _ from "lodash";
import { uniqueId } from "lodash";
import { ItemBusinessClass } from "../../../../types";
import { sortPropertiesByName } from "../../../item/ItemPropertiesUtil";
import { isEntity } from "../../../item/utils";

export const getDisplayName = (value) => {
  return value?._Display && isNaN(parseInt(value?._Display))
    ? `: ${value?._Display}`
    : '';
};

const getTreeGridData = (
  display,
  propertyValue,
  fieldProperties,
  propertyDetails,
  treeGridProperties,
  rowId,
  businessObject,
) => {
  // generate a unique id, this will be used for deleting the parent data.
  const id = uniqueId('CT_');
  const isCollection = propertyDetails.IsCollection;
  const parentPropertyName = `${
    fieldProperties?.TreeDataTypeDef?.Caption ??
    fieldProperties?.TreeDataTypeDef?._Display
  }${display}`;

  const TreeDataTypeDefId = fieldProperties?.TreeDataTypeDef?.ID;
  // get editable properties
  const editableFields = fieldProperties?.EditablePropDefs?.value;
  const propGroupsMapping = getPropGroupsMapping(treeGridProperties);

  const data = { ID: propertyValue?.ID ?? null, TypeDefId: propertyValue?.TypeDefId ?? TreeDataTypeDefId };

  // get tree grid items based on the editable fields
  const treeGridData = editableFields?.map((field) => {

    const isImportant = getIsImportant(field.Name, TreeDataTypeDefId, propGroupsMapping);
    const Items = [];
    // generate a unique id, this will be used for editing the child data.
    const id = uniqueId('ITEM_');
    const value = propertyValue?.[field.Name];
    const propertyName = `${field.Caption ?? field._Display}${
      field.IsCollection ? ` (${value?.length ?? value?.value?.length ?? 0})` : ''
    }`;

    // Check if the field is a custom type
    const fieldProperties = treeGridProperties?.TypeData?.value?.find(
      (val) => val?.TreeDataTypeDef?.ID === field?.PropTypeDefId
    );

    // Check if the field is a perspective class
    const isPerspectiveClass = isEntity(field) &&
    field?.PropTypeDef?.Name?.indexOf('IT_') === 0 &&
    field?.PropTypeDef?.Category?.InternalName === 'Inheritance';

    // Check if the field has items and a custom type that is not a collection
    const isCustomTypeWithItems = (isEntity(field) && !field.IsCollection && !!fieldProperties && !isPerspectiveClass) || (!!value && isEntity(field) && !!fieldProperties && !isPerspectiveClass);

    // if the field has items
    if (isCustomTypeWithItems) Items.push(...constructTreeGridData(value, businessObject, field, treeGridProperties));

    const important = field.IsCollection || isCustomTypeWithItems ? '--' : isImportant ? 'Yes' : 'No';

    const _data = { ID: value?.ID ?? null, TypeDefId: value?.TypeDefId };

    return {
      id,
      Items,
      important,
      propertyName,
      propDef: field,
      fieldDetails: { ...field, TreeDataTypeDefId, RowId: rowId },
      value: isCustomTypeWithItems ? '' : value,
      data: _data
    };
  });

  // create tree grid data with child if the property is a collection
  if (isCollection) {
    return {
      id,
      important: "--",
      propertyName: parentPropertyName,
      Items: sortPropertiesByName(treeGridData),
      data
    };
  }

  // return the tree grid data.
  return sortPropertiesByName(treeGridData);
};

export const constructTreeGridData = (
  propertyValue,
  businessObject,
  propertyDetails,
  treeGridProperties,
) => {
  const display = getDisplayName(propertyValue);
  const isCollection = propertyDetails.IsCollection;
  // find the needed field properties from the tree grid properties
  const fieldProperties = treeGridProperties?.TypeData?.value?.find(
    (val) => val?.TreeDataTypeDef?.ID === propertyDetails?.PropTypeDefId
  );

  // display initial tree grid data for collection
  if (!propertyValue && isCollection) {
    return [
      getTreeGridData('', propertyValue, fieldProperties, propertyDetails, treeGridProperties, null, businessObject),
    ];
  }

  // if the property value is an array, it will generate tree grid data with values
  if (Array.isArray(propertyValue?.value)) {
    return propertyValue?.value?.map((val) => {
      const rowId = val?.ID ?? null;
      const display = getDisplayName(val);
      return getTreeGridData(display, val, fieldProperties, propertyDetails, treeGridProperties, rowId, businessObject);
    });
  }

  return getTreeGridData(
    display,
    propertyValue,
    fieldProperties,
    propertyDetails,
    treeGridProperties,
    propertyValue?.ID, 
    businessObject
  );
};

/**
 * addCollection - this function is used to add a new child from a collection
 * @param treeGridData current tree grid data
 * @param id id of the parent of the selected row or id of the selected row
 * @param propertyName selected property name
 * @param fieldDetails it contains the details of the selected row
 * @param treeGridProperties this is where we can get the fields to be added on the tree grid
 */
export const addCollection = (_treeGridData, id, propertyName, fieldDetails, treeGridProperties, businessObject) => {
  _treeGridData.forEach((gridItem) => {
    // find the parent of the selected element using id.
    if (gridItem.id === id) {
      // find the child item object based on the selected propertyName
      const childItem = gridItem.Items.find(
        (item) => item.propertyName === propertyName
      );

      // set the child items
      if (!!childItem) {
        childItem.Items = [
          ...childItem.Items,
          ...constructTreeGridData('', businessObject,  fieldDetails, treeGridProperties),
        ];
      } else {
        gridItem.Items = [
          ...gridItem.Items,
          ...constructTreeGridData('', businessObject, fieldDetails, treeGridProperties),
        ];
      }
    } else {
      // if the id didn't matched, call a recursive function
      addCollection(gridItem.Items, id,  propertyName, fieldDetails, treeGridProperties, businessObject);
    }
  });
};

export const removeItem = (treeData, targetDepth, depth = 0, selectedRow, id) => {
  if (depth === targetDepth) {
    // remove the items if the parent is selected
    if (selectedRow?.fieldDetails?.IsCollection) {
      return treeData.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            Items: [],
          };
        }

        return item;
      });
    }

    // filter the data, remove the selected item
    return treeData.filter((item) => item.id !== id);
  }

  // find the item and remove it from the array
  return treeData.map((item) => {
    if (item?.Items) {
      return {
        ...item,
        Items: removeItem(item.Items, targetDepth, depth + 1, selectedRow, id),
      };
    }
    return item;
  });
};

export const getEnumValues = (data, caption) => {
  const enumObj = data?.value?.find(
    (val) => val.Caption === caption
  );

  const propTypeDefMembers =
    enumObj?.Members?.value?.map((currency) => {
      const title = currency.Description ?? currency.Caption;
      return {
        name: currency.Name,
        id: currency.Value,
        title,
        Caption: currency.Caption,
        show: true,
      };
    }) ?? [];

  // Adding an empty value for enum list
  const enumValues = [
    {
      name: '',
      id: '',
      title: '',
      Caption: '',
      show: true,
    },
    ...propTypeDefMembers,
  ];

  return enumValues;
}

export const getPerspectiveClassValues = (data, propTypeDefId, selectedPerspectiveClass) => {
  const filteredPerspectiveData = data?.value?.filter(
    (val) => val.TypeDefId === propTypeDefId
  );

  const perspectiveClasses: ItemBusinessClass[] =
    filteredPerspectiveData?.filter(
      (perspectiveClass: ItemBusinessClass) =>
        perspectiveClass._Display !== selectedPerspectiveClass
    );

  return perspectiveClasses;
}

export const deepMergeArrays = (currentTreeGridData, newTreeGridData, action) => {
  const mergedArray = action === 'complete' ? _.unionBy(currentTreeGridData, newTreeGridData, 'id') : currentTreeGridData.length > newTreeGridData.length ? _.unionBy(currentTreeGridData, newTreeGridData, 'id') : _.unionBy(newTreeGridData, currentTreeGridData, 'id') 

  // Recursively merge 'Items' arrays
  mergedArray.forEach((item: any) => {
    const correspondingItem = newTreeGridData.find((el) => el.id === item.id);
    if (correspondingItem) {
      if (item.Items && correspondingItem.Items) {
        item.Items = deepMergeArrays(item.Items, correspondingItem.Items, action);
      }
    }
  });

  return mergedArray;
}


export const getPropGroupsMapping = (treeGridProperties) => {
  const _propGroupsMapping = {};
  treeGridProperties.TypeData.value.forEach((typeData) => {
    // Prop Groups
    if (typeData.PropLinkGroups && typeData.PropLinkGroups.value)
      _propGroupsMapping[typeData.TreeDataTypeDef.ID] =
        typeData.PropLinkGroups.value;
    else _propGroupsMapping[typeData.TreeDataTypeDef.ID] = [];
  });

  return _propGroupsMapping;
};

// Checks whether the given property is marked as important in the specified typedef
const getIsImportant = (propertyName, typeDefId, propGroupsMapping) => {
  const propGroupsForTypeDef = propGroupsMapping[typeDefId];

  let important = false;

  if (propGroupsForTypeDef) {
    const importantPropGroup = propGroupsForTypeDef.find(
      (g) => g.Purpose === 'IsImportant'
    );
    if (importantPropGroup) {
      if (importantPropGroup.PropLinks) {
        const importantProperties = importantPropGroup.PropLinks.value;
        if (importantProperties) {
          const prop = importantProperties.find(
            (p) => p.PropDef.Name === propertyName
          );
          important = prop != null;
        }
      }
    }
  }

  return important;
};