import { TooltipProps, Tooltip as MUITooltip } from '@mui/material';

export const Tooltip = ({ title, children, ...props }: TooltipProps) => {
  return (
    <MUITooltip
      title={
        typeof title === 'string' && title ? (
          <span
            className='tooltip-title'
            style={{
              whiteSpace: 'pre-line',
              overflow:
                title == 'Additional Information' ? 'scroll' : undefined,
              textOverflow: 'clip',
            }}
          >
            {title}
          </span>
        ) : (
          title
        )
      }
      {...props}
    >
      {children}
    </MUITooltip>
  );
};
