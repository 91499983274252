import { Collapse, List } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useGetBusinessCatalogNodeQuery } from '../services';
import { Box } from './Box';
import { CollapsibleGroupedItem } from './CollapsibleGroupedItem';
import { CollapsibleGroupedParentItem } from './CollapsibleGroupedParentItem';

interface CollapsibleGroupedList<T> {
  groupBy?: string;
  optionList: T[];
  handleSelect: (option: T) => void;
}

export const CollapsibleGroupedList = memo(
  <
    T extends {
      ID?: string;
      _Display?: string;
      _ImageId?: string;
    }
  >({
    handleSelect,
    optionList,
    groupBy,
  }: CollapsibleGroupedList<T>) => {
    // Getting all the groups based on the groupBy property
    const [expandedGroups, setExpandedGroups] = useState(
      optionList?.map((option) => option[groupBy])
    );

    // TODO: update this, this is for getting the icon of a perspective class
    const { data: businessCatalogNode } = useGetBusinessCatalogNodeQuery(
      {},
      { skip: !groupBy }
    );

    const businessCatalogNodeValues =
      businessCatalogNode?.catalogNode?.Children?.value;

    useEffect(() => {
      if (!optionList?.length) return;

      const groups = optionList?.map((option) => option[groupBy]);

      setExpandedGroups(groups);
    }, [optionList]);

    const handleGroupToggle = (group) => {
      setExpandedGroups((prevExpandedGroups) =>
        prevExpandedGroups?.includes(group)
          ? prevExpandedGroups?.filter((item) => item !== group)
          : [...prevExpandedGroups, group]
      );
    };

    return (
      <Box background='none' style={{ overflow: 'auto' }}>
        <List
          sx={{
            padding: 0,
            width: '100%',
            overflow: 'auto',
            borderRadius: '0.5rem',
            height: 'calc(100% - 4rem)',
          }}
        >
          {/* Filter the duplicate group name and display the items under each groups */}
          {Array.from(
            new Set(optionList?.map((option) => option?.[groupBy]))
          ).map((groupName, index) => {
            return (
              <div key={`${groupName}-${index}`}>
                <CollapsibleGroupedParentItem
                  groupName={groupName}
                  expandedGroups={expandedGroups}
                  handleGroupToggle={handleGroupToggle}
                  businessCatalogNodeValues={businessCatalogNodeValues}
                />
                <Collapse in={expandedGroups?.includes(groupName)}>
                  <List>
                    {optionList
                      ?.filter((option) => option?.[groupBy] === groupName)
                      ?.map((filteredOption, idx) => (
                        <CollapsibleGroupedItem
                          idx={idx}
                          key={`${groupName}-${idx}`}
                          filteredOption={filteredOption}
                          handleSelect={() => handleSelect(filteredOption)}
                        />
                      ))}
                  </List>
                </Collapse>
              </div>
            );
          })}
        </List>
      </Box>
    );
  }
);
