type Config = {
  TITLE: string;
  NAME: string;
  VERSION: string;
  REACT_APP_ENC_BUILD: string;
  REACT_APP_ENC_COMMIT: string;
  API_BASE_URL: string;
  MSAL_CLIENT_ID: string;
  MSAL_AUTHORITY: string;
  MSAL_SCOPE: string;
  SYNCFUSION_LICENSE: string;
  REACT_APP_ENC_IMC_URL: string;
  REACT_APP_ENC_LANDING_APP_URL: string;
  REACT_APP_ENC_DISPOSAL_APPROVAL_APP_URL: string;
  REACT_APP_ENC_DISPOSAL_APP_URL: string;
  REACT_APP_ENC_ZENDESK_KEY: string;
};

export const config: Config = {
  TITLE: process.env.REACT_APP_TITLE,
  NAME: process.env.REACT_APP_NAME,
  VERSION: process.env.REACT_APP_VERSION,
  REACT_APP_ENC_BUILD: process.env.REACT_APP_ENC_BUILD,
  REACT_APP_ENC_COMMIT: process.env.REACT_APP_ENC_COMMIT,
  SYNCFUSION_LICENSE: process.env.REACT_APP_SYNCFUSION_LICENSE,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  MSAL_CLIENT_ID: process.env.REACT_APP_MSAL_CLIENT_ID,
  MSAL_AUTHORITY: process.env.REACT_APP_MSAL_AUTHORITY,
  MSAL_SCOPE: process.env.REACT_APP_MSAL_SCOPE,
  REACT_APP_ENC_IMC_URL: process.env.REACT_APP_ENC_IMC_URL,
  REACT_APP_ENC_LANDING_APP_URL: process.env.REACT_APP_ENC_LANDING_APP_URL,
  REACT_APP_ENC_DISPOSAL_APPROVAL_APP_URL:
        process.env.REACT_APP_ENC_DISPOSAL_APPROVAL_APP_URL,
  REACT_APP_ENC_DISPOSAL_APP_URL:
        process.env.REACT_APP_ENC_DISPOSAL_APP_URL,
  REACT_APP_ENC_ZENDESK_KEY:process.env.REACT_APP_ENC_ZENDESK_KEY,
};
