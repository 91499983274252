import { MouseEvent } from 'react';
import { useTheme } from '@mui/material';
import {
  MultilineTypography,
  MultilineTypographyProps,
} from './MultilineTypography';

export type CommentTextProps = {
  comment?: string;
  variant?: MultilineTypographyProps['variant'];
  numberOfLines?: number;
  isURL?: boolean;
  onClick?: (comment: string) => void;
};

export const CommentText = ({
  comment,
  variant = 'body2',
  numberOfLines = 15,
  isURL,
  onClick,
}: CommentTextProps) => {
  const theme = useTheme();
  return (
    <MultilineTypography
      variant={variant}
      sx={{
        display: '-webkit-box',
        WebkitLineClamp: numberOfLines,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        ...(isURL && {
          textDecoration: 'underline',
          color: theme.palette.primary.main,
        }),
        ...(onClick && {
          '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: theme.palette.primary.main,
          },
        }),
      }}
      onClick={
        onClick &&
        ((e: MouseEvent) => {
          if (comment?.length) {
            e.preventDefault();
            e.stopPropagation();
            onClick(comment);
          }
        })
      }
    >
      {comment}
    </MultilineTypography>
  );
};
