import { DocumentHeaderFooter24Filled } from '@fluentui/react-icons';
import { memo, MouseEvent } from 'react';
import { getExceptionLabel } from '../../../util/data-utils';
import { Box } from '../../Box';
import { Indicator } from '../../chip';
import { ImageWithFallback } from '../components/ImageWithFallback';
import { LinkWithArrow } from '../components/LinkWithArrow';
import { useItemExceptions, useItemImage } from '../../../hooks';
import { convertObjectToArray } from '../../../util';
import {
  DestroyItemsStatusCaption,
  DisposalRequest,
  DisposalRequestStatus,
  DisposalRequestStatusCaption,
  ItemGridQuery,
} from '../../../types';
import { useTheme } from '@mui/material';
export const ItemSummaryTemplate = ({
  title,
  subTitle,
  information,
  imageId,
  itemId,
  disposalRequest,
  tags,
  onClick,
  onClickItems,
  onContextMenu,
  count,
  type,
  adminMode,
  itemGridType,
  filter,
}: {
  adminMode?: boolean;
  title: string;
  subTitle?: string;
  information?: string;
  tags?: string[];
  onClick?: () => void;
  onClickItems?: () => void;
  imageId?: string;
  itemId?: string;
  disposalRequest?: DisposalRequest;
  onContextMenu?: (e: MouseEvent) => void;
  count?: number;
  type?: 'item' | 'attachment';
  itemGridType?: ItemGridQuery;
  filter?: string;
}) => {
  const { itemImage } = useItemImage({ id: imageId });
  const theme = useTheme();

  const { data: disposalDetailsExceptions, isSuccess: exceptionFetchSuccess } =
    useItemExceptions({
      itemId: itemId,
      disposalId: disposalRequest?.ID as string,
    });

  const exceptions =
    disposalDetailsExceptions != null &&
    convertObjectToArray(disposalDetailsExceptions).filter(
      (x) => x[1] === true
    );

  const getExceptionChip = ({ request }: { request: DisposalRequest }) => {
    if (itemGridType === ItemGridQuery.DestroyItems) return;
    switch (true) {
      case !!exceptions && exceptions.length > 0 && adminMode:
        return [
          ...new Set(
            exceptions
              .filter(
                (exception) =>
                  request?.Status !== DisposalRequestStatus.Rejected ||
                  (request?.Status === DisposalRequestStatus.Rejected &&
                    exception[0] !== 'RejectedInOtherRequest')
              )
              .map((x) => {
                if (
                  request?.Status === DisposalRequestStatus.Rejected &&
                  x[0] === 'RejectedInOtherRequest'
                )
                  return;
                return getExceptionLabel(x[0]);
              })
          ),
        ].map((label: string, i) => {
          if (label == '') {
            return <></>;
          }
          return <Indicator key={i} label={label} color='warning' />;
        });
      default:
        return <></>;
    }
  };

  const getItemStatusChip = ({
    request,
    filter,
  }: {
    request: DisposalRequest;
    filter?: string;
  }) => {
    switch (true) {
      case !!filter &&
        itemGridType === ItemGridQuery.DestroyItems &&
        adminMode: {
        if (filter === DisposalRequestStatus.ItemDestroyComplete)
          return (
            <Indicator
              label={DestroyItemsStatusCaption.Destroyed}
              sx={{
                backgroundColor: theme.palette.info.main,
                color: theme.palette.common.white,
                paddingTop: '0.063rem',
              }}
            />
          );
        else if (filter === DisposalRequestStatus.Failed)
          return (
            <Indicator
              label={DestroyItemsStatusCaption.DestructionFailed}
              sx={{
                backgroundColor: theme.palette.warning[900],
                color: theme.palette.common.white,
                paddingTop: '0.063rem',
              }}
            />
          );

        return (
          <Indicator
            label={DestroyItemsStatusCaption.DestructionInProgress}
            sx={{ paddingTop: '0.063rem' }}
          />
        );
      }
      case !!exceptions &&
        exceptions.length > 0 &&
        (request?.Status == DisposalRequestStatus.Complete ||
          request?.Status == DisposalRequestStatus.InProgress) &&
        adminMode:
        return <Indicator label='Destruction Deferred' />;
      case request?.Status == DisposalRequestStatus.Approved:
        return (
          <Indicator
            label={DisposalRequestStatusCaption.Approved}
            color='primary'
          />
        );
      case request?.Status == DisposalRequestStatus.InProgress:
        return <Indicator label={DisposalRequestStatusCaption.InProgress} />;
      case request?.Status == DisposalRequestStatus.Complete &&
        !!exceptions &&
        !exceptions.length:
        return (
          <Indicator
            label={DisposalRequestStatusCaption.Complete}
            color='primary'
          />
        );
      case request?.Status == DisposalRequestStatus.Rejected:
        return (
          <Indicator
            label={DisposalRequestStatusCaption.Rejected}
            color='warning'
            isRejected={true}
          />
        );
      case !!tags && request?.Status == DisposalRequestStatus.New:
        return tags.map((tag, i) => <Indicator label={tag} key={i} />);
      default:
        return <></>;
    }
  };

  return (
    <Box direction='column' gap='small' background='none'>
      <Box
        alignItems='center'
        direction='row'
        gap='small'
        background='none'
        onContextMenu={(e) => e.preventDefault()}
        style={{ marginLeft: '1rem' }}
      >
        <ImageWithFallback src={itemImage} sx={{ mr: '0.6rem' }} />
        <LinkWithArrow
          title={title}
          onClick={onClick}
          onContextMenu={onContextMenu}
        />
      </Box>
      <Box
        alignItems='center'
        direction='row'
        background='none'
        onContextMenu={(e) => e.preventDefault()}
        style={{ marginLeft: '1rem', textOverflow: 'ellipsis' }}
      >
        {/* TODO: should this icon be here? it has opacity 0 */}
        <DocumentHeaderFooter24Filled
          opacity={0}
          style={{
            marginRight: '0.6rem',
            minWidth: '2rem',
            alignSelf: 'flex-start',
            marginTop: '2px',
          }}
        />
        <Box
          direction='column'
          background='none'
          gap={'medium'}
          onContextMenu={(e) => e.preventDefault()}
        >
          {count > 0 && (
            <LinkWithArrow
              title={`${count} ${type == 'item' ? 'member' : ''} ${type}${
                count > 1 ? 's' : ''
              }`}
              onClick={onClickItems}
              onContextMenu={onContextMenu}
              variant={'body2'}
            />
          )}
          <Box
            gap='small'
            background='none'
            direction='row'
            style={{
              textOverflow: 'ellipsis',
              flexWrap: 'wrap',
              marginRight: '1',
            }}
          >
            {getItemStatusChip({
              request: disposalRequest,
              filter,
            })}
            {exceptionFetchSuccess &&
              getExceptionChip({ request: disposalRequest })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const MemoizedItemSummaryTemplate = memo(ItemSummaryTemplate);
