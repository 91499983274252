import {
  ArchiveMultiple24Regular,
  CheckmarkSquare20Regular,
  CompassNorthwest24Regular,
  DismissSquare20Regular,
  ErrorCircle20Regular,
  New20Regular,
  PeopleQueue20Regular,
  PeopleSwap20Regular,
} from '@fluentui/react-icons';
import { Button, useTheme } from '@mui/material';
import {
  DisposalRequestResponseStatus,
  DisposalRequestStatus,
  ItemManagementStatus,
} from '../../types';
import { Box } from '../Box';
import { Typography } from '../Typography';

// TODO: this needs to be cleaned up

export type RequestStatusChiprops = {
  title?: string | undefined;
  subTitle?: string | undefined;
  color?: string;
  iconColor?: string;
  variant?:
    | string
    | DisposalRequestStatus
    | DisposalRequestResponseStatus
    | ItemManagementStatus;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  border?: boolean;
};

export const RequestStatusChip = ({
  title,
  subTitle,
  color,
  iconColor,
  icon,
  variant,
  border = true,
  ...rest
}: RequestStatusChiprops) => {
  const theme = useTheme();

  let _icon = icon ?? null;
  const _textColor = color ?? theme.palette.info.main;
  const _iconColor = iconColor ?? theme.palette.info.main;

  if (variant === DisposalRequestResponseStatus.Approved) {
    _icon = <CheckmarkSquare20Regular color={_iconColor} />;
  } else if (variant === DisposalRequestResponseStatus.Rejected) {
    _icon = <DismissSquare20Regular color={_iconColor} />;
  } else if (variant === DisposalRequestResponseStatus.Delegated) {
    _icon = <PeopleSwap20Regular color={_iconColor} />;
  } else if (variant === DisposalRequestResponseStatus.Pending) {
    _icon = <ErrorCircle20Regular color={_iconColor} />;
  } else if (variant === ItemManagementStatus.Managed) {
    _icon = <ArchiveMultiple24Regular color={_iconColor} />;
  } else if (variant === ItemManagementStatus.Discovered) {
    _icon = <CompassNorthwest24Regular color={_iconColor} />;
  } else if (variant === DisposalRequestStatus.InApproval) {
    _icon = <PeopleQueue20Regular color={_iconColor} />;
  } else {
    _icon = !!_icon
      ? _icon
      : (_icon = <New20Regular color={theme.palette.info.main} />);
  }

  return (
    <Button
      disableRipple
      startIcon={_icon}
      variant='outlined'
      sx={{
        background: 'none',
        color: { _textColor },
        padding: '0 0.75rem',
        minHeight: subTitle ? '3.2rem' : '2rem',
        height: 'auto',
        fontSize: '0.875rem !important',
        borderColor: border ? theme.palette.info.light : 'transparent',
        borderRadius: '0.25rem !important',
        cursor: 'default',
        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: border ? theme.palette.info.light : 'transparent',
        },
      }}
      size='small'
      data-testid='info-chip'
      {...rest}
    >
      <div style={{ fontSize: '0.875rem !important', color: _textColor }}>
        <Box background='none'>
          <Typography
            variant='body2'
            sx={{
              width: 'fit-content',
            }}
          >
            {title}
          </Typography>
        </Box>
        {subTitle && (
          <Box background='none'>
            <Typography
              variant='body3'
              sx={{
                fontSize: '0.775rem !important',
                color: theme.palette.info.main,
                width: 'fit-content',
              }}
            >
              {subTitle}
            </Typography>
          </Box>
        )}
      </div>
    </Button>
  );
};
