import {
  Box,
  Button,
  DisposalRequestGrid,
  FullModalDialog,
  ItemPanel,
  ItemsGrid,
  OpenInContextMenu,
  OpenInContextMenuTarget,
  SideMenu,
  SideMenuItem,
  SideMenuItemGroup,
  Typography,
} from '@encompaas/common/components';
import {
  DisposalRequest,
  DisposalRequestAction,
  DisposalRequestActionCaption,
  DisposalRequestActionDoneCaption,
  DisposalRequestCount,
  DisposalRequestStatus,
  DisposalRequestStatusCaption,
  Item,
  ItemGridQuery,
} from '@encompaas/common/types';
import {
  ArrowLeft24Regular,
  CheckmarkSquare24Regular,
  ChevronDownFilled,
  ColumnEdit20Regular,
  Delete24Regular,
  DismissSquare24Regular,
  ErrorCircle24Regular,
  New24Regular,
  PeopleQueue24Regular,
  SpinnerIos20Filled,
} from '@fluentui/react-icons';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Fragment,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import PageWithAppBar from '../components/PageWithAppBar';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { useUser } from '@encompaas/common/hooks';
import {
  requestApi,
  useGetDestroyedItemsCountQuery,
  useGetDestructionInProgressItemsCountQuery,
  useGetFailedToDestroyItemsCountQuery,
  useGetRequestCountsByStatusQuery,
} from '@encompaas/common/services';
import {
  setCurrentItem,
  setSelectedSideMenuItemValue,
  setSideMenuCollapsed,
  setSideMenuGroupCollapsedState,
} from '@encompaas/common/store';
import { useAppDispatch, useAppSelector } from '@encompaas/common/store';
import { TreeGridComponent } from '@syncfusion/ej2-react-treegrid';
import {
  DestructionActioned24,
  DestructionComplete24,
} from '@encompaas/common';

const HomePage = () => {
  const user = useUser();

  const navigate = useNavigate();

  const [key, setKey] = useState(Math.random().toString(36).substring(2, 7));

  const [contextAnchor, setContextAnchor] = useState<Element | null>(null);
  const [contextItem, setContextItem] = useState<DisposalRequest>();
  const [itemID, setItemID] = useState<string | undefined>();
  const [tabIntent, setTabIntent] = useState<'items' | undefined>(undefined);

  const dispatch = useAppDispatch();

  const gridRef: RefObject<TreeGridComponent> = useRef(null);

  const { data: counts, isLoading } = useGetRequestCountsByStatusQuery(
    { userId: user?.ID ?? '' },
    { skip: !user?.ID }
  );

  const {
    data: destructionInProgressItems,
    isLoading: isFetchingDestructionInProgressCount,
  } = useGetDestructionInProgressItemsCountQuery({});
  const { data: destroyedItems, isLoading: isFetchingDestroyedItemsCount } =
    useGetDestroyedItemsCountQuery({});
  const {
    data: failedToDestroyItems,
    isLoading: isFetchingFailedToDestroyItemsCount,
  } = useGetFailedToDestroyItemsCountQuery({});

  const {
    sideMenuCollapsed,
    sideMenuGroupCollapsedState,
    selectedSideMenuItemValue,
  } = useAppSelector((state) => state.application?.ui ?? {});

  const levels = useAppSelector(
    (state: any) => state.itemModal.navigation.levels
  );

  const _menuItems: SideMenuItemGroup[] = [
    {
      title: 'Prepare Requests',
      items: [
        {
          title: DisposalRequestStatusCaption[DisposalRequestStatus.New],
          key: 'DisposalRequests_New',
          icon: <New24Regular />,
          value: DisposalRequestStatus.New,
          caption:
            DisposalRequestStatusCaption[DisposalRequestStatus.New] +
            ' Requests',
        },
      ],
    },
    {
      title: 'Request Disposal',
      items: [
        {
          title: DisposalRequestStatusCaption[DisposalRequestStatus.InApproval],
          key: 'DisposalRequests_InApproval',
          icon: <PeopleQueue24Regular />,
          value: DisposalRequestStatus.InApproval,
          caption:
            DisposalRequestStatusCaption[DisposalRequestStatus.InApproval],
        },
        {
          title: DisposalRequestStatusCaption[DisposalRequestStatus.Approved],
          key: 'DisposalRequests_Approved',
          icon: <CheckmarkSquare24Regular />,
          value: DisposalRequestStatus.Approved,
          caption: DisposalRequestStatusCaption[DisposalRequestStatus.Approved],
        },
        {
          title: DisposalRequestStatusCaption[DisposalRequestStatus.Rejected],
          key: 'DisposalRequests_Rejected',
          icon: <DismissSquare24Regular />,
          value: DisposalRequestStatus.Rejected,
          caption: DisposalRequestStatusCaption[DisposalRequestStatus.Rejected],
        },
        {
          title: DisposalRequestStatusCaption[DisposalRequestStatus.InProgress],
          key: 'DisposalRequests_InProgress',
          icon: <DestructionActioned24 />,
          value: DisposalRequestStatus.InProgress,
          caption:
            DisposalRequestStatusCaption[DisposalRequestStatus.InProgress],
        },
        {
          title: DisposalRequestStatusCaption[DisposalRequestStatus.Complete],
          key: 'DisposalRequests_Complete',
          icon: <DestructionComplete24 />,
          value: DisposalRequestStatus.Complete,
          caption: DisposalRequestStatusCaption[DisposalRequestStatus.Complete],
        },
      ],
    },
    {
      title: `${
        DisposalRequestActionCaption[DisposalRequestAction.Destroy]
      } Items`,
      items: [
        {
          title:
            DisposalRequestStatusCaption[
              DisposalRequestStatus.ItemDestroyInProgress
            ],
          key: 'DisposalItems_InProgress',
          icon: <SpinnerIos20Filled />,
          value: DisposalRequestStatus.ItemDestroyInProgress,
          caption:
            DisposalRequestStatusCaption[
              DisposalRequestStatus.ItemDestroyInProgress
            ],
        },
        {
          // Value is temporary since it's conflicting with Destruction Actioned
          title:
            DisposalRequestActionDoneCaption[DisposalRequestAction.Destroy],
          key: 'DisposalItems_Completed',
          icon: <Delete24Regular />,
          value: DisposalRequestStatus.ItemDestroyComplete,
          caption:
            DisposalRequestStatusCaption[
              DisposalRequestStatus.ItemDestroyComplete
            ],
        },
        {
          title: DisposalRequestStatusCaption[DisposalRequestStatus.Failed],
          key: 'DisposalItems_Failed',
          icon: <ErrorCircle24Regular />,
          value: DisposalRequestStatus.Failed,
          caption: DisposalRequestStatusCaption[DisposalRequestStatus.Failed],
        },
      ],
    },
  ];

  //create a lookup to access the menuitems for counts
  //[0,n] = new items
  //[1,n] = disposal requests
  //[2,n] = items in disposalrequests
  //n is the order in which they appear
  const _menuItemsIndex = {
    [DisposalRequestStatus.New as string]: [0, 0],
    [DisposalRequestStatus.InApproval as string]: [1, 0],
    [DisposalRequestStatus.Approved as string]: [1, 1],
    [DisposalRequestStatus.Rejected as string]: [1, 2],
    [DisposalRequestStatus.InProgress as string]: [1, 3],
    [DisposalRequestStatus.Complete as string]: [1, 4],
    [DisposalRequestStatus.ItemDestroyInProgress as string]: [2, 0],
    [DisposalRequestStatus.ItemDestroyComplete as string]: [2, 1],
    [DisposalRequestStatus.Failed as string]: [2, 2],
  };

  const [selectedSideMenuItem, setSelectedSideMenuItem] = useState<
    SideMenuItem | undefined
  >(_menuItems?.[0]?.items?.[0]);

  const [menuItems, setMenuItems] = useState(_menuItems);

  useEffect(() => {
    //set default state if not already set
    const index =
      _menuItemsIndex[selectedSideMenuItemValue ?? DisposalRequestStatus.New];
    if (index) {
      handleSelect(_menuItems[index[0]]?.items?.[index[1]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSideMenuItemValue]);

  useEffect(() => {
    const isFetching =
      isLoading ||
      isFetchingDestructionInProgressCount ||
      isFetchingDestroyedItemsCount ||
      isFetchingFailedToDestroyItemsCount;

    if (!isFetching) handleResetCount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    counts,
    isLoading,
    destroyedItems?.count,
    failedToDestroyItems?.count,
    destructionInProgressItems?.count,
    isFetchingDestructionInProgressCount,
    isFetchingDestroyedItemsCount,
    isFetchingFailedToDestroyItemsCount,
  ]);

  const handleRefresh = () => {
    setKey(Math.random().toString(36).substring(2, 7));
    dispatch(requestApi.util.invalidateTags(['DisposalRequest']));
    // refresh the destroy items tree grid.
    if (gridRef?.current) gridRef?.current.refresh();
    handleResetCount();
  };
  const handleCollapse = (value: boolean) => {
    dispatch(setSideMenuCollapsed(value));
  };
  const handleGroupCollapse = (index: number, collapsed: boolean) => {
    const _groupState = [
      ...(sideMenuGroupCollapsedState ??
        new Array(_menuItems.length).fill(false)),
    ];
    _groupState[index] = collapsed;
    dispatch(setSideMenuGroupCollapsedState(_groupState));
  };
  const handleSelect = (item?: SideMenuItem) => {
    dispatch(
      setSelectedSideMenuItemValue(item?.value ?? DisposalRequestStatus.New)
    );
    setSelectedSideMenuItem(item);
  };

  const handleOpenRequest = useCallback(
    (item?: DisposalRequest | Item, target?: OpenInContextMenuTarget) => {
      if (!item) return;

      const _target = target ?? 'new page';
      switch (_target) {
        case 'new tab':
        case 'new window':
          window.open(`/request/${item.ID}`, '_blank');
          break;
        case 'modal': //modal yet to be implemented for request page
        case 'new page':
        default:
          navigate(`/request/${item.ID}?history=true`);
          break;
      }
    },
    [navigate]
  );

  const handleOpenURL = (url?: string) => {
    if (url) window.open(url, '_blank');
  };

  // const handleNewRequest = () => {
  //   console.log('New request');
  // };
  const handleSelectRequests = (requests?: DisposalRequest[]) => {
    console.log('Select requests', requests?.length, requests?.[0]?.Name);
  };
  const handleReadRequests = (requests: DisposalRequest[]) => {
    console.log('Read requests', requests?.length, requests?.[0]?.Name);
  };
  const handleResetCount = () => {
    //reset all counts to 0 first - remove to not show zero counts
    Object.keys(_menuItemsIndex).forEach((status) => {
      const index = _menuItemsIndex[status];
      (_menuItems[index[0]]?.items?.[index[1]] ?? {}).count = 0;
    });
    //--------

    counts?.forEach((c: DisposalRequestCount) => {
      const index = _menuItemsIndex[c.Status];
      if (index) {
        (_menuItems[index[0]]?.items?.[index[1]] ?? {}).count = c.Count;
      }
    });

    // set items count for Destroy Items
    _menuItems?.forEach((_menuItem) => {
      if (
        _menuItem.title ===
        `${DisposalRequestActionCaption[DisposalRequestAction.Destroy]} Items`
      ) {
        _menuItem.items?.forEach((item) => {
          switch (item.caption) {
            // Set items count for Destruction In Progress items
            case DisposalRequestStatusCaption[
              DisposalRequestStatus.ItemDestroyInProgress
            ]:
              item.count = destructionInProgressItems?.count;
              return;
            // Set items count for Destroyed items
            case DisposalRequestStatusCaption[
              DisposalRequestStatus.ItemDestroyComplete
            ]:
              item.count = destroyedItems?.count;
              return;
            // Set items count for Failed to Destroy items
            case DisposalRequestStatusCaption[DisposalRequestStatus.Failed]:
              item.count = failedToDestroyItems?.count;
              return;
            default:
              item.count = 0;
              return;
          }
        });
      }
    });

    setMenuItems([..._menuItems]);
  };

  const showColumnChooser = () => {
    if (gridRef?.current) {
      (gridRef.current as TreeGridComponent).openColumnChooser(0, 0);
    }
  };

  const handleOpenInContextMenu = (target: OpenInContextMenuTarget) => {
    setContextAnchor(null);
    handleOpenRequest(contextItem, target);
  };

  const handleCloseInContextMenu = () => {
    setContextAnchor(null);
  };

  const navigateBreadcrumbs = (item: Item) => {
    if (gridRef.current) {
      setItemID(item.ID);
    }
  };

  const handleOpen = useCallback(
    (item: Item, target?: OpenInContextMenuTarget) => {
      dispatch(setCurrentItem({ id: item.ID, name: item.DisplayName }));
      const _target = target ?? 'modal';

      switch (_target) {
        case 'new tab':
        case 'new window':
          window.open(`/item/${item.ID}`, '_blank');
          break;
        case 'chromeless page':
          window.open(`/itempanel/${item.ID}`, '_blank');
          break;
        case 'modal':
          navigateBreadcrumbs(item);
          break;
        case 'new page':
        default:
          navigate(`/item/${item.ID}`);
          break;
      }
    },
    [dispatch, navigate]
  );

  const handleOpenItems = useCallback(
    (item: Item) => {
      setTabIntent('items');
      handleOpen(item);
    },
    [handleOpen]
  );

  const handleOpenDisposalRequest = (request: DisposalRequest) => {
    handleOpenRequest(request);
  };

  const handleDisposalRequestContextMenu = (
    e: React.MouseEvent,
    request: DisposalRequest
  ) => {
    setContextItem(request);
    setContextAnchor(e.currentTarget);
  };

  const MemoizedItemsGrid = useMemo(
    () => (
      <ItemsGrid
        ref={gridRef}
        adminMode
        filter={selectedSideMenuItem?.value as DisposalRequestStatus}
        itemGridType={ItemGridQuery.DestroyItems}
        onOpen={handleOpen}
        onOpenURL={handleOpenURL}
        onOpenItems={handleOpenItems}
        onOpenDisposalRequest={handleOpenDisposalRequest}
        onOpenDisposalRequestContextMenu={handleDisposalRequestContextMenu}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSideMenuItem]
  );

  return (
    <PageWithAppBar data-testid={TEST_CONSTANTS.HOME_PAGE}>
      <Box direction='row' background='none' gap='medium'>
        <SideMenu
          collapsed={sideMenuCollapsed}
          selected={selectedSideMenuItem}
          collapsedSize='xlarge'
          onSelect={handleSelect}
          onRefresh={() => {
            handleRefresh();
          }}
          onCollapse={handleCollapse}
          items={menuItems}
          canCollapseGroups
          groupState={sideMenuGroupCollapsedState}
          onGroupCollapse={handleGroupCollapse}
          blur
        />
        <Box
          background='light'
          padding='large'
          rounded='top'
          blur
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          {user?.ID && (
            <TabContext value={selectedSideMenuItem?.key as string}>
              {_menuItems?.map(
                (itemGroup: SideMenuItemGroup, groupindex: number) => (
                  <Fragment key={groupindex}>
                    {itemGroup.items?.map((item) => (
                      <TabPanel
                        value={item.key as string}
                        key={item.key as string}
                        sx={{
                          padding: 0,
                          display: 'flex',
                          overflow: 'hidden',
                          height:
                            selectedSideMenuItem?.key === item.key
                              ? '100%'
                              : 'auto',
                        }}
                      >
                        {itemGroup?.title !==
                        `${
                          DisposalRequestActionCaption[
                            DisposalRequestAction.Destroy
                          ]
                        } Items` ? (
                          <DisposalRequestGrid
                            user={user}
                            key={key}
                            filter={item.value as DisposalRequestStatus}
                            title={`${selectedSideMenuItem?.caption}`}
                            onOpen={handleOpenRequest}
                            // onNew={handleNewRequest}
                            onSelect={handleSelectRequests}
                            onRead={handleReadRequests}
                            adminMode
                          />
                        ) : (
                          <Box
                            background='none'
                            className='items-tree-grid-with-filter'
                          >
                            <Box
                              direction='row'
                              justifyContent='space-between'
                              alignItems='center'
                              background='none'
                              height={6}
                            >
                              <Typography variant='h1'>
                                {selectedSideMenuItem?.caption}
                              </Typography>
                              <Button
                                variant='text'
                                startIcon={<ColumnEdit20Regular />}
                                endIcon={<ChevronDownFilled />}
                                onClick={showColumnChooser}
                              >
                                Change Columns
                              </Button>
                            </Box>
                            {MemoizedItemsGrid}
                            <FullModalDialog
                              open={!!itemID}
                              icon={<ArrowLeft24Regular />}
                              onAction={(crumb?: {
                                id: string;
                                name: string;
                              }) => {
                                const lastNavigatedTo = crumb ?? levels.at(0);
                                dispatch(
                                  setCurrentItem({
                                    id: lastNavigatedTo.id,
                                    name: lastNavigatedTo.name,
                                  })
                                );
                                setItemID(lastNavigatedTo.id);
                              }}
                              onClose={() => {
                                setItemID(undefined);
                                setTabIntent(undefined);
                                dispatch(setCurrentItem(undefined));
                              }}
                            >
                              {itemID && (
                                <ItemPanel
                                  id={itemID}
                                  adminMode={true}
                                  initialTab={tabIntent}
                                  setId={setItemID}
                                />
                              )}
                            </FullModalDialog>
                            <OpenInContextMenu
                              anchor={contextAnchor}
                              options={['new tab', 'new window']}
                              onClose={handleCloseInContextMenu}
                              onOpen={handleOpenInContextMenu}
                            />
                          </Box>
                        )}
                      </TabPanel>
                    ))}
                  </Fragment>
                )
              )}
            </TabContext>
          )}
        </Box>
      </Box>
    </PageWithAppBar>
  );
};

export default HomePage;
