import { createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';
import { acquireToken } from '../../util';

export interface DownloadFilePayload {
  id: string;
}

export const downloadFileThunk = createAsyncThunk(
  'downloadFile/fetch',
  async ({ id }: DownloadFilePayload): Promise<Blob> => {
    const token = await acquireToken();

    const API_URL = `${config.API_BASE_URL}/Item('${id}')/Download?ds=5`;
    const response = await fetch(API_URL, {
      headers: {
        Authorization: `bearer ${token}`,
      },
      redirect: 'follow',
    });

    if (!response.ok) {
      throw new Error('Failed to download');
    }

    return response.blob();
  }
);
