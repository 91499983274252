import { useAppDispatch } from '../store';
import { downloadFileThunk } from '../store/thunks';

type FileDetails = {
  _Display?: string;
  StorageObject?: {
    FileExtension?: string;
  };
  Body?: {
    MimeType?: string;
  }
};

type DownloadFileProps = {
  id: string;
  itemDetails: FileDetails;
};

export const useDownloadFile = () => {
  const dispatch = useAppDispatch();
  const downloadFile = async ({
    id,
    itemDetails,
  }: DownloadFileProps): Promise<void> => {
    try {
      const blobAction = await dispatchFile(dispatch, downloadFileThunk, id);

      if (isDownloadFulfilled(blobAction)) {

        const blob = blobAction.payload;
        initiateDownload(blob, itemDetails);
      }
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return { downloadFile };
};

const dispatchFile = async (
  dispatch: Function,
  thunk: Function,
  id: string
) => {
  return await dispatch(thunk({ id }));
};

const isDownloadFulfilled = (blobAction: any) => {
  return downloadFileThunk.fulfilled.match(blobAction);
};

const initiateDownload = (blob: Blob, itemDetails: FileDetails) => {
  const filename = generateFilename(itemDetails);
  const blobURL = window.URL.createObjectURL(blob);

  triggerDownload(blobURL, filename);
  window.URL.revokeObjectURL(blobURL);
};

const generateFilename = (itemDetails: FileDetails): string => {
  const fileExtension = itemDetails?.StorageObject?.FileExtension ?? itemDetails?.Body?.MimeType

  return `${
    itemDetails._Display
  }.${fileExtension?.toLowerCase()}`;
};

const triggerDownload = (blobURL: string, filename: string): void => {
  const link = document.createElement('a');
  link.href = blobURL;
  link.download = filename;
  link.click();
};
