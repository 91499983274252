import { memo } from 'react';
import { BusinessTypeChip } from '../../chip/BusinessTypeChip';

export const BusinessTypeTemplate = ({
  title,
  information,
  imageId,
}: {
  title: string;
  information?: string;
  imageId?: string;
}) => {
  return (
    <BusinessTypeChip title={title} subTitle={information} imageId={imageId} />
  );
};

export const MemoizedBusinessTypeTemplate = memo(BusinessTypeTemplate);
