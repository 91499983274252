import {
  ArrowClockwiseDashes20Regular,
  CheckmarkSquare20Regular,
  Clock20Regular,
  DismissSquare20Regular,
  PeopleSwap20Regular,
} from '@fluentui/react-icons';
import {
  DisposalRequestResponseStatus,
  DisposalRequestResponseStatusCaption,
} from '../../types';
import { Chip, ChipProps } from './Chip';

export type DisposalRequestResponseStatusChipProps = {
  status: DisposalRequestResponseStatus;
} & Omit<ChipProps, 'title' | 'label' | 'icon' | 'color'>;

export const DisposalRequestResponseStatusChip = ({
  status,
  border = false,
  ...props
}: DisposalRequestResponseStatusChipProps) => {
  // can make these global if needed
  const _icon = {
    [DisposalRequestResponseStatus.Approved]: <CheckmarkSquare20Regular />,
    [DisposalRequestResponseStatus.Rejected]: <DismissSquare20Regular />,
    [DisposalRequestResponseStatus.Pending]: <Clock20Regular />,
    [DisposalRequestResponseStatus.Delegated]: <PeopleSwap20Regular />,
    [DisposalRequestResponseStatus.Waiting]: <ArrowClockwiseDashes20Regular />,
  };

  const _color: Record<DisposalRequestResponseStatus, ChipProps['color']> = {
    [DisposalRequestResponseStatus.Approved]: 'primary',
    [DisposalRequestResponseStatus.Rejected]: 'warning',
    [DisposalRequestResponseStatus.Pending]: 'default',
    [DisposalRequestResponseStatus.Delegated]: 'default',
    [DisposalRequestResponseStatus.Waiting]: 'default',
  };

  return (
    <Chip
      {...props}
      border={border}
      title={DisposalRequestResponseStatusCaption[status]}
      icon={_icon[status]}
      color={_color[status]}
      sx={{
        '& > .MuiChip-icon': {
          margin: '0 0.25rem 0 0',
          minWidth: '1.25rem',
        },
      }}
    />
  );
};
