import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../auth';
import {
  DisposalDetailsCollection,
  DisposalDetailsCountCollection,
} from '../types';

import { config } from '../config';

const ENDPOINT = 'DisposalDetails';

export const disposalDetailsApi = createApi({
  reducerPath: 'disposalDetailsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders,
  }),
  tagTypes: ['DisposalDetails'],
  endpoints: (builder) => ({
    getDisposalRequestAllExceptions: builder.query<
      DisposalDetailsCountCollection,
      { requestId: string }
    >({
      query: ({ requestId }) => {
        return {
          url: `${ENDPOINT}?$filter=DisposalRequestId eq '${requestId}' and (RetentionConflict eq true)&$select=ID&$count=true`,
          method: 'GET',
        };
      },
      providesTags: ['DisposalDetails'],
    }),
    getDisposalRequestRetentionConflictExceptions: builder.query<
      DisposalDetailsCountCollection,
      { requestId: string }
    >({
      query: ({ requestId }) => {
        return {
          url: `${ENDPOINT}?$filter=DisposalRequestId eq '${requestId}' and (RetentionConflict eq true)&$select=ID&$top=1&$count=true`,
          method: 'GET',
        };
      },
      providesTags: ['DisposalDetails'],
    }),
    getDisposalRequestRejectedInOtherRequestExceptions: builder.query<
      DisposalDetailsCountCollection,
      { requestId: string }
    >({
      query: ({ requestId }) => {
        return {
          url: `${ENDPOINT}?$filter=DisposalRequestId eq '${requestId}' and (RejectedInOtherRequest eq true)&$select=ID&$top=1&$count=true`,
          method: 'GET',
        };
      },
      providesTags: ['DisposalDetails'],
    }),
    getItemExceptions: builder.query<
      DisposalDetailsCollection,
      { itemId: string, disposalId: string }
    >({
      query: ({ itemId, disposalId }) => {
        return {
          url: `${ENDPOINT}?$filter=ItemId eq '${itemId}'&$select=FailureDetail,RetentionConflict,RejectedInOtherRequest,RelatedItemRetentionConflict,RelatedItemRejectedInOtherRequest&$top=1`,
          method: 'GET',
        };
      },
      providesTags: ['DisposalDetails'],
    }),
  }),
});

export const {
  useGetDisposalRequestRetentionConflictExceptionsQuery,
  useGetDisposalRequestAllExceptionsQuery,
  useGetDisposalRequestRejectedInOtherRequestExceptionsQuery,
  useGetItemExceptionsQuery
} = disposalDetailsApi;
