import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { MsalAccountToUser, User } from '../types';
import { loginRequest } from './config';

export const useMsalLogout = (): (() => void) => {
  const { instance } = useMsal();

  const logoutRequest = {
    account: instance.getActiveAccount(),
    postLogoutRedirectUri: '/logout', // Redirects to home page after logout
  };

  function onLogout() {
    instance.logoutRedirect(logoutRequest);
  }

  return onLogout;
};

export function useLogin() {
  const { instance } = useMsal();

  function onLogin() {
    instance.loginRedirect(loginRequest)
  }

  return { onLogin };
}

export const useMsalUser = (): User => {
  const { instance } = useMsal();

  const [user, setUser] = useState<User>({} as User);

  useEffect(() => {
    if (instance)
      setUser(
        MsalAccountToUser(
          instance.getActiveAccount() ?? instance.getAllAccounts()?.[0]
        )
      );
  }, [instance]);

  return user;
};
