import * as React from 'react';
import { SVGProps } from 'react';
const SvgCollapseLeft24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20.39 8.293h-8.585m0 0 3.512-3.512m-3.512 3.512 3.317 3.318M10.4 4H7a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h1.8M20 13.756V17a3 3 0 0 1-3 3H8.8m0-16v16'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default SvgCollapseLeft24;
