import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { downloadFileThunk } from '../thunks';

type DownloadState = {
  blob: Blob | null;
  loading: boolean;
  error: string | null;
};

const initialState: DownloadState = {
  blob: null,
  loading: false,
  error: null,
};

const downloadFileSlice = createSlice({
  name: 'downloadFile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadFileThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadFileThunk.fulfilled, (state, action: PayloadAction<Blob>) => {
        state.loading = false;
        state.blob = action.payload;
      })
      .addCase(downloadFileThunk.rejected, (state, action: ReturnType<typeof downloadFileThunk.rejected>) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default downloadFileSlice.reducer;
