import { ChevronDownFilled } from '@fluentui/react-icons';
import {
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useGetEnumsQuery } from '../../../../services';
import { BusinessTypePropDefsValue } from '../../../../types';
import { Box } from '../../../Box';
import { getEnumValues } from './customTypeUtils';

type EnumFieldProps = {
  value: string;
  onChange: (e: SelectChangeEvent<string>) => void;
  propertyDetails: BusinessTypePropDefsValue;
};

export const EnumField = ({
  value,
  onChange,
  propertyDetails,
}: EnumFieldProps) => {
  const { data: enums } = useGetEnumsQuery({});

  const caption = propertyDetails?.PropTypeDef?.Caption;

  const enumValues = getEnumValues(enums, caption);

  return !!enums ? (
    <Box background='none'>
      <Select
        value={value}
        onChange={onChange}
        variant='outlined'
        input={<OutlinedInput notched={false} />}
        IconComponent={ChevronDownFilled}
        sx={{
          width: '100%',
          height: '100%',
          maxWidth: '10.875rem',
          maxHeight: '3rem',
          paddingLeft: '0.75rem',
        }}
        MenuProps={{ classes: { paper: 'filter-dropdown' } }}
      >
        {enumValues?.map((enumValue, index) => (
          <MenuItem value={enumValue.name} key={index}>
            <Typography variant='body2'>{enumValue.Caption}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  ) : null;
};
