import { Button as MuiButton, Tooltip as MuiTooltip } from '@mui/material';
import React, { ReactNode } from 'react';
import { sizes } from '../../themes/sizes';

type MuiButtonProps = React.ComponentProps<typeof MuiButton>;
type MuiTooltipProps = React.ComponentProps<typeof MuiTooltip>;

export type ButtonProps = MuiButtonProps & {
  shape?: 'default' | 'square' | 'round';
  border?: boolean;
  tooltip?: ReactNode;
  tooltipPlacement?: MuiTooltipProps['placement'];
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
};

//create super component for mui button component to obfuscate library
export const Button: React.FC<ButtonProps> = ({
  shape = 'default',
  variant = 'contained',
  size = 'medium',
  border,
  tooltip,
  tooltipPlacement,
  ...props
}: ButtonProps) => {
  const sx = {
    ...(border && { border: '0.125rem solid currentcolor !important' }),
    ...(shape === 'round' && {
      padding: 0,
      minWidth: 0,
      width: `${sizes.rounddimension[size]} !important`,
      height: `${sizes.rounddimension[size]} !important`,
      borderRadius: '50%',
    }),
    ...(shape === 'square' && {
      padding: 0,
      minWidth: 0,
      width: `${sizes.dimension[size]} !important`,
      '& span': { margin: '0 !important' },
    }),
    ...props.sx,
  };

  const _component = () => (
    <MuiButton variant={variant} size={size} {...props} sx={sx} />
  );

  return tooltip ? (
    <MuiTooltip title={tooltip} placement={tooltipPlacement} arrow>
      {_component()}
    </MuiTooltip>
  ) : (
    _component()
  );
};
