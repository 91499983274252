import { Box } from '../Box';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { Button } from '../button';
import { useIcon } from '../../hooks';
import { BusinessTypePropDefsValue, ItemBusinessClass } from '../../types';
import { Edit20Regular } from '@fluentui/react-icons';
import { useGetPerspectiveClassesQuery } from '../../services';
import { PerspectiveClassField } from './components/item/PerspectiveClassField';
import { getPerspectiveClassValues } from './components/item/customTypeUtils';

export type EditPerspectiveClassDialogProps = {
  open: boolean;
  title?: string;
  height?: string;
  fieldLabel: string;
  isLoading?: boolean;
  onClose?: () => void;
  propertyName?: string;
  propertyValue?: ItemBusinessClass;
  propertyDetails: BusinessTypePropDefsValue;
  onSave?: (value: ItemBusinessClass, propertyName?: string) => void;
};

export const EditPerspectiveClassDialog = ({
  open,
  title,
  onSave,
  onClose,
  isLoading = false,
  fieldLabel,
  propertyName,
  propertyValue,
  propertyDetails,
}: EditPerspectiveClassDialogProps) => {
  const theme = useTheme();

  const [selectedPerspectiveClass, setSelectedPerspectiveClass] =
    useState<ItemBusinessClass>(propertyValue);

  const imageId = selectedPerspectiveClass?._ImageId;

  const { iconDetails } = useIcon({
    imageId,
  });

  const { data } = useGetPerspectiveClassesQuery({});

  const propTypeDefId = propertyDetails?.PropTypeDefId;
  const currentPerspectiveClass = selectedPerspectiveClass?._Display;

  const perspectiveClasses = getPerspectiveClassValues(
    data,
    propTypeDefId,
    currentPerspectiveClass
  );

  const handleSelect = (perspectiveClass: ItemBusinessClass) => {
    setSelectedPerspectiveClass(perspectiveClass);
  };

  const handleDelete = () => {
    setSelectedPerspectiveClass(null);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          maxWidth: '47.5rem',
          maxHeight: '42.438rem',
        },
      }}
    >
      <DialogTitle>
        <Edit20Regular />
        {title}
      </DialogTitle>
      <DialogContent>
        <Box background='none'>
          <Typography
            mb={1}
            variant='body1'
            sx={{ color: theme.palette.info.dark }}
          >
            {fieldLabel}
          </Typography>
          <PerspectiveClassField
            iconDetails={iconDetails}
            handleDelete={handleDelete}
            handleSelect={handleSelect}
            perspectiveClasses={perspectiveClasses}
            selectedPerspectiveClass={selectedPerspectiveClass}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          disabled={propertyValue === selectedPerspectiveClass || isLoading}
          onClick={() =>
            onSave && onSave(selectedPerspectiveClass, propertyName)
          }
        >
          Save
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
