import { ChangeEvent, useEffect, useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { Search20Regular } from '@fluentui/react-icons';
import { palette } from '../themes';
import { debounce } from 'lodash';

export type SearchFieldProps = {
  height?: string;
  width?: string;
  onHandleSearch: (value: string) => void;
};

export const SearchField = ({
  height,
  width,
  onHandleSearch,
}: SearchFieldProps) => {
  const [searchText, setSearchText] = useState('');

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const debouncedOnHandleSearch = debounce((value) => {
    onHandleSearch && onHandleSearch(value);
  }, 500);

  useEffect(() => {
    debouncedOnHandleSearch(searchText);

    return () => debouncedOnHandleSearch.cancel();
  }, [searchText]);

  return (
    <TextField
      placeholder='Search'
      variant='outlined'
      value={searchText}
      onChange={handleSearchTextChange}
      InputProps={{
        className: searchText ? 'Mui-typing' : '',
        endAdornment: (
          <InputAdornment position='start'>
            <Search20Regular style={{ color: palette.primary }} />
          </InputAdornment>
        ),
      }}
      sx={{
        width,
        height,
      }}
    />
  );
};
