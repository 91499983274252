import Zendesk from 'react-zendesk';
import React, { useEffect } from 'react';
import { useUser } from '../hooks/useUser';
import { config } from '../config';

declare global {
  interface Window {
    zE: any;
  }
}

export type ZenDeskSupportProps = {
  zendeskKey?: string;
  setting?: any;
};

export const ZenDeskSupport: React.FC<ZenDeskSupportProps> = ({
  zendeskKey,
  setting,
}) => {
  const user = useUser();
  useEffect(() => {
    setting = {
      chat: {
        connectionOnPageLoad: false,
      },
    };
  }, []);

  const zendeskLoadEvent = () => {
    if (window.zE) {
      window.zE('webWidget', 'hide');
      window.zE('webWidget:on', 'close', function () {
        window.zE('webWidget', 'hide');
      });
      window.zE('webWidget', 'prefill', {
        name: { value: user?.name, readOnly: true },
        email: { value: user?.username, readOnly: true },
      });
    }
  };

  return (
    <div>
      <Zendesk
        defer
        zendeskKey={config.REACT_APP_ENC_ZENDESK_KEY}
        {...setting}
        onLoaded={zendeskLoadEvent}
      />
    </div>
  );
};
