import { styled } from '@mui/material';
import { useIcon } from '../../hooks';
import { useGetPerspectiveClassQuery } from '../../services';
import { ItemBusinessClass } from '../../types';
import { RequestStatusChip } from './RequestStatusChip';

export type PerspectiveClassChipProps = {
  businessClass: ItemBusinessClass;
};

const StyledImage = styled('img')``;

export const PerspectiveClassChip = ({
  businessClass,
}: PerspectiveClassChipProps) => {
  // purpose of this code is to display the latest perspective class name
  // there's an issue on the business object that the data is not getting the latest value for perspective class.
  const { data: perspectiveClass } = useGetPerspectiveClassQuery(
    {
      perspectiveClassId: businessClass?.ID,
    },
    { skip: !businessClass?.ID }
  );

  const imageId = perspectiveClass?._ImageId;
  const typeDefId = perspectiveClass?.TypeDefId;

  const { iconDetails } = useIcon({
    imageId,
    typeDefId,
  });

  return (
    <RequestStatusChip
      data-testid={`perspective-class-chip-${businessClass?.ID}`}
      title={perspectiveClass?.Name}
      icon={
        iconDetails ? (
          <StyledImage
            src={`${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`}
            alt={iconDetails?.AltText}
            style={{ height: '1.5rem', width: '1.5rem' }}
          />
        ) : null
      }
      subTitle={
        perspectiveClass?.FullPath ? perspectiveClass?.FullPath : undefined
      }
      style={{ textAlign: 'left', width: 'fit-content' }}
    />
  );
};
