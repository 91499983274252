import { ResultColumns, ResultImages, ResultPropLinks, ResultTypeDefs } from "./itemPropertyTypes";
import { ApproverResponse, DisposalRequestStatus } from "./requestTypes";
import { SortDirection } from "@syncfusion/ej2-react-grids";

// Item Details
export type Item = {
  $type: string;
  '@enweb.Save': boolean;
  ID: string;
  _Display: string;
  _ImageId: string;
  CompositeId: string;
  TypeDefId: string;
  EigenDisplayViewContentId?: string;
  DCExtractionStatus: any;
  OwnerId: any;
  BusinessTypeId: string;
  Duplicate: boolean;
  Description: any;
  DateFinalised: any;
  DateManaged: any;
  RecordNumber: any;
  HasCapturedFile: boolean;
  ResidesInRepository: boolean;
  Status: ItemManagementStatus;
  Complete: boolean;
  BusinessClasses_DateAssigned: string;
  Classifications_DateAssigned: any;
  ExternalClassifications_DateAssigned: any;
  StorageObjectId: string;
  BusinessObjectId: string;
  OriginObjectId: string;
  AttachmentOfId: any;
  Members: Members;
  Summary: any;
  DisplayName: string;
  SecurityLevelId: any;
  IsStub: boolean;
  ModifiedById: string;
  CreatedById: string;
  DateModified: string;
  DateCreated: string;
  RepositoryUrl: string;
  RepositoryTypeId: any;
  RepositoryId: string;
  ModifiedByInEncId: string;
  CreatedByInEncId: string;
  DateModifiedInEnc: string;
  DateCreatedInEnc: string;
  ETag: any;
  EigenDisplayViewContent: any;
  BusinessClasses: ItemBusinessClasses;
  MemberOf?: ItemMemberOfs;
  DisposalRequests: any;
  ExternalClassifications: any;
  InheritedDisposalStates: any;
  DisposalStates: ItemDisposalStates;
  Classifications?: ItemClassifications | null;
  Contributors: any;
  Owner: any;
  StorageType: any;
  StorageObject?: ItemStorageObject;
  BusinessType: BusinessType;
  BusinessObject: ItemBusinessObject;
  OriginObject: ItemOriginObject;
  TrainingExamples: any;
  Insights: any;
  AttachedItems?: AttachedItems | null;
  AttachmentOf: any;
  Categories: CategoryValues;
  SecurityLevel: any;
  ModifiedBy: ItemModifiedBy;
  CreatedBy: ItemCreatedBy;
  SiblingComponents: ItemSiblingComponents;
  RepositoryType: any;
  Repository: ItemRepository;
  ModifiedByInEnc: ModifiedByInEnc;
  CreatedByInEnc: CreatedByInEnc;
  _Image: Image;
  TypeDef: ItemTypeDef;
  MemberOfRecursive?: ItemMemberOfs;
  ApplicableClassifications: any;
  InheritedClassifications: any;
  Authors: any;
  Content: Content;
  DataPointLocations?: ItemDataPointLocations;
  SupplementalMarkings: any;
  DisposalDetails?: ItemDisposalDetails | null;
  Subject?: string;
  ToRecipients?: Recipient;
  From?: From;
  CCRecipients?: Recipient;
  BCCRecipients?: Recipient;
  Copies?: Copies;
  MediaDateCaptured?: string;
  MediaLength?: number;
  // values passed from ItemsGrid
  index?: number;
  page?: number;
  pageSize?: number;
  sortSettings?: SortValue;
};

export type Recipient = {
  $type: string
  value: RecipientValue[]
}

export type RecipientValue = {
  $type: string
  ID: string
  _Display: string
  CompositeId: any
  TypeDefId: string
  AuthIdentity: any
  IsActive: boolean
  UserAccessType: string
  SecurityLevelId: any
  GivenName: any
  Surname: any
  DateOfBirth: string
  IsExternal: boolean
  Notes: any
  Department: string
  State: any
  JobTitle: any
  BusinessPhone: any
  OfficeAddress: any
  Office: any
  City: any
  Zip: any
  Country: any
  Mail: string
  DisplayName: string
  DateCreatedInEnc: string
  DateModifiedInEnc: string
  CreatedByInEncId: any
  ModifiedByInEncId: any
  RepositoryId: any
  RepositoryTypeId: any
  RepositoryUrl: any
  DateCreated: any
  DateModified: any
  CreatedById: any
  ModifiedById: any
  IsStub: boolean
  EncompaasUrl: any
  _ImageId: any
  ETag: any
}


export type From = {
  $type: string
  ID: string
  _Display: string
  _ImageId: string
  CompositeId: string
  TypeDefId: string
  IsActive: boolean
  DisplayName: string
  UserAccessType: string
  Mail: string
}

export type Copies = {
  $type: string
  value: CopiesValue[]
}

export type CopiesValue = {
  $type: string
  "@enweb.Save": boolean
  ID: string
  _Display: string
  CompositeId: any
  TypeDefId: string
  SentDateTime?: string
  IsDraft?: boolean
  EmailId: string
  ParentFolderId: string
  MailboxId?: string
  CreatedDateTime: string
  LastModifiedDateTime: string
  IsRead: boolean
  ConversationId: string
  ConversationIndex: any
  DateCreatedInEnc: string
  DateModifiedInEnc: string
  CreatedByInEncId: any
  ModifiedByInEncId: any
  RepositoryId: any
  RepositoryTypeId: any
  RepositoryUrl: any
  DateCreated: any
  DateModified: any
  CreatedById: any
  ModifiedById: any
  IsStub: boolean
  EncompaasUrl: any
  _ImageId: any
  ETag: any
  ReceivedDateTime?: string
}



export type CategoryValues = {
  "@odata.count"?: number;
  $type: string;
  "@odata.context"?: string;
  value: Categories[];
  ResultColumns?: ResultColumns;
  ResultPropLinks?: ResultPropLinks;
  ResultTypeDefs?: ResultTypeDefs;
  ResultImages?: ResultImages;
}

export type Categories = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  DisplayName: string;
  Description: string;
  EncompaasUrl: string;
  _ImageId?: string;
  ETag?: string;
}

export type ItemDisposalDetail = {
  AdditionalInformation: string;
  CompletedDate?: string | null;
  DisposalRequestId: string;
  FailureDetail?: string | null;
  ID: string;
  RetentionClassId: string;
  TypeDefId: string;
  DisposalRequest?: DisposalDetailsDisposalRequest;
};

export type ItemDisposalDetails = {
  $type: string;
  value: ItemDisposalDetail[];
};

export type ItemBusinessClasses = {
  $type: string;
  value: ItemBusinessClass[];
};

export type DisposalDetailsDisposalRequest = {
  DateRequested?: string;
  ID?: string;
  Name?: string;
  RequestComments?: string;
  RetentionClassId?: string;
  Status?: DisposalRequestStatus;
  TypeDefId?: string;
  _Display?: string;
  ApproverResponses?: ItemDisposalDetailsApproverResponses;
  ActionedById?: string;
}

export type ItemDisposalDetailsApproverResponses = {
  $type: string;
  value?: ApproverResponse[];
}

export type ItemBusinessClass = {
  $type: string;
  ID: string;
  _Display: string;
  _ImageId: string;
  TypeDefId: string;
  Active: boolean;
  IsBuiltIn: boolean;
  FullPath: string;
  HierarchyLevelId: string;
  Depth: number;
  Description: string;
  Name: string;
  ParentId: any;
  InheritanceTypeId: any;
  ModifiedByInEncId: any;
  CreatedByInEncId: any;
  DateModifiedInEnc: string;
  DateCreatedInEnc: string;
  ETag: any;
};

export type ItemDataPointLocations = {
  value: ItemDataPointLocation[]
}

export type ItemDataPointLocation = {
  Geometry: string;
  ItemId: string;
  PropDefId: string;
  TypeDefId: string;
}

export type ItemMemberOfs = {
  $type: string;
  value: ItemMemberOf[];
};

export type ItemMemberOf = {
  $type: string;
  ID: string;
  _Display: string;
  CompositeId: any;
  TypeDefId: string;
  DateMembershipModifiedRecursive: any;
  DateMembersModifiedRecursive: any;
  EigenDisplayViewContentId: any;
  DCExtractionStatus: any;
  OwnerId: any;
  BusinessTypeId: any;
  Duplicate: boolean;
  Description: any;
  DateFinalised: any;
  DateManaged: any;
  RecordNumber: any;
  HasCapturedFile: boolean;
  ResidesInRepository: boolean;
  Status: string;
  Complete: boolean;
  BusinessClasses_DateAssigned: any;
  Classifications_DateAssigned: any;
  ExternalClassifications_DateAssigned: any;
  StorageObjectId: any;
  BusinessObjectId: any;
  OriginObjectId: any;
  AttachmentOfId: any;
  Summary: any;
  DisplayName: string;
  SecurityLevelId: any;
  IsStub: boolean;
  ModifiedById: any;
  CreatedById: any;
  DateModified: any;
  DateCreated: any;
  RepositoryUrl: any;
  RepositoryTypeId: any;
  RepositoryId: any;
  ModifiedByInEncId: any;
  CreatedByInEncId: any;
  DateModifiedInEnc: string;
  DateCreatedInEnc: string;
  ETag: any;
  _ImageId: any;
};

export type ItemDisposalStates = {
  $type: string;
  value: ItemDisposalState[];
};

export type ItemDisposalState = {
  $type: string;
  '@enweb.Save': boolean;
  ID: string;
  _Display: string;
  TypeDefId: string;
  DisposalAction: string;
  DisposalStatus: string;
  RetentionClassId: string;
  //extra property not returned by API
  RetentionClass?: RetentionClass;
};

export type ItemClassifications = {
  $type: string;
  '@odata.context'?: string;
  value: RetentionClass[];
};

export type RetentionClass = {
  $type: string;
  ID: string;
  _Display: string;
  CompositeId: string;
  TypeDefId: string;
  Description: string;
  Code: string;
  FullPath: string;
  IsActive: boolean;
  DisplayName: string;
  Color: string;
  RootParentColor: string;
  FullCode: string;
  _ImageId?: string;
};

export type ItemStorageObject = {
  $type: string;
  ID: string;
  _Display: string;
  CompositeId: string;
  TypeDefId: string;
  FileExtension: string;
  FileSize: number;
};

export type BusinessType = {
  $type: string;
  ID: string;
  _Display: string;
  _ImageId: string;
  TypeDefId: string;
  IsPolicyBaseType: boolean;
  IsAsynchronous: boolean;
  StorePerConnection: boolean;
  IsStoragePoint: boolean;
  IsBusinessType: boolean;
  IsAppSystemType: boolean;
  UsesDynamicParameters: boolean;
  IsInterface: boolean;
  DefaultHierarchyId: any;
  CanHaveRefLink: boolean;
  RepositoryCategoryId: any;
  AccessRulesId: any;
  ConnectionId: any;
  AppId: string;
  BasedOnTypeDefId: string;
  SerialiseRules: any;
  PolicyTypesForAction: any;
  DateModified: any;
  DerivedPath: any;
  Description: string;
  ShortCaption: string;
  Active: boolean;
  IsItemCollection: boolean;
  IsItem: boolean;
  Name: string;
  CaptionPlural: any;
  DontInsertSpacesInCaption: boolean;
  Caption: string;
  AlwaysCache: boolean;
  IsStoredInstance: boolean;
  IsForInstance: boolean;
  OpenType: boolean;
  Abstract: boolean;
  IsContained: boolean;
  TypeKind: string;
  Image: any;
  CategoryId: string;
  IsRefType: boolean;
  NativeTypeName: string;
  ModifiedByInEncId: string;
  CreatedByInEncId: string;
  DateModifiedInEnc: string;
  DateCreatedInEnc: string;
  ETag: number;
};

export type ItemBusinessObject = {
  $type?: string;
  ID?: string;
  _Display?: string;
  CompositeId?: string;
  TypeDefId?: string;
  [key: string]: any;
};

export type ItemOriginObject = {
  $type: string;
  ID: string;
  _Display: string;
  CompositeId: string;
  AccessRulesId: string;
  TypeDefId: string;
  Title: string;
  Modified: string;
  Size: number;
  FileType: string;
  Path: string;
  IsDocument: boolean;
  EffectivePermissions: any;
  Repository: ItemOriginObjectRepository;
};

export type ItemOriginObjectRepository = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  CanTestRepository: boolean;
  AllowRepositoryQuery: boolean;
  IsServiceProvider: boolean;
  IsAncillaryDestination: boolean;
  IsAncillaryProvider: boolean;
  IsItemDestination: boolean;
  IsItemProvider: boolean;
  RepositoryKey: number;
  ConnectionId: string;
  RepositoryCategoryId: string;
  DisplayName: string;
  ModifiedByInEncId: any;
  CreatedByInEncId: any;
  DateModifiedInEnc: string;
  DateCreatedInEnc: string;
  ETag: any;
  _ImageId: any;
  StartUrl: any;
  RootSharePointAddress: any;
};

export type ItemModifiedBy = {
  $type: string;
  ID: string;
  _Display: string;
  _ImageId: string;
  CompositeId: string;
  TypeDefId: string;
  IsActive: boolean;
  DisplayName: string;
  Mail: string;
};

export type ItemCreatedBy = {
  $type: string;
  ID: string;
  _Display: string;
  _ImageId: string;
  CompositeId: string;
  TypeDefId: string;
  IsActive: boolean;
  DisplayName: string;
  Mail: string;
};

export type ItemSiblingComponents = {
  $type: string;
  value: ItemSiblingComponent[];
};

export type ItemSiblingComponent = {
  $type: string;
  ID: string;
  _Display: string;
  CompositeId: string;
  AccessRulesId?: string;
  TypeDefId: string;
  MediaLengthInSeconds: any;
  EffectivePermissionsId: any;
  SiteId?: string;
  ListId?: string;
  FolderId: any;
  ChannelId: any;
  LastModifiedById?: string;
  CreatedById?: string;
  Src_Id?: string;
  Url?: string;
  Path?: string;
  ContentType?: string;
  ContentTypeId?: string;
  ListItemId?: number;
  WebUrl?: string;
  FileRef?: string;
  FileLeafRef?: string;
  Title?: string;
  Modified?: string;
  Created?: string;
  RootDisplayName: any;
  RootId?: string;
  AclGroupChangeId?: number;
  AclGroupId: any;
  Size?: number;
  IsDocument?: boolean;
  FileType?: string;
  StreamHash?: string;
  Mode?: string;
  IsStub: boolean;
  ConnectorErrorString: any;
  PublishMode?: string;
  LastPublishTime: any;
  NotFoundInRepository?: boolean;
  HasAccessSyncError?: boolean;
  ForceUpdateAccessDependencyModified?: boolean;
  LastAccessDependencyModified: any;
  LastAccessModified: any;
  LastSyncTime?: string;
  RefLinkId?: string;
  RepositoryId?: string;
  ETag: any;
  _ImageId: any;
  EffectivePermissions: any;
  Repository?: ItemSiblingComponentsValueRepository;
  Site?: ItemSiblingComponentsValueSite;
  List?: List;
  Folder: any;
  Channel: any;
  LastModifiedBy?: LastModifiedBy;
  CreatedBy?: ItemSiblingComponentsValueCreatedBy;
  Channel_Temp: any;
  Team_Temp: any;
  AclGroup: any;
  AccessRules?: AccessRules;
  RefLink?: RefLink;
  _Image: any;
  TypeDef: TypeDef;
  BT_Contract_Terms_1?: string;
  BT_Contract_Expiry_Date_1?: string;
  ParentComponentId: any;
  ParentItemId?: string;
  ParentItem?: ParentItem;
  ParentComponent: any;
  WordCount?: number;
  ExtractedTextStatus?: string;
  ContentHash?: string;
  PreviousContentHash?: string;
  TextLength?: number;
  MimeType: any;
  DateExtracted?: string;
  HasExtractedText?: boolean;
  StoreKey?: string;
  FileSize?: number;
  FileExtension?: string;
} & { [key: string]: string };

export type ItemSiblingComponentsValueRepository = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  StartUrl: any;
  RootSharePointAddress: any;
  CanTestRepository: boolean;
  AllowRepositoryQuery: boolean;
  IsServiceProvider: boolean;
  IsAncillaryDestination: boolean;
  IsAncillaryProvider: boolean;
  IsItemDestination: boolean;
  IsItemProvider: boolean;
  RepositoryKey: number;
  ConnectionId: string;
  RepositoryCategoryId: string;
  DisplayName: string;
  ModifiedByInEncId: any;
  CreatedByInEncId: any;
  DateModifiedInEnc: string;
  DateCreatedInEnc: string;
  ETag: any;
  _ImageId: any;
  Connection: ItemConnection;
};

export type ItemConnection = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  DisplayName: string;
  IsActive: boolean;
  HasRegisteredTypeDefs: boolean;
  SystemName: string;
};

export type ItemSiblingComponentsValueSite = {
  $type: string;
  ID: string;
  _Display: string;
  CompositeId: string;
  TypeDefId: string;
  Created: string;
  Description: string;
  Level: number;
  IsPrivateChannelSite: boolean;
  IsPrivateTeam: any;
  IsTeam: boolean;
  WebUrl: string;
  DisplayName: string;
  Src_Id: string;
  IsUnderTeamSite: boolean;
  Src_SiteCollectionId: string;
  RepositoryId: string;
  _ImageId: any;
};

export type List = {
  $type: string;
  ID: string;
  _Display: string;
  CompositeId: string;
  TypeDefId: string;
  SiteId: string;
  Src_Id: string;
  DisplayName: string;
  Description: string;
  LibraryType: string;
  IsLibrary: boolean;
  RepositoryId: string;
  _ImageId: any;
};

export type LastModifiedBy = {
  $type: string;
  ID: string;
  _Display: string;
  CompositeId: string;
  TypeDefId: string;
  State: any;
  Department: string;
  JobTitle: string;
  DisplayName: string;
  Mail: string;
  RepositoryId: string;
  _ImageId: any;
};

export type ItemSiblingComponentsValueCreatedBy = {
  $type: string;
  ID: string;
  _Display: string;
  CompositeId: string;
  TypeDefId: string;
  State: any;
  Department: string;
  JobTitle: string;
  DisplayName: string;
  Mail: string;
  RepositoryId: string;
  _ImageId: any;
};

export type AccessRules = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  _ImageId: any;
};

export type RefLink = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  DisplayName: string;
  _ImageId: any;
};

export type TypeDef = {
  $type: string;
  ID: string;
  _Display: string;
  _ImageId?: string;
  TypeDefId: string;
  IsPolicyBaseType: boolean;
  IsAsynchronous: boolean;
  StorePerConnection: boolean;
  IsStoragePoint: boolean;
  IsBusinessType: boolean;
  IsAppSystemType: boolean;
  UsesDynamicParameters: boolean;
  IsInterface: boolean;
  DefaultHierarchyId: any;
  CanHaveRefLink: boolean;
  RepositoryCategoryId?: string;
  AccessRulesId: any;
  ConnectionId: any;
  AppId: string;
  BasedOnTypeDefId: string;
  SerialiseRules: any;
  PolicyTypesForAction: any;
  DateModified?: string;
  DerivedPath: any;
  Description?: string;
  ShortCaption?: string;
  Active: boolean;
  IsItemCollection: boolean;
  IsItem: boolean;
  Name: string;
  CaptionPlural?: string;
  DontInsertSpacesInCaption: boolean;
  Caption: string;
  AlwaysCache: boolean;
  IsStoredInstance: boolean;
  IsForInstance: boolean;
  OpenType: boolean;
  Abstract: boolean;
  IsContained: boolean;
  TypeKind: string;
  CategoryId?: string;
  IsRefType: boolean;
  NativeTypeName: string;
  ModifiedByInEncId: string;
  CreatedByInEncId?: string;
  DateModifiedInEnc: string;
  DateCreatedInEnc: string;
  ETag: number;
};

export type ParentItem = {
  $type: string;
  '@enweb.Save': boolean;
  ID: string;
  _Display: string;
  _ImageId: string;
  CompositeId: string;
  TypeDefId: string;
  EigenDisplayViewContentId: any;
  DCExtractionStatus: any;
  OwnerId: any;
  BusinessTypeId: string;
  Duplicate: boolean;
  Description: any;
  DateFinalised: any;
  DateManaged: any;
  RecordNumber: any;
  HasCapturedFile: boolean;
  ResidesInRepository: boolean;
  Status: string;
  Complete: boolean;
  BusinessClasses_DateAssigned: string;
  Classifications_DateAssigned: any;
  ExternalClassifications_DateAssigned: any;
  StorageObjectId: string;
  BusinessObjectId: string;
  OriginObjectId: string;
  AttachmentOfId: any;
  Summary: any;
  DisplayName: string;
  SecurityLevelId: any;
  IsStub: boolean;
  ModifiedById: string;
  CreatedById: string;
  DateModified: string;
  DateCreated: string;
  RepositoryUrl: string;
  RepositoryTypeId: any;
  RepositoryId: string;
  ModifiedByInEncId: string;
  CreatedByInEncId: string;
  DateModifiedInEnc: string;
  DateCreatedInEnc: string;
  ETag: any;
};

export type ItemRepository = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  DisplayName: string;
  ConnectionId: string;
  Connection: ItemRepositoryConnection;
  RepositoryCategory: RepositoryCategory;
};

export type ItemRepositoryConnection = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  EnwebAppIdUri: any;
  Tenant: any;
  UseAgentRelay: any;
  IsUsed: boolean;
  IsActive: boolean;
  HasGeneratedPolicies: boolean;
  RegisteredTypeDefsError: any;
  CanDownloadAgent: boolean;
  CanTestConnection: boolean;
  HasRegisteredTypeDefs: boolean;
  DisplayName: string;
  SystemName: string;
  ConnectorId: any;
  ETag: any;
  _ImageId: any;
  DelegatedPermissionsEnabled: boolean;
  ConsentUrl: string;
  ClientSecret: any;
  ClientId: string;
  GraphTenant: any;
};

export type RepositoryCategory = {
  $type: string;
  ID: string;
  _Display: string;
  _ImageId: string;
  TypeDefId: string;
  IsServiceProvider: boolean;
  IsAncillaryDestination: boolean;
  IsAncillaryProvider: boolean;
  IsItemDestination: boolean;
  IsItemProvider: boolean;
  ApplicationId: any;
  Caption: string;
  Description: any;
  Name: string;
  ETag: any;
};

export type CreatedByInEnc = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  DisplayName: string;
  IsActive: boolean;
  PolicyType: number;
  DateCreatedInEnc: string;
  PolicyStatus: string;
  DateModifiedInEnc: string;
};

export type ItemTypeDef = {
  $type: string;
  ID: string;
  _Display: string;
  _ImageId: string;
  TypeDefId: string;
  Caption: string;
  Name: string;
  TypeKind: string;
  DerivedPath: any;
  IsBusinessType: boolean;
};

export type Content = {
  $type: string;
  ID: string;
  _Display: string;
  CompositeId: string;
  TypeDefId: string;
  FileExtension: string;
  FileSize: number;
};
// Item Details

// Item Icon
export type ItemIcon = {
  $type: string;
  '@odata.context': string;
  value: ItemIconValue[];
};

export type ItemIconValue = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  Name: string;
  Url: string;
  Grouping: string;
  AltText: string;
};
// Item Icon

// Business Type Property Values
export type BusinessTypePropertyValues = {
  $type: string;
  '@enweb.Save': boolean;
  ID: string;
  _Display: string;
  CompositeId: string;
  TypeDefId: string;
  ParentComponentId: string;
  IsStub: boolean;
  ParentItemId: string;
  ETag: number;
  _ImageId: string;
  DataPointLocations: any;
  ParentItem: BusinessTypePropertyValuesParentItem;
  ParentComponent: any;
  _Image: any;
  TypeDef: BusinessTypePropertyValuesTypeDef;
} & { [key: string]: any };

export type BusinessTypePropertyValuesParentItem = {
  $type: string;
  ID: string;
  _Display: string;
  _ImageId: string;
  CompositeId: string;
  TypeDefId: string;
  DisplayName: string;
  Status: string;
  Complete: boolean;
  DateCreated: string;
  DateModified: string;
  AttachmentOfId: any;
};

export type BusinessTypePropertyValuesTypeDef = {
  $type: string;
  ID: string;
  _Display: string;
  _ImageId: string;
  TypeDefId: string;
  Caption: string;
  Name: string;
  TypeKind: string;
  DerivedPath: any;
  IsBusinessType: boolean;
};
// Business Type Property Values

// Business Type Properties
export type BusinessTypeProperties = {
  $id: string;
  $type: string;
  TypeDefId: string;
  PropDefs: {
    $type: string;
    value: BusinessTypePropDefsValue[];
  };
};

export interface AttachedItems {
  $type: string;
  value: AttachedItem[];
}

export interface AttachedItem {
  $type: string;
  '@enweb.Save': boolean;
  ID: string;
  _Display: string;
  CompositeId: any;
  TypeDefId: string;
  DisplayName: string;
  _ImageId?: string;
}

export type BusinessTypePropDefsValue = {
  $id: string;
  $type: string;
  '@enweb.Save': boolean;
  ID: string;
  _Display: string;
  TypeDefId: string;
  UsingValueOfId: any;
  CalculationQuery: any;
  Calculation: string;
  IsCalculated: boolean;
  LogicalDataType: string;
  TypeKind: string;
  Nullable: boolean;
  IsCaseSensitive: boolean;
  Unicode: boolean;
  IsNavigation: boolean;
  IsDynamic: boolean;
  ContainsTarget: boolean;
  IsCollection: boolean;
  IsEnId: boolean;
  PrimitiveDataType: string;
  StoreAsJson: boolean;
  IsText: boolean;
  ConnectionId: string;
  AppId: string;
  PropTypeDefId: string;
  SearchBoost: any;
  OperatorsForRepository: any;
  Operators: any;
  IsInherited: boolean;
  IsUsedInPolicyProcessing: boolean;
  IsSensitive: boolean;
  CanOrderBy: boolean;
  CanFilterInRepository: boolean;
  CanFilter: boolean;
  StringLength: string;
  WriteMode: string;
  Ordered: boolean;
  Required: boolean;
  StoreCount: boolean;
  IsAccessDependency: boolean;
  IsFlattenedLikeBaseObject: boolean;
  IsFlattened: boolean;
  SaveChild: boolean;
  ShowInRepositoryForms: boolean;
  CanSetInPolicy: boolean;
  CanUseInSearch: boolean;
  CanUseInAudit: boolean;
  CanShowInGrid: boolean;
  IsParent: boolean;
  IsRefProp: boolean;
  CanShowImage: boolean;
  IncludeInRequestJson: boolean;
  IsSrcKey: boolean;
  IsKey: boolean;
  DefinerProvided: boolean;
  UsedInKeywordSearch: boolean;
  IsActionReturnParameter: boolean;
  RecursiveUsingId: string;
  InversePropLinkId: string;
  ValidCategoryId: string;
  Attributes: any;
  AttachingTypeDefId: string;
  Description: string;
  Caption: string;
  UniqueName: string;
  Name: string;
  ETag: number;
  _ImageId: string;
  PropTypeDef: any;
};
// Business Type Properties

// File Content
export type FileContent = {
  $id: string;
  $type: string;
  TypeDefId: string;
  ContentUrl: ContentURL;
};

export type ContentURL = {
  $id: string;
  $type: string;
  '@enweb.Save': boolean;
  FileExtension: string;
  CreatedBy: string;
  Title: string;
  DocumentId: string;
  ExpiryDate: Date;
  Url: string;
  TypeDefId: string;
  Document: Document;
};

export type Document = {
  $id: string;
  $type: string;
  ID: string;
  _Display: string;
  _ImageId: string;
  CompositeId: string;
  TypeDefId: string;
  EigenDisplayViewContentId: null;
  DCExtractionStatus: null;
  OwnerId: null;
  BusinessTypeId: string;
  Duplicate: boolean;
  Description: null;
  DateFinalised: null;
  DateManaged: null;
  RecordNumber: null;
  HasCapturedFile: boolean;
  ResidesInRepository: boolean;
  Status: string;
  Complete: boolean;
  BusinessClasses_DateAssigned: Date;
  Classifications_DateAssigned: null;
  ExternalClassifications_DateAssigned: null;
  StorageObjectId: string;
  BusinessObjectId: string;
  OriginObjectId: string;
  AttachmentOfId: null;
  Summary: null;
  DisplayName: string;
  SecurityLevelId: null;
  IsStub: boolean;
  ModifiedById: string;
  CreatedById: string;
  DateModified: Date;
  DateCreated: Date;
  RepositoryUrl: string;
  RepositoryTypeId: null;
  RepositoryId: string;
  ModifiedByInEncId: string;
  CreatedByInEncId: string;
  DateModifiedInEnc: Date;
  DateCreatedInEnc: Date;
  ETag: number;
};

export enum ItemManagementStatus {
  None = 'None',
  Managed = 'Managed',
  Discovered = 'Discovered',
  Disposed = 'Disposed',
  Created = 'Created',
  Destroyed = 'Destroyed',
}

//this enum may need more work to have the complete set of values
export enum ItemDisposalStatus {
  Pending = 'Pending',
  Triggered = 'Triggered',
  AssignedToRequest = 'AssignedToRequest',
  DisposalApproved = 'DisposalApproved',
  DisposalRejected = 'DisposalRejected',
  ReadyToTransfer = 'ReadyToTransfer',
  ReadyToDestroy = 'ReadyToDestroy',
  DestructionInProgress = 'DestructionInProgress',
  Destroyed = 'Destroyed',
  Transferred = 'Transferred',
  DisposalFailed = 'DisposalFailed',
  DestructionActioned = 'DestructionActioned',
}

export enum DestroyItemsStatusCaption {
  DestructionInProgress = 'Destruction In Progress',
  DestructionActioned = 'Destruction Actioned',
  DestructionFailed = 'Failed To Destroy',
  Destroyed = 'Destroyed',
}

export const ItemDisposalStatusCaption = {
  [ItemDisposalStatus.Pending]: 'Pending',
  [ItemDisposalStatus.Triggered]: 'Triggered',
  [ItemDisposalStatus.AssignedToRequest]: 'Assigned To Request',
  [ItemDisposalStatus.DisposalApproved]: 'Disposal Approved',
  [ItemDisposalStatus.DisposalRejected]: 'Disposal Rejected',
  [ItemDisposalStatus.ReadyToTransfer]: 'Ready To Transfer',
  [ItemDisposalStatus.ReadyToDestroy]: 'Ready To Destroy',
  [ItemDisposalStatus.DestructionInProgress]: 'Destruction In Progress',
  [ItemDisposalStatus.Destroyed]: 'Destroyed',
  [ItemDisposalStatus.Transferred]: 'Transferred',
  [ItemDisposalStatus.DisposalFailed]: 'Disposal Failed',
  [ItemDisposalStatus.DestructionActioned]: 'Destruction Actioned',
};

export enum ITEM_TYPE {
  AUDIO = 'Audio',
  CONVERSATION = 'PostBasedConversation',
  DOCUMENT = 'Document',
  EMAIL = 'Email',
  EVENT = 'EventType',
  IMAGE = 'ImageType',
  ITEM_COLLECTION = 'ItemCollection',
  ITEM = 'Item',
  TASK = 'TaskType',
  VIDEO = 'Video',
  POST = 'Post',
}

/**
 * Enum representing different queries used for the ItemsGrid components
 * @enum {string}
 */

export enum ItemGridQuery {
  /**
   * Indicates that the ItemsGrid will display items from a collection
   */
  Child = 'Child',

  /**
   * Indicates that the ItemsGrid will display items from a collection and will only include items that have a status
   */
  ChildWithStatus = 'ChildWithStatus',

  /**
   * Indicates that the ItemsGrid will display items from a disposal request
   */
  Root = 'Root',

  /**
   * Indicates that the ItemsGrid will display items from a disposal request and will only include items that have a status
   */
  RootWithStatus = 'RootWithStatus',

  /**
   * Indicates that the ItemsGrid will display items from a destroy items and will include items and item collections that have a disposal states
   */
  DestroyItems = 'DestroyItems',
}
export type BusinessTypes = {
  $type: string;
  '@odata.context': string;
  value: BusinessTypesValue[];
};

export type BusinessTypesValue = {
  $type: string;
  ID: string;
  _Display: string;
  _ImageId: string;
  TypeDefId: string;
  Caption: string;
  Name: string;
  TypeKind: string;
  IsBusinessType: boolean;
  DerivedPath?: any;
  Category: Category;
  App: App;
  Connection?: any;
  _Image: Image;
  BasedOnTypeDef: BasedOnTypeDef;
};

type BasedOnTypeDef = {
  $type: string;
  ID: string;
  _Display: string;
  _ImageId: string;
  TypeDefId: string;
  Caption: string;
  Name: string;
  TypeKind: string;
  DerivedPath?: any;
  IsBusinessType: boolean;
};

export type Image = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  Name: string;
  AltText: string;
  Url: string;
  Grouping: string;
  _ImageId?: any;
};

type App = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  DisplayName: string;
  Created: string;
  Purpose: string;
  Homepage: string;
  MinimumAccessRequired: string;
  _ImageId?: any;
  AadAppId: string;
  AadAppIdUri: string;
};

type Category = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  InternalName: string;
};

export type Enums = {
  $type: string;
  '@odata.context': string;
  value: EnumValue[];
};

type EnumValue = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  DateCreatedInEnc: string;
  DateModifiedInEnc: string;
  CreatedByInEncId?: string;
  ModifiedByInEncId?: string;
  NativeTypeName?: string;
  IsRefType: boolean;
  CategoryId?: any;
  Image?: any;
  TypeKind: string;
  IsContained: boolean;
  Abstract: boolean;
  OpenType: boolean;
  IsForInstance: boolean;
  IsStoredInstance: boolean;
  AlwaysCache: boolean;
  Caption: string;
  DontInsertSpacesInCaption: boolean;
  CaptionPlural: string;
  Name: string;
  IsItem: boolean;
  IsItemCollection: boolean;
  Active: boolean;
  ShortCaption?: any;
  Description?: any;
  DerivedPath?: any;
  DateModified?: string;
  PolicyTypesForAction?: any;
  SerialiseRules?: any;
  BasedOnTypeDefId?: any;
  AppId: string;
  ConnectionId?: any;
  AccessRulesId?: any;
  RepositoryCategoryId?: string;
  CanHaveRefLink: boolean;
  DefaultHierarchyId?: any;
  IsInterface: boolean;
  UsesDynamicParameters: boolean;
  IsAppSystemType: boolean;
  IsBusinessType: boolean;
  IsStoragePoint: boolean;
  StorePerConnection: boolean;
  IsAsynchronous: boolean;
  IsPolicyBaseType: boolean;
  _ImageId?: string;
  ETag: number;
  Members: Members;
};

type Members = {
  "@odata.count"?: number;
  $type: string;
  value: MembersValue[];
};

type MembersValue = {
  $type: string;
  TypeDefOwnerId: string;
  Value: any;
  Name: string;
  Description?: null | string | string;
  Caption: string;
  TypeDefId: string;
  _ImageId?: null | string | string;
  ETag?: any;
  _Display: string;
};

export type PerspectiveClasses = {
  $type: string;
  '@odata.context': string;
  value: ItemBusinessClass[];
};

export type ModifiedByInEnc = {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  DisplayName: string;
  _ImageId?: string;
  Created?: string;
  Purpose?: string;
  Homepage?: string;
  MinimumAccessRequired?: string;
  AadAppId?: string;
  AadAppIdUri?: string;
  CompositeId?: string;
  Mail?: string;
  IsActive?: boolean;
  UserAccessType?: string;
  Department?: string;
};

export interface BusinessCatalogNode {
  $type: string;
  TypeDefId: string;
  catalogNode: CatalogNode;
}

export interface CatalogNode {
  $type: string;
  _Display: string;
  TypeDefId: string;
  IsSelectable: boolean;
  IsPropChainRoot: boolean;
  IsRequired: boolean;
  IsHidden: boolean;
  Children: CatalogNodeChildren;
}

export interface CatalogNodeChildren {
  $type: string;
  value: CatalogNodeChildrenValue[];
}

export interface CatalogNodeChildrenValue {
  $type: string;
  ID: string;
  _Display: string;
  _ImageId: string;
  TypeDefId: string;
  RootId: string;
  TypeDefCategoryId?: string;
  ItemCount: number;
  AggregatedItemCount: number;
  NodeFilter: string;
  AggregateNodeFilter: string;
  ExampleItemsFilter: string;
  AggregateExampleItemsFilter: string;
  SuggestionsFilter: string;
  AggregateSuggestionsFilter: string;
  HasChildren: boolean;
  IsSection: boolean;
  DashboardGroupId: string;
  DefaultDashboardId: string;
  SectionTypeId: string;
  IsSelectable: boolean;
  IsPropChainRoot: boolean;
  IsRequired: boolean;
  IsHidden: boolean;
  PolicyFilter: string;
  ValueId: string;
  DashboardGroup: DashboardGroup;
  DefaultDashboard: DefaultDashboard;
  SectionType: SectionType;
  TrainingConfig: any;
  Value: ChildrenValue;
}

export interface DashboardGroup {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  Title: string;
  Description: string;
  Order: number;
  _ImageId: any;
  ETag: number;
}

export interface DefaultDashboard {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  Title: string;
  Description: string;
  Order: number;
  DashboardGroupId: string;
  CommonQuery: string;
  _ImageId: any;
  ETag: number;
}

export interface SectionType {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  DateCreatedInEnc: string;
  DateModifiedInEnc: string;
  CreatedByInEncId: any;
  ModifiedByInEncId?: string;
  NativeTypeName: string;
  IsRefType: boolean;
  CategoryId: any;
  TypeKind: string;
  IsContained: boolean;
  Abstract: boolean;
  OpenType: boolean;
  IsForInstance: boolean;
  IsStoredInstance: boolean;
  AlwaysCache: boolean;
  Caption: string;
  DontInsertSpacesInCaption: boolean;
  CaptionPlural: string;
  Name: string;
  IsItem: boolean;
  IsItemCollection: boolean;
  Active: boolean;
  ShortCaption: any;
  Description: any;
  DerivedPath: any;
  DateModified: string;
  Members: any;
  PolicyTypesForAction: any;
  SerialiseRules: any;
  BasedOnTypeDefId: string;
  AppId: string;
  ConnectionId: any;
  AccessRulesId: any;
  RepositoryCategoryId: any;
  CanHaveRefLink: boolean;
  DefaultHierarchyId?: string;
  IsInterface: boolean;
  UsesDynamicParameters: boolean;
  IsAppSystemType: boolean;
  IsBusinessType: boolean;
  IsStoragePoint: boolean;
  StorePerConnection: boolean;
  IsAsynchronous: boolean;
  IsPolicyBaseType: boolean;
  _ImageId: any;
  ETag: number;
}

export interface ChildrenValue {
  $type: string;
  ID: string;
  _Display: string;
  _ImageId: string;
  TypeDefId: string;
  Name: string;
  Description?: string;
  DateCreatedInEnc: string;
  IsBuiltIn: boolean;
  ModelName: any;
  Suggestions: boolean;
  TypeDef: ChildrenValueTypeDef;
  SuggestionService: any;
  CreatedByInEnc: ChildrenValueCreatedByInEnc;
}

export interface ChildrenValueTypeDef {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  Name: string;
  Caption: string;
}

export interface ChildrenValueCreatedByInEnc {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  DisplayName: string;
  _ImageId?: string;
  CompositeId?: string;
}

export interface EmailCopy {
  $type: string
  "@odata.context": string
  value: EmailCopyValue[]
}

export interface EmailCopyValue {
  $type: string
  ID: string
  _Display: string
  CompositeId: string
  TypeDefId: string
  EmailId: string
  ParentFolderId: string
  MailboxId: string
  CreatedDateTime: string
  LastModifiedDateTime: string
  IsRead: boolean
  ConversationId: string
  ConversationIndex: string
  DateCreatedInEnc: string
  DateModifiedInEnc: string
  CreatedByInEncId: string
  ModifiedByInEncId: string
  RepositoryId: string
  RepositoryTypeId: any
  RepositoryUrl: string
  DateCreated: string
  DateModified: string
  CreatedById: any
  ModifiedById: any
  IsStub: boolean
  EncompaasUrl: string
  _ImageId: any
  ETag: any
  ReceivedDateTime: string
  ParentFolder: ParentFolder
  Mailbox: Mailbox
  Email: Email
}

export interface ParentFolder {
  $type: string
  ID: string
  _Display: string
  CompositeId: any
  TypeDefId: string
  DisplayName: string
}

export interface Mailbox {
  $type: string
  ID: string
  _Display: string
  CompositeId: string
  TypeDefId: string
  OwnerId: string
  DisplayName: string
  DateCreatedInEnc: string
  DateModifiedInEnc: string
  CreatedByInEncId: string
  ModifiedByInEncId: string
  RepositoryId: string
  RepositoryTypeId: any
  RepositoryUrl: any
  DateCreated: any
  DateModified: any
  CreatedById: any
  ModifiedById: any
  IsStub: boolean
  EncompaasUrl: string
  _ImageId: any
  ETag: any
  Owner: Owner
}

export interface Owner {
  $type: string
  ID: string
  _Display: string
  CompositeId: any
  TypeDefId: string
  DisplayName: string
  Mail: string
  _ImageId: any
  Department?: string
}

export interface Email {
  $type: string
  ID: string
  _Display: string
  _ImageId: string
  CompositeId: string
  TypeDefId: string
  Subject: string
  SenderId: string
  FromId: string
  IsDraft: boolean
  BodyId: string
  Importance: string
  SentDateTime: string
  UniqueBodyId: string
  SecurityLevelId: any
  DisplayName: string
  Summary: any
  AttachmentOfId: any
  OriginObjectId: string
  BusinessObjectId: any
  StorageObjectId: any
  ExternalClassifications_DateAssigned: any
  Classifications_DateAssigned: any
  BusinessClasses_DateAssigned: any
  Complete: boolean
  Status: string
  ResidesInRepository: boolean
  HasCapturedFile: boolean
  RecordNumber: any
  DateManaged: any
  DateFinalised: any
  DateDisposed: any
  Description: any
  Duplicate: boolean
  BusinessTypeId: any
  OwnerId: any
  DCExtractionStatus: any
  EigenDisplayViewContentId: any
  DateCreatedInEnc: string
  DateModifiedInEnc: string
  CreatedByInEncId: string
  ModifiedByInEncId: string
  RepositoryId: string
  RepositoryTypeId: any
  RepositoryUrl: string
  DateCreated: string
  DateModified: string
  CreatedById: string
  ModifiedById: string
  IsStub: boolean
  EncompaasUrl: string
  ETag: any
  From: From
}

type SortValue = {
  field: string;
  direction: SortDirection;
};
