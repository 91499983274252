import {
  Alert24Regular,
  ArrowClockwiseDashes24Regular,
  CheckmarkSquare24Regular,
  Clock24Regular,
  Delete24Regular,
  DismissSquare24Regular,
  ErrorCircle24Regular,
  Layer24Regular,
  PeopleCheckmark24Regular,
  Square24Regular,
} from '@fluentui/react-icons';
import { ReadyToDestroy24 } from '../../icons';
import { DisposalRequestStatus, ItemDisposalStatus, ItemDisposalStatusCaption } from '../../types';
import { Chip, ChipProps } from './Chip';

export type ItemDisposalRequestStatus = 
  | DisposalRequestStatus.Approved
  | DisposalRequestStatus.Rejected;

export type CombinedItemStatus = ItemDisposalStatus | ItemDisposalRequestStatus;

export type ItemDisposalStatusChipProps = {
  status: CombinedItemStatus;
} & Omit<ChipProps, 'title' | 'label' | 'icon' | 'color'>;

//TODO: this will need more work to handle an unknown status value
export const ItemDisposalStatusChip = ({
  status,
  border = false,
  ...props
}: ItemDisposalStatusChipProps) => {
  // can make these global if needed
  const _icon = {
    [ItemDisposalStatus.Pending]: <Clock24Regular />,
    [ItemDisposalStatus.Triggered]: <Alert24Regular />,
    [ItemDisposalStatus.AssignedToRequest]: <Layer24Regular />,
    [ItemDisposalStatus.DisposalApproved]: <CheckmarkSquare24Regular />,
    [ItemDisposalStatus.DisposalRejected]: <DismissSquare24Regular />,
    [ItemDisposalStatus.ReadyToTransfer]: <ReadyToDestroy24 />,
    [ItemDisposalStatus.ReadyToDestroy]: <ReadyToDestroy24 />,
    [ItemDisposalStatus.DestructionInProgress]: (
      <ArrowClockwiseDashes24Regular />
    ),
    [ItemDisposalStatus.Destroyed]: <Delete24Regular />,
    [ItemDisposalStatus.Transferred]: <Delete24Regular />,
    [ItemDisposalStatus.DisposalFailed]: <ErrorCircle24Regular />,
    [ItemDisposalStatus.DestructionActioned]: <ReadyToDestroy24 />,
    [DisposalRequestStatus.Approved]: <CheckmarkSquare24Regular />,
    [DisposalRequestStatus.Rejected]: <DismissSquare24Regular />,
  };

  const _color: Record<CombinedItemStatus, ChipProps['color']> = {
    [ItemDisposalStatus.Pending]: 'default',
    [ItemDisposalStatus.Triggered]: 'default',
    [ItemDisposalStatus.AssignedToRequest]: 'default',
    [ItemDisposalStatus.DisposalApproved]: 'primary',
    [ItemDisposalStatus.DisposalRejected]: 'warning',
    [ItemDisposalStatus.ReadyToTransfer]: 'default',
    [ItemDisposalStatus.ReadyToDestroy]: 'default',
    [ItemDisposalStatus.DestructionInProgress]: 'default',
    [ItemDisposalStatus.Destroyed]: 'default',
    [ItemDisposalStatus.Transferred]: 'default',
    [ItemDisposalStatus.DisposalFailed]: 'error',
    [ItemDisposalStatus.DestructionActioned]: 'default',
    [DisposalRequestStatus.Approved]: 'primary',
    [DisposalRequestStatus.Rejected]: 'warning',
  };

  return (
    <Chip
      {...props}
      border={border}
      title={ItemDisposalStatusCaption[status] ?? status}
      icon={_icon[status] ?? <Square24Regular />}
      color={_color[status] ?? 'default'}
    />
  );
};
