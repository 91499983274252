import { ErrorCircle16Regular, PeopleEdit20Regular } from '@fluentui/react-icons';
import { Box, Typography, useTheme } from '@mui/material';
import { useApprovers } from '../../hooks';
import { Approver, ApproversList, DisposalRequest } from '../../types';
import { Box as BoxComponent } from '../Box';
import { EditApprovers } from '../EditApprovers';
import CustomActionModal from './CustomActionModal';
import { ActionButton } from './components';
import { useEffect } from 'react';

export type ChangeApproversDialogProps = {
  title?: string;
  request?: DisposalRequest;
  approvers?: ApproversList;
  disposalRequest?: DisposalRequest | undefined;
  open: boolean;
  onClose?: () => void;
  onAction?: (approvers: Approver[]) => void;
  currentSelectedApprovers?: Approver[];
};
export const ChangeApproversDialog = ({
  title = 'Change Approvers',
  approvers,
  disposalRequest,
  open,
  onClose,
  onAction,
  currentSelectedApprovers,
}: ChangeApproversDialogProps) => {
  const {
    selectedApprovers,
    setSelectedApprovers,
    isApproversListValid,
    setIsApproversListValid,
  } = useApprovers(currentSelectedApprovers);

  const theme = useTheme();

  useEffect(() => {
    if ((currentSelectedApprovers.length ?? 0) === 0) setSelectedApprovers([]);
    setSelectedApprovers(currentSelectedApprovers);
  }, [currentSelectedApprovers]);

  useEffect(() => {
    disposalRequest?.ApproverResponses?.value.forEach((_approverResponse) => {
      if (
        !currentSelectedApprovers.some(
          (e) => e.ID === _approverResponse.Approver.ID
        )
      )
        currentSelectedApprovers.push({
          _Display: _approverResponse.Approver?.DisplayName!,
          ID: _approverResponse.Approver?.ID!,
          Department: '',
          Status: _approverResponse?.Status!,
          ApproverId: _approverResponse?.ID!,
        });
    });
  }, [approvers]);

  const handleResetApprovers = () => {
    setSelectedApprovers(currentSelectedApprovers);
    onClose();
  };

  return (
    <CustomActionModal
      title={title}
      onClose={handleResetApprovers}
      open={open}
      icon={<PeopleEdit20Regular />}
      size='large'
      actionButton={
        <ActionButton
          onAction={(name, comment, approvers) => onAction(approvers)}
          selectedApprovers={selectedApprovers}
          setIsValidCondition={(approvers) => (approvers?.length ?? 0) > 0}
          setValidity={setIsApproversListValid}
          label='Change Approvers'
        />
      }
    >
      <Box mb={2}>
        <Typography variant='h4'>Approvers</Typography>
        <Typography variant='body2'>
          Specify Approvers in approval order. Note that removing an
        </Typography>
        <Typography variant='body2'>
          Approver that has responded, will delete their response.
        </Typography>
      </Box>

      <EditApprovers
        approvers={approvers}
        setSelectedApprovers={setSelectedApprovers}
        selectedApprovers={selectedApprovers}
        isApproversListValid={isApproversListValid}
        setIsApproversListValid={setIsApproversListValid}
      />
      {!isApproversListValid && (
        <BoxComponent
          background='none'
          color='red'
          direction='row'
          style={{
            marginTop: '0.75rem',
          }}
        >
          <ErrorCircle16Regular
            color={theme.palette.warning.main}
            style={{
              marginRight: '.3rem',
            }}
          />
          <Typography variant='body3' color={theme.palette.warning.main}>
            Please specify approvers
          </Typography>
        </BoxComponent>
      )}
    </CustomActionModal>
  );
};
