import { Edit24Regular, ErrorCircle16Regular } from '@fluentui/react-icons';
import { TextField, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Box } from '../Box';
import { Button } from '../button';
import CustomActionModal from './CustomActionModal';

export type EditTextDialogProps = {
  title?: string;
  label?: string;
  description?: string;
  text?: string;
  open?: boolean;
  minLength?: number;
  multiline?: boolean;
  rows?: number;
  onClose?: () => void;
  onSave?: (text: string, propertyName?: string) => void;
  style?: React.CSSProperties;
  propertyName?: string;
};

export const EditTextDialog = ({
  title,
  label,
  description,
  text,
  open,
  minLength = 0,
  multiline = false,
  rows,
  onClose,
  onSave,
  style,
  propertyName,
}: EditTextDialogProps) => {
  const theme = useTheme();
  const [editText, setEditText] = useState(text);
  const [hasError, setHasError] = useState(false);
  const [className, setClassName] = useState('');

  useEffect(() => {
    if (open) {
      setEditText(text);
      setHasError(false);
      setClassName('');
    }
  }, [open]);

  return (
    <CustomActionModal
      title={title ?? `Edit ${label}`}
      onClose={onClose}
      icon={<Edit24Regular />}
      open={open}
      actionButton={
        <Button
          variant='contained'
          onClick={() => onSave && onSave(editText, propertyName)}
          disabled={editText?.length < minLength || editText === text}
        >
          Save
        </Button>
      }
      size={multiline ? 'medium' : 'small'}
      style={style}
    >
      {label && (
        <Typography
          variant='body1'
          sx={{
            color: theme.palette.info.dark,
          }}
          noWrap
        >
          {label}
        </Typography>
      )}
      {description && (
        <Typography
          variant='body2'
          sx={{
            color: theme.palette.info.main,
          }}
          noWrap
        >
          {description}
        </Typography>
      )}

      <TextField
        sx={{
          marginTop: label || description ? '0.75rem' : '0',
          width: '100%',
          ...(!multiline && { height: '3rem' }),
        }}
        placeholder={label && `Enter ${label.toLowerCase()}`}
        onChange={(event) => {
          setEditText(event.target.value);
          setHasError(event.target.value.length < minLength);
          setClassName(
            event.target.value.length < minLength ? '' : 'Mui-typing'
          );
        }}
        error={hasError}
        value={editText}
        multiline={multiline}
        rows={multiline ? rows ?? 9 : undefined}
        InputProps={{
          className,
        }}
        onBlur={() => setClassName('')}
      />

      {hasError && (
        <Box
          background='none'
          direction='row'
          gap='small'
          style={{
            color: theme.palette.warning.main,
            marginTop: '0.5rem',
          }}
        >
          <ErrorCircle16Regular color='inherit' />
          <Typography variant='body3' color='inherit' noWrap>
            {`Please enter a ${label.toLowerCase()}`}
          </Typography>
        </Box>
      )}
    </CustomActionModal>
  );
};
