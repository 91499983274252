import { useAlert, AlertState, AlertActions } from './useAlert';

export type ErrorAlert = {
  setError: (title: string, message: string) => void;
} & AlertState & AlertActions;

export type SuccessAlert = {
  setSuccess: (title: string, message: string) => void;
} & AlertState & AlertActions;

export type RetryAlert = {
  setRetry: (title: string, message: string) => void;
} & AlertState & AlertActions;

export const useApiAlert = (): { errorAlert: ErrorAlert; successAlert: SuccessAlert, retryAlert: RetryAlert } => {
  const errorAlert = useAlert(false);
  const successAlert = useAlert(false);
  const retryAlert = useAlert(false);

  const setError = (title: string, message: string) => {
    errorAlert.setTitle(title);
    errorAlert.setMessage(message);
    errorAlert.openAlert();
  };

  const setSuccess = (title: string, message: string) => {
    successAlert.setTitle(title);
    successAlert.setMessage(message);
    successAlert.openAlert();
  };

  const setRetry = (title: string, message: string) => {
    retryAlert.setTitle(title);
    retryAlert.setMessage(message);
    retryAlert.openAlert();
  };

  return {
    errorAlert: {
      ...errorAlert,
      setError,
    },
    successAlert: {
      ...successAlert,
      setSuccess,
    },
    retryAlert: {
      ...retryAlert,
      setRetry
    }
  };
};

