import { useEffect, useState } from 'react';
import { Chip, ChipProps } from './Chip';
import { getImage } from '../../util';
import { ImageWithFallback } from '../grid/components/ImageWithFallback';

export const BusinessTypeChip = ({
  imageId,
  ...props
}: ChipProps & { imageId: string }) => {
  //TODO: replace this with RTKQuery API call so that it is cached.
  const [businessImage, setBusinessImage] = useState<string>();

  useEffect(() => {
    (async () => {
      setBusinessImage(await getImage(imageId));
    })();
  }, []);

  return (
    <Chip
      title={props.title}
      icon={<ImageWithFallback src={businessImage} />}
      border={false}
      subTitle={props.subTitle}
    />
  );
};
