import { Typography } from '@mui/material';
import { Box } from './Box';
import {
  Info20Regular,
  Dismiss20Regular,
  Warning20Regular,
} from '@fluentui/react-icons';
import { ReactNode } from 'react';

export interface BannerNoticeProps {
  text: string;
  type: 'info' | 'warning';
  onClose?: () => void;
  className?: string;
  isRelative?: boolean;
  icon?: ReactNode;
}

export const BannerNotice = ({
  text,
  onClose,
  type = 'info',
  className,
  isRelative = false,
  icon,
}: BannerNoticeProps) => {
  const _className =
    `${
      isRelative ? 'ecs-banner-notice-relative' : 'ecs-banner-notice'
    } ecs-banner-notice-${type}` + (className ?? '');

  //can apply custom className
  return (
    <>
      <Box
        className={_className}
        direction='row'
        padding='small'
        justifyContent='space-between'
        alignItems='center'
      >
        {!!icon && icon}
        {type === 'info' && !icon && (
          <Info20Regular className='ecs-banner-notice-icon' />
        )}
        {type !== 'info' && !icon && (
          <Warning20Regular className='ecs-banner-notice-icon' />
        )}
        <Box background='none'>
          <Typography
            sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
            className='ecs-banner-notice-text'
            variant='h2'
          >
            {text}
          </Typography>
        </Box>

        {!!onClose && (
          <Dismiss20Regular
            className='ecs-banner-notice-icon ecs-banner-notice-dismiss'
            onClick={onClose}
          />
        )}
      </Box>
    </>
  );
};
