import { Edit20Regular } from '@fluentui/react-icons';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { Box } from '../Box';
import { Button } from '../button';
import { NumberTextField } from './components/item';

export type EditNumberDialogProps = {
  open: boolean;
  title?: string;
  height?: string;
  fieldLabel: string;
  isLoading?: boolean;
  onClose?: () => void;
  propertyName?: string;
  propertyValue?: string;
  onSave?: (value: string, propertyName?: string) => void;
};

export const EditNumberDialog = ({
  open,
  title,
  onSave,
  onClose,
  isLoading = false,
  fieldLabel,
  propertyName,
  propertyValue,
}: EditNumberDialogProps) => {
  const theme = useTheme();

  const [value, setValue] = useState(propertyValue);

  const onChange = (e) => setValue(e.target.value);

  const handleIncrement = () => {
    const currentValue = (
      (!isNaN(parseInt(value)) ? parseInt(value) : -1) + 1
    ).toString();

    setValue(currentValue);
  };

  const handleDecrement = () => {
    const currentValue = (
      (!isNaN(parseInt(value)) ? parseInt(value) : 1) - 1
    ).toString();

    setValue(currentValue);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          maxWidth: '47.5rem',
          maxHeight: '20.5rem',
        },
      }}
    >
      <DialogTitle>
        <Edit20Regular />
        {title}
      </DialogTitle>
      <DialogContent>
        <Box background='none'>
          <Typography
            mb={1}
            variant='body1'
            sx={{ color: theme.palette.info.dark }}
          >
            {fieldLabel}
          </Typography>
          <NumberTextField
            value={value}
            onChange={onChange}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          disabled={propertyValue === value || isLoading}
          onClick={() => onSave && onSave(value, propertyName)}
        >
          Save
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
