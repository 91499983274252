import { Typography, useTheme } from '@mui/material';
import { memo, MouseEvent } from 'react';
import { Box } from '../../Box';
import { LinkWithArrow } from '../components';
import { useDisposalRequestExceptions } from '../../../hooks';
import { useItemGridFilterCount } from '../../../hooks';
import { DisposalRequestStatus } from '../../../types';

export const SummaryTemplate = ({
  title,
  subTitle,
  adminMode,
  information,
  disposalId,
  isUnread,
  isDestructionActioned,
  disposalRequestStatus,
  onClick,
  onContextMenu,
}: {
  adminMode?: boolean;
  title: string;
  disposalId?: string | number;
  subTitle?: string;
  information?: string;
  isUnread?: boolean;
  isDestructionActioned?: boolean;
  disposalRequestStatus?: DisposalRequestStatus;
  onClick?: () => void;
  onContextMenu?: (e: MouseEvent) => void;
}) => {
  const theme = useTheme();
  const { data } = useDisposalRequestExceptions({ id: disposalId as string });
  const { all: items, deferredItems } = useItemGridFilterCount({
    disposalId: disposalId as string,
  });
  const hasExceptions = data.count > 0;
  const totalCount = (items?.count ?? 0) - (deferredItems?.count ?? 0);
  return (
    <Box
      direction='column'
      gap='xsmall'
      background='none'
      style={{ marginLeft: '-0.75rem' }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <Box
        direction='row'
        background='none'
        alignItems='center'
        // added styling for temporary removal of the Circle Icon below
        // TODO: remove style if we need to remove the comment for read / unread indicator
        style={{ paddingLeft: '1.25rem' }}
      >
        {/* TODO: remove comments if we need the read / unread indicator */}
        {/* Temporary Removal - Marking requests as read and unread using the button */}
        {/* https://encompaas.visualstudio.com/EncompaaS/_workitems/edit/58122 */}
        {/* <CircleSmall20Filled
          color={isUnread ? theme.palette.primary.main : 'transparent'}
        /> */}
        {title && (
          <LinkWithArrow
            title={title}
            onClick={onClick}
            onContextMenu={onContextMenu}
          />
        )}
      </Box>
      <Box background='none' style={{ paddingLeft: '1.25rem' }}>
        <Typography variant='body2' noWrap>
          {subTitle}
        </Typography>
        <Typography
          variant='body2'
          noWrap
          sx={{
            marginTop: subTitle ? '0.4rem' : undefined,
          }}
        >
          {`Destroy ${totalCount} Item${totalCount === 1 ? '' : 's'}`}
        </Typography>
        {isDestructionActioned && (
          <Typography
            variant='body2'
            noWrap
            sx={{
              marginTop: subTitle ? '0.4rem' : undefined,
            }}
          >
            {`Defer ${deferredItems?.count ?? 0} Item${
              deferredItems?.count === 1 ? '' : 's'
            }`}
          </Typography>
        )}
        <Typography
          variant='body2'
          noWrap
          sx={{
            marginTop: '0.4rem',
          }}
        >
          {information}
        </Typography>
        {adminMode && (
          <Typography
            variant='body3'
            noWrap
            sx={{
              marginTop: information ? '0.4rem' : undefined,
              color: hasExceptions ? theme.palette.warning.main : undefined,
            }}
          >
            {!!data.count &&
              !isDestructionActioned &&
              `${data.count} Approval exception${data.count > 1 ? 's' : ''}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export const MemoizedSummaryTemplate = memo(SummaryTemplate);
