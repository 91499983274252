import { useCallback, useRef, useState } from 'react';
import { Dialog, EditDialogProps } from '../components';
import { transformSyncFusionPropertiesToJson } from '../components/item/ItemBusinessTypeUtil';
import { getParentNameFromObject } from '../components/item/ItemPropertiesUtil';
import { dateTimeRegex } from '../components/item/template/PropertyValueTemplate';
import { useSaveItemDetailsMutation } from '../services';
import { useItemPropertiesMenuItem } from './useItemPropertiesMenuItem';
import { useSearch } from './useSearch';

export enum Field {
  PerspectiveClass = 'PerspectiveClass',
  Boolean = 'Boolean',
  BusinessType = 'BusinessType',
  BusinessClasses = 'BusinessClasses',
  Classifications = 'Classifications',
}

export const useItemPropertiesPanel = ({
  item,
  hasEditPermission,
  rowSelected,
  formDetails,
  selectedTab,
  itemTypeName,
  propLinksData,
  businessTypeName,
  businessTypeTreeGridData,
}) => {
  // HOOKS
  const gridRef = useRef(null);

  const [dialogDetails, setDialogProps] = useState<EditDialogProps>({
    open: false,
  });

  const [saveItemDetails, { isLoading: isSaving }] =
    useSaveItemDetailsMutation();

  // CUSTOM HOOKS
  // this is for gettng the dropdown menu items
  const { onChange, menuItem, treeGridData } = useItemPropertiesMenuItem({
    item,
    businessTypeTreeGridData,
    businessTypeName,
    itemTypeName,
    gridRef,
    selectedTab,
    propLinksData,
  });

  // create a custom search function for the tree grid.
  const { handleSearch } = useSearch({
    treeGridData,
    gridRef,
    menuItem,
  });

  const setDialogDetails = ({
    open,
    title,
    dialog,
    fieldLabel,
    propertyName,
    propertyValue,
  }: EditDialogProps) =>
    setDialogProps({
      open,
      title,
      dialog,
      fieldLabel,
      propertyName,
      propertyValue,
    });

  const handleActionComplete = useCallback(
    async (args) => {
      if (args.type === 'save') {
        // Just a workaround to show the template values after inline edit
        gridRef.current.refreshColumns();

        if (
          args?.previousData === args?.data?.value ||
          (dateTimeRegex.test(args?.previousData) &&
            args?.previousData.substring(0, 10) === args?.data?.value)
        )
          return;

        const businessObject = formDetails?.BusinessObject;

        // workaround to fix the inline editing issue.
        const parentName = getParentNameFromObject(args?.data);

        const storedGridData = JSON.parse(
          localStorage.getItem('treeGridResultProperties')
        );

        const cellValue = gridRef.current?.dataSource.find(
          (x) => x.propDef.Name === parentName
        );

        const updatedTreeGridData = storedGridData?.map((x) =>
          x.propDef.Name === parentName ? cellValue ?? null : x
        );

        // Get all the business type-related properties in the tree grid
        const businessTypeGridData = Object.keys(businessObject)
          .map((key) => updatedTreeGridData.find((x) => x.propDef.Name === key))
          .filter((item) => item);

        const treeGridDataJSON = transformSyncFusionPropertiesToJson(
          businessTypeGridData,
          businessObject
        );

        // format formData object
        const formData = {
          BusinessObject: {
            ...treeGridDataJSON,
          },
          RepositoryId: item?.RepositoryId,
        };

        // format the request object
        const requestObj = {
          ID: item?.ID,
          TypeDefId: item?.TypeDefId,
          formDetails: formData,
        };

        try {
          gridRef.current.showSpinner();
          // save the values
          setTimeout(async () => {
            await saveItemDetails(requestObj);
          }, 1000);
        } catch (error) {
          // Handle error
          console.error(
            'Error on updating the item details on the properties page:',
            error
          );
          gridRef.current.hideSpinner();
        }
      }
    },
    [gridRef, formDetails]
  );

  const handleRowSelection = (rowData: string) => {
    if (rowData) {
      // send rowData string to another component
      rowSelected(rowData);
    }
  };

  const handleRowSelecting = useCallback(
    (args) => {
      const parentName = getParentNameFromObject(args.data);

      const businessObject = formDetails?.BusinessObject;

      const _selectedRow = args.data;
      const _isAllowEditing =
        (!!_selectedRow &&
          !_selectedRow?.isCollection &&
          !_selectedRow?.childRecords?.length &&
          hasEditPermission &&
          parentName in businessObject) ??
        false;

      // this is to prevent inline editing when the field is a collection
      gridRef.current.editSettings.allowEditing = _isAllowEditing;
    },
    [formDetails, hasEditPermission]
  );

  const handleRecordDoubleClick = (args) => {
    const { rowData } = args;

    if (
      [
        Field.BusinessClasses,
        Field.Classifications,
        Field.BusinessType,
      ].includes(rowData?.propDef?.Name)
    ) {
      let title;
      let fieldLabel;
      let dialog;

      if (rowData?.propDef?.Name === Field.BusinessType) {
        dialog = Dialog.BUSINESS_TYPE;
        title = 'Edit Business Type';
        fieldLabel = 'Business Type';
      }

      if (rowData?.propDef?.Name === Field.BusinessClasses) {
        dialog = Dialog.PERSPECTIVE;
        title = 'Edit Perspective Classes';
        fieldLabel = 'Perspective Classes';
      }

      if (rowData?.propDef?.Name === Field.Classifications) {
        dialog = Dialog.RETENTION;
        title = 'Edit Retention Classes';
        fieldLabel = 'Retention Classes';
      }

      setDialogDetails({
        title,
        dialog,
        fieldLabel,
        open: true,
        propertyName: rowData?.propDef,
        propertyValue: rowData?.value?.value ?? rowData?.value,
      });
    }
  };

  const handleDialogClose = () => {
    setDialogDetails({
      open: false,
    });
  };

  const handleDialogSave = async (value, propertyDetails) => {
    // this is to prevent from calling the API again.
    if (isSaving) return;

    gridRef.current.showSpinner();

    // Format the selected value(s) from the dialog.
    // Get only the needed IDs (ID and TypeDefId)
    const formattedDialogValues = Array.isArray(value)
      ? value?.length
        ? value?.map(({ ID, TypeDefId }) => ({
            ID,
            TypeDefId,
          }))
        : null
      : value;

    // Format form details
    // propertyDetails returns the property details based on the selected dialog
    // BusinessClasses property = Perspective Classes Dialog
    // Classifications property = Retention Classes Dialog
    const formDetails = {
      [propertyDetails?.Name]: formattedDialogValues,
    };

    const { ID, TypeDefId } = item;

    // request object
    const requestObj = {
      ID,
      TypeDefId,
      formDetails,
    };

    try {
      setTimeout(async () => {
        await saveItemDetails(requestObj);
      }, 1000);
    } catch (error) {
      // Handle error
      console.error('Error on updating the item details:', error);
      gridRef.current.hideSpinner();
    }

    setDialogDetails({ open: false });
  };

  return {
    gridRef,
    menuItem,
    formDetails,
    treeGridData,
    dialogDetails,
    hasEditPermission,
    onChange,
    handleSearch,
    handleDialogSave,
    handleDialogClose,
    handleRowSelection,
    handleRowSelecting,
    handleActionComplete,
    handleRecordDoubleClick,
  };
};
