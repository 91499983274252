import { Edit20Regular } from '@fluentui/react-icons';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs, { isDayjs, Dayjs } from 'dayjs';
import { useState } from 'react';
import { Box } from '../Box';
import { Button } from '../button';
import { DateField, DateTimeField } from './components/item';

export type EditDateTimeDialogProps = {
  open: boolean;
  title?: string;
  height?: string;
  fieldLabel: string;
  isLoading?: boolean;
  onClose?: () => void;
  propertyName?: string;
  propertyValue?: string;
  onSave?: (value: string, propertyName?: string) => void;
};

export const EditDateTimeDialog = ({
  open,
  title,
  onSave,
  onClose,
  isLoading = false,
  fieldLabel,
  propertyName,
  propertyValue,
}: EditDateTimeDialogProps) => {
  const theme = useTheme();

  const [value, setValue] = useState<Dayjs | string>(
    !!propertyValue ? dayjs(propertyValue).format('MM/DD/YYYY HH:mm') : null
  );

  const isValidValue = !!isDayjs(value) ? (value as Dayjs)?.isValid() : true;

  const onChange = (date) => setValue(date);

  const isDisabledSaveButton =
    dayjs(propertyValue).startOf('day').isSame(value) ||
    value === propertyValue ||
    !isValidValue ||
    isLoading;

  const handleSave = () => {
    const formattedDate = isDayjs(value)
      ? value?.format('YYYY-MM-DDTHH:mm:ss')
      : value;

    onSave && onSave(formattedDate, propertyName);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          maxWidth: '47.5rem',
          maxHeight: '20.5rem',
        },
      }}
    >
      <DialogTitle>
        <Edit20Regular />
        {title}
      </DialogTitle>
      <DialogContent>
        <Box
          background='none'
          style={{ maxWidth: '21.75rem' }}
          className='datepicker'
        >
          <Typography
            mb={1}
            variant='body1'
            sx={{ color: theme.palette.info.dark }}
          >
            {fieldLabel}
          </Typography>
          <DateTimeField value={value} onChange={onChange} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          disabled={isDisabledSaveButton}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
