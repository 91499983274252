import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTooltip } from '../hooks';
import { Box, BoxProps } from './Box';
import { Tooltip } from './Tooltip';

export type InfoPanelProps = {
  title?: string;
  inset?: string | number;
} & BoxProps;

export const InfoPanel = ({
  title,
  children,
  inset = 0,
  style,
  ...props
}: InfoPanelProps) => {
  const { dataRef, title: tooltipTitle } = useTooltip(title);

  return (
    <Box
      padding='large'
      background='none'
      style={{
        paddingLeft: inset,
        overflow: 'visible',
        ...style,
      }}
      {...props}
    >
      {title && (
        <Tooltip title={tooltipTitle} arrow>
          <Typography
            variant='h2'
            sx={{
              margin: '2rem 0 2rem 0',
            }}
            ref={dataRef}
          >
            {title}
          </Typography>
        </Tooltip>
      )}
      <Box
        background='none'
        style={{
          overflow: 'visible',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
