import { useGetFailureDetailQuery } from '../../../services';
import { Item } from '../../../types';
import { Box } from '../../Box';
import { Tooltip } from '../../Tooltip';
import { Typography as MUITypography } from '@mui/material';
import { palette } from '../../../themes';
import { memo } from 'react';

export const DestructionFailureTemplate = ({ item }) => {
  const { data } = useGetFailureDetailQuery({});

  const _lookup = data?.value[0]?.Members?.value;

  const failureDetails = _lookup
    ?.map((x) => {
      const failureDetail = item?.DisposalDetails?.value?.find(
        (y) => x?.Name === y?.FailureDetail
      );

      if (!!failureDetail) return { ...x, ...failureDetail };
    })
    .filter((z) => !!z);

  return (
    <>
      {failureDetails?.map((detail, index) => (
        <Box
          background='none'
          key={`${index}-${detail.Caption}`}
          style={{
            minHeight: '3.25rem',
          }}
        >
          <Tooltip
            title={
              <Box background='none'>
                <MUITypography
                  variant='body2'
                  sx={{ color: palette.purewhite }}
                >
                  {detail?.Caption}
                </MUITypography>
                <MUITypography
                  variant='body2'
                  sx={{ color: palette.purewhite }}
                >
                  {item?.Repository?.DisplayName}
                </MUITypography>
              </Box>
            }
            arrow
            placement='top'
          >
            <div>
              <Box background='none'>
                <MUITypography
                  variant='body2'
                  sx={{ color: palette.juice[900] }}
                >
                  {detail?.Caption}
                </MUITypography>
                <MUITypography
                  variant='body2'
                  sx={{ color: palette.bley[700] }}
                >
                  {item?.Repository?.DisplayName}
                </MUITypography>
              </Box>
            </div>
          </Tooltip>
        </Box>
      ))}
    </>
  );
};

export const MemoizedDestructionFailureTemplate = memo(
  DestructionFailureTemplate
);
