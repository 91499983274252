import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { BusinessTypesValue, ItemIconValue } from '../../../../types';
import { Box } from '../../../Box';
import { OptionSelect } from '../../../OptionSelect';

const StyledImage = styled('img')``;

export type BusinessTypeFieldProps = {
  iconDetails: ItemIconValue;
  handleDelete?: () => void;
  businessTypes: BusinessTypesValue[];
  selectedBusinessType: BusinessTypesValue;
  handleSelectBusinessType: (businessType: BusinessTypesValue) => void;
};

export const BusinessTypeField = memo(
  ({
    iconDetails,
    handleDelete,
    businessTypes,
    selectedBusinessType,
    handleSelectBusinessType,
  }: BusinessTypeFieldProps) => {
    return (
      <Box width='29rem' background='none'>
        <OptionSelect
          handleSelect={handleSelectBusinessType}
          selectedOption={
            selectedBusinessType ? (
              <Box background='none' alignItems='center' direction='row'>
                {!!iconDetails ? (
                  <StyledImage
                    alt={iconDetails?.AltText}
                    style={{ height: '1.5rem' }}
                    src={`${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`}
                  />
                ) : null}
                <Typography variant='body2' ml={1}>
                  {selectedBusinessType?.Caption}
                </Typography>
              </Box>
            ) : null
          }
          placeholder='- Select a business type -'
          options={businessTypes}
          handleDelete={handleDelete}
        />
      </Box>
    );
  }
);
