import { SVGProps } from 'react';
import { EncompaasBanner } from '../images';

export interface BannerProps extends SVGProps<SVGSVGElement> {
  colour?: 'default' | 'light' | 'dark' | 'alt' | 'alt-light' | 'alt-dark';
}

//create super component for div with layout
export const Banner = ({
  colour = 'default',
  className,
  ...props
}: BannerProps) => {
  let _className = `ecs-banner ecs-fill-${colour} `;

  //can apply custom className
  _className = _className + (className ?? '');

  return (
    <EncompaasBanner
      data-testid={`encompaas-banner-${colour}`}
      className={_className}
      {...props}
    />
  );
};
