import { Button } from './button';
import { Box } from './Box';
import {
  PeopleTeam20Regular,
  DismissSquare20Regular,
  CheckmarkSquare20Regular,
  PeopleCheckmark20Regular,
  Delete20Regular,
  ArrowClockwise20Regular,
} from '@fluentui/react-icons';
import { CloseRequest20 } from '../icons';
import { DisposalRequestResponseStatus, DisposalRequestStatus } from '../types';

export type DisposalRequestHeaderActionButtonsProps = {
  status?: DisposalRequestStatus | DisposalRequestResponseStatus;
  multipleRetentionClasses?: boolean;
  onApprove?: () => void;
  onReject?: () => void;
  onDelegate?: () => void;
  onChangeApprovers?: () => void;
  onCloseRequest?: () => void;
  onRequestApproval?: () => void;
  onDestroyItems?: () => void;
  onReapprove?: () => void;
  onRetry?: () => void;
};

export const DisposalRequestHeaderActionButtons = ({
  status,
  multipleRetentionClasses = false,
  onApprove,
  onReject,
  onDelegate,
  onChangeApprovers,
  onCloseRequest,
  onRequestApproval,
  onDestroyItems,
  onReapprove,
  onRetry,
}: DisposalRequestHeaderActionButtonsProps) => {
  return (
    <Box
      background='none'
      justifyContent='end'
      direction='row'
      alignItems='center'
      gap={0.625}
      shrink
    >
      {status === DisposalRequestResponseStatus.Pending && (
        <>
          <Button
            startIcon={<PeopleTeam20Regular />}
            variant='text'
            onClick={onDelegate}
            size='large'
          >
            Delegate
          </Button>
          <Button
            startIcon={<DismissSquare20Regular />}
            variant='outlined'
            onClick={onReject}
            size='large'
          >
            Reject
          </Button>
          <Button
            startIcon={<CheckmarkSquare20Regular />}
            onClick={onApprove}
            size='large'
          >
            Approve
          </Button>
        </>
      )}
      {[
        DisposalRequestStatus.New,
        DisposalRequestStatus.InApproval,
        DisposalRequestStatus.Approved,
        DisposalRequestStatus.Rejected,
      ].includes(status as DisposalRequestStatus) && (
        <Button
          startIcon={<CloseRequest20 />}
          variant='outlined'
          onClick={onCloseRequest}
          size='large'
        >
          Close Request
        </Button>
      )}
      {status === DisposalRequestStatus.New && (
        <Button
          startIcon={<PeopleCheckmark20Regular />}
          onClick={onRequestApproval}
          size='large'
        >
          Request Approval
        </Button>
      )}
      {status === DisposalRequestStatus.InApproval && (
        <Button
          startIcon={<PeopleCheckmark20Regular />}
          onClick={onChangeApprovers}
          size='large'
        >
          Change Approvers
        </Button>
      )}
      {status === DisposalRequestStatus.Approved && (
        <Button
          startIcon={<Delete20Regular />}
          onClick={onDestroyItems}
          disabled={multipleRetentionClasses}
          size='large'
        >
          Destroy Items
        </Button>
      )}
      {status === DisposalRequestStatus.Rejected && (
        <Button
          startIcon={<PeopleCheckmark20Regular />}
          onClick={onReapprove}
          size='large'
        >
          Reapprove
        </Button>
      )}
      {status === DisposalRequestStatus.InProgress && (
        <Button startIcon={<ArrowClockwise20Regular />} onClick={onRetry}>
          Retry
        </Button>
      )}
    </Box>
  );
};
