import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTheme } from '@mui/material';
import FilterMenuItem from './components/FilterMenuItem';
import { ChevronDown20Filled } from '@fluentui/react-icons';
import useStyles from './styles';

export type SelectValue = {
  value: string;
  display: string;
  count?: number;
};

export interface FilterProps {
  selectedState: string;
  setSelectedState: React.Dispatch<React.SetStateAction<string>>;
  defaultValue?: SelectValue;
  data: SelectValue[];
  labelId: string;
  id: string;
}

export const Filter = ({
  selectedState,
  setSelectedState,
  defaultValue,
  data,
  labelId,
  id,
}: FilterProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedState(event.target.value);
  };
  const theme = useTheme();

  const { styles } = useStyles();

  return (
    <FormControl sx={{ width: 300 }}>
      <InputLabel
        shrink={false}
        sx={styles.select}
        disableAnimation
        id={labelId}
      />
      <Select
        labelId={labelId}
        id={id}
        value={selectedState}
        onChange={handleChange}
        defaultValue={defaultValue.value}
        IconComponent={() => (
          <ChevronDown20Filled
            color={theme.palette.primary.main}
            style={{
              paddingRight: '1rem',
              cursor: 'pointer',
              position: 'absolute',
              right: '0',
              pointerEvents: 'none',
            }}
          />
        )}
        fullWidth
        sx={{
          ...styles.select,
        }}
        MenuProps={{
          sx: {
            marginTop: '0.5rem',
          },
          MenuListProps: {
            sx: { boxShadow: 1 },
          },
        }}
      >
        <MenuItem sx={styles.menuItem} value={defaultValue.value}>
          <FilterMenuItem value={defaultValue} />
        </MenuItem>
        {data.map((x, i) => (
          <MenuItem key={i} sx={styles.menuItem} value={x.value}>
            <FilterMenuItem value={x} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
