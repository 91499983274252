import { useGetRequestDetailsQuery } from '../services';

interface UseDisposalRequestParams {
  id?: string;
  userApiError?: boolean;
}

export const useDisposalRequest = ({ id, userApiError = false }: UseDisposalRequestParams) => {
  const { data, isSuccess, isError, error, refetch } = useGetRequestDetailsQuery(
    {
      requestId: id!,
    },
    { skip: !id }
  );

  if (isError && error) {
    console.error(error);
  }

  const transformedData = userApiError ? undefined : data;

  return { data: transformedData, isSuccess, isError, refetch };
};
