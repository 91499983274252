import { Chip as MuiChip, Typography } from '@mui/material';
import { useTooltip } from '../../hooks';
import { Box } from '../Box';
import { Tooltip } from '../Tooltip';

type MuiChipProps = React.ComponentProps<typeof MuiChip>;

export type ChipProps = {
  title?: string;
  subTitle?: string;
  border?: boolean;
  isLink?: boolean;
  internalChip?: React.ReactNode;
} & MuiChipProps;

// general purpose multiline chip component
export const Chip = ({
  title,
  subTitle,
  border = true,
  color = 'default',
  variant = 'outlined',
  isLink,
  internalChip,
  ...props
}: ChipProps) => {
  const { dataRef: titleRef, title: tooltipTitle } = useTooltip(title);

  const { dataRef: subTitleRef, title: tooltipTitleForSubtitle } =
    useTooltip(subTitle);

  const height = internalChip ? '5.25rem' : '3.25rem';
  const padding = internalChip ? '0.5rem 0.75rem' : '0 0.75rem';
  const align = internalChip ? 'start' : 'center';

  return (
    <MuiChip
      {...props}
      clickable={!!props.onClick}
      onDelete={undefined}
      label={
        <Box background='none' gap={title && subTitle ? 'xsmall' : 'none'}>
          {title && (
            <Tooltip title={tooltipTitle} arrow>
              <Typography variant='body2' noWrap ref={titleRef}>
                {title}
              </Typography>
            </Tooltip>
          )}
          {subTitle && (
            <Tooltip title={tooltipTitleForSubtitle} arrow>
              <Typography variant='body3' noWrap ref={subTitleRef}>
                {subTitle}
              </Typography>
            </Tooltip>
          )}
          {!!internalChip && (
            <div>
              {internalChip}
            </div>
          )}
        </Box>
      }
      className={`${isLink ? 'chip-link' : ''}`}
      color={color}
      variant={variant}
      style={{ alignItems: align }}
      sx={{
        ...(title &&
          subTitle && {
          minHeight: { height },
          padding: { padding },
          ...(!border && {
            '& > .MuiChip-icon': {
              marginTop: '-0.75rem',
            },
          }),
        }),
        ...(!border && {
          borderColor: 'transparent',
          padding: '0',
          paddingRight: '0.25rem',
        }),
        maxWidth: '36rem',
        ...props.sx,
      }}
    />
  );
};
