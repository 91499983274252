import { Box } from '@mui/system';
import { Typography, useTheme } from '@mui/material';
export type SelectValue = {
  value: string;
  display: string;
  count?: number;
};

const FilterMenuItem = ({ value }: { value: SelectValue }) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography
        display='inline'
        variant='body3'
        color={theme.palette.info.dark}
        sx={{ lineHeight: 'unset' }}
      >
        {value.value + ' '}
      </Typography>
      <Typography
        display='inline'
        variant='body3'
        color={theme.palette.info.main}
        sx={{ lineHeight: 'unset' }}
      >
        ({value.count ?? 0})
      </Typography>
    </Box>
  );
};

export default FilterMenuItem;
