import * as React from 'react';
import { SVGProps } from 'react';
const SvgDisposalRequest64 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={64}
    height={64}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width={64} height={64} rx={8} fill='currentColor' />
    <path
      d='m9.745 22.135 21.774 8.881a1.286 1.286 0 0 0 .964 0l21.773-8.881c.46-.188.754-.622.744-1.099a1.172 1.172 0 0 0-.79-1.067l-21.774-7.892a1.276 1.276 0 0 0-.872 0L9.791 19.968c-.467.17-.78.592-.79 1.068-.01.476.284.912.744 1.099ZM32 14.419l18.5 6.705L32 28.671l-18.5-7.547L32 14.42ZM39.488 39.602 32 42.656l-21.291-8.684c-.623-.255-1.343.021-1.61.614-.266.593.023 1.28.646 1.533L32 45.198l7.018-2.863c.052-.945.213-1.86.47-2.733Z'
      fill='#fff'
    />
    <path
      d='M39.49 46.403 32 49.46l-21.291-8.686c-.623-.254-1.343.023-1.61.615-.266.593.023 1.28.646 1.534L32 52l8.376-3.417c-.364-.69-.662-1.419-.886-2.18ZM9.745 29.318 32 38.396l22.254-9.078c.624-.254.912-.941.645-1.534-.266-.592-.984-.87-1.609-.615L32 35.854 10.709 27.17c-.623-.254-1.343.022-1.61.615-.266.594.023 1.28.646 1.534Z'
      fill='#fff'
    />
    <circle cx={51} cy={43} r={9} fill='#fff' />
    <path
      d='M50 40h2a1 1 0 1 0-2 0Zm-1 0a2 2 0 1 1 4 0h2.5a.5.5 0 0 1 0 1h-.441l-.443 5.17A2 2 0 0 1 52.623 48h-3.246a2 2 0 0 1-1.993-1.83L46.941 41H46.5a.5.5 0 0 1 0-1H49Zm3.5 3a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0v-2Zm-2.5-.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2a.5.5 0 0 1 .5-.5Zm-1.62 3.585a1 1 0 0 0 .997.915h3.246a1 1 0 0 0 .996-.915L54.055 41h-6.11l.436 5.085Z'
      fill='currentColor'
    />
  </svg>
);
export default SvgDisposalRequest64;
