import { ChevronDownFilled } from '@fluentui/react-icons';
import { Grow, Menu, MenuItem } from '@mui/material';
import { memo, useState } from 'react';
import { Box } from '../../Box';
import { Button } from '../../button';

interface Props {
  options?: number[];
  pageSize?: number;
  onChange?: (option: number) => void;
}

export const PageSizeDropdown = ({
  options,
  onChange,
  pageSize = 30,
}: Props) => {
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);

  return (
    <Box
      background='none'
      direction='row'
      style={{
        flex: '0 1 min-content',
      }}
      data-testid='page-size-dropdown'
    >
      <Button
        variant='text'
        endIcon={<ChevronDownFilled />}
        color='primary'
        onClick={(e) => setMenuAnchor(e.currentTarget)}
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        Show {pageSize} results per page
      </Button>
      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => {
          setMenuAnchor(null);
        }}
        TransitionComponent={Grow}
        MenuListProps={{ className: 'MuiMenu-list-flat' }}
      >
        {options?.map((option, index) => [
          <MenuItem
            key={index}
            onClick={() => {
              setMenuAnchor(null);
              onChange && onChange(option);
            }}
          >
            {option}
          </MenuItem>,
        ])}
      </Menu>
    </Box>
  );
};

export const MemoizedPageSizeDropdown = memo(PageSizeDropdown);
