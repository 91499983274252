import { RetentionClass24 } from '../../icons';
import { RetentionClass } from '../../types';
import { Chip, ChipProps } from './Chip';

export type RetentionClassChipProps = {
  classification: RetentionClass;
} & ChipProps;

export const RetentionClassChip = ({
  classification,
  border = true,
  ...props
}: RetentionClassChipProps) => {
  return (
    <Chip
      {...props}
      border={border}
      title={
        classification?.DisplayName ||
        (classification &&
          typeof classification === 'object' &&
          classification.hasOwnProperty('Name') &&
          (classification as any).Name) ||
        ''
      }
      icon={
        <RetentionClass24
          color={classification?.RootParentColor ?? undefined}
        />
      }
      subTitle={classification?.FullPath}
      sx={{ ...(border && { paddingLeft: '0.75rem' }), ...props.sx }}
    />
  );
};
