import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { memo, useState } from 'react';
import { ListItem, ListItemButton } from '@mui/material';

import { ArrowMove20Regular } from '@fluentui/react-icons';
import { Box } from './Box';
import { DeleteButton } from './button';
import { useIcon } from '../hooks';
import styled from '@emotion/styled';
import { RetentionClass24 } from '../icons';
import { Typography } from './Typography';

export const StyledImage = styled('img')``;

export enum Type {
  RETENTION = 'retention',
  PERSPECTIVE = 'perspective',
  DEFAULT = 'default',
}

export const SortableOptionList = memo(
  ({
    type,
    index,
    option,
    onDelete,
    displayIndex = true,
    hasDraggableItems = true,
  }: {
    option: any;
    index: number;
    type: Type;
    displayIndex?: boolean;
    hasDraggableItems?: boolean;
    onDelete: () => void;
  }) => {
    const {
      ID: id,
      _Display: displayName,
      FullPath,
      _ImageId: imageId,
    } = option;

    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });

    const [isHovered, setIsHovered] = useState(false);

    const { iconDetails } = useIcon({
      imageId,
    });

    const handleMouseEnter = () => {
      !!hasDraggableItems && setIsHovered(true);
    };

    const handleMouseLeave = () => {
      !!hasDraggableItems && setIsHovered(false);
    };

    const handleOnDelete = () => {
      onDelete && onDelete();
    };

    const getOptionIcon = () => {
      // check if the icon is enabled and has icon details
      if (iconDetails) {
        return (
          <StyledImage
            alt={iconDetails.AltText}
            style={{ height: '1.25rem' }}
            src={`${process.env.REACT_APP_ENC_IMC_URL}${iconDetails.Url}`}
          />
        );
      }

      // no icon will be displayed
      return null;
    };

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <ListItem
        disablePadding
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ListItemButton
          sx={{
            padding: '0.75rem 0.25rem 0.75rem 1rem',
            height: '100%',
            maxHeight: type === Type.RETENTION ? '4.375rem' : '2.75rem',
          }}
        >
          <Box direction='row' background='none' alignItems='center'>
            {displayIndex ? (
              <Typography variant='body2' mr={1}>
                {(index + 1).toString().padStart(2, '0')}
              </Typography>
            ) : null}
            {type === Type.DEFAULT || type === Type.PERSPECTIVE ? (
              <Box
                direction='row'
                background='none'
                gap={0.25}
                alignItems='center'
              >
                {getOptionIcon()}
                <Typography variant='body2'>{displayName}</Typography>
              </Box>
            ) : null}
            {/* Render For Retention Class */}
            {type === Type.RETENTION ? (
              <Box direction='row' background='none' gap={0.5}>
                <RetentionClass24
                  color={option?.RootParentColor ?? undefined}
                  style={{ flexShrink: 0 }}
                />
                <Box direction='column' background='none'>
                  <Typography variant='body2'>{displayName}</Typography>
                  <Typography variant='body3'>{FullPath}</Typography>
                </Box>
              </Box>
            ) : null}
          </Box>
          <Box
            direction='row'
            background='none'
            justifyContent='end'
            style={{ flexShrink: 0 }}
          >
            {!!hasDraggableItems && (
              <ArrowMove20Regular
                style={{
                  visibility: isHovered ? 'visible' : 'hidden',
                  alignSelf: 'center',
                }}
              />
            )}
            <DeleteButton onClick={handleOnDelete} size='medium' />
          </Box>
        </ListItemButton>
      </ListItem>
    );
  }
);
