import { DataStateChangeEventArgs } from '@syncfusion/ej2-react-treegrid';
import { useEffect, useState, useRef } from 'react';
import { DisposalRequestStatus, Item, ItemGridQuery } from '../../types';

import { DataResult } from '@syncfusion/ej2-react-grids';
import { config } from '../../config';
import { acquireToken } from '../../util/data-utils';
import { useGetFailureDetailQuery } from '../../services';
import { ItemLevel, SortValue } from '../../store';

enum StateActionRequestTypes {
  Sorting = 'sorting',
  Filtering = 'filtering'
}

enum StateActions {
  Filter = 'filter',
  ClearFilter = 'clearFilter'
}

const useItemsGrid = ({
  onSelect,
  pageSize,
  disposalId,
  data,
  adminMode,
  filter,
  selectedRecords,
  gridRef,
  stateValue,
  itemGridType,
  selectedRecordsForRemoval = [],
  showCheckbox = false,
  isItemPanel = false,
  initialSortValue,
  levels
}: {
  onSelect?: (request: any) => void;
  pageSize: number;
  disposalId?: string;
  data?: Item[];
  adminMode?: boolean;
  filter?: string;
  selectedRecords?: any[];
  gridRef?: any;
  stateValue?: any;
  itemGridType: ItemGridQuery;
  selectedRecordsForRemoval?:any[];
  showCheckbox?: boolean;
  isItemPanel?: boolean;
  initialSortValue?: SortValue[];
  levels?: ItemLevel[];
}) => {
    localStorage.setItem("storedSelectedRecordsForRemoval", JSON.stringify(selectedRecordsForRemoval));

    const key = Math.random();

    const BASE_URL: string = itemGridType === ItemGridQuery.DestroyItems ? `${config.API_BASE_URL}/Item` : `${config.API_BASE_URL}/Enc.Item`;
    const selectDisposalStatesQuery = 'DisposalStatus,RetentionClassId';
    const selectDisposalRequestsQuery = '*';
    const failureDetailQuery = `DisposalStates/Any(dr:dr/DisposalStatus eq 'DestructionActioned') and (DisposalDetails/Any(dd:startswith(dd/FailureDetail,'Src_') or startswith(dd/FailureDetail,'Enc_')))`;

    // Nested ICs cause the execute API to run multiple times with the incorrect disposalId so this is to make sure that the disposalId used in the API will always be corect
    const disposalRef = useRef(null);
    const execute = (state: DataStateChangeEventArgs): Promise<DataResult> => {    
      const stateAction = (state.action as any);
      const stateActionRequestType = (stateAction ?? { requestType: 'undefined' }).requestType;

      if (state.requestType === 'expand') return getChildData(state);

      if ((stateActionRequestType == StateActionRequestTypes.Sorting && stateAction.columnName != undefined) || 
          (stateActionRequestType === StateActionRequestTypes.Filtering && stateAction.action == StateActions.Filter)) return getSortData(state);

      if (stateActionRequestType === StateActionRequestTypes.Filtering && stateAction.action == StateActions.ClearFilter) localStorage.removeItem('itemsGridFilterQuery');

      return getData(state);
    };

    const getQueryOperation = (stateAction) => {
      switch(stateAction?.currentFilterObject.operator) {
        case 'equal': 
          return `(${(stateAction?.currentFilteringColumn as string).replace('.', '/')}) eq '${stateAction?.currentFilterObject.value}'`;
        case 'notequal': return `(${(stateAction?.currentFilteringColumn as string).replace('.', '/')}) ne '${stateAction?.currentFilterObject.value}'`;
        default:
          return `${stateAction?.currentFilterObject.operator}((${(stateAction?.currentFilteringColumn as string).replace('.', '/')}),'${stateAction?.currentFilterObject.value}')`
      }
    }


  const getSortData = async (
    state: DataStateChangeEventArgs
  ): Promise<DataResult> => {
    const stateAction = (state.action as any);
    const token = !!data ? '' : await acquireToken();
    const pageQuery = `$skip=${state.skip}&$top=${state.take}`;
    const sortedColumn = String(stateAction.columnName);
    const isFilter = stateAction.action == StateActions.Filter;
    const itemsGridFilterQuery = localStorage.getItem('itemsGridFilterQuery');

    const queryOperation = isFilter && getQueryOperation(stateAction);

    const filterQuery = isFilter ? ` ${stateAction?.currentFilterObject.predicate} (${queryOperation})`: itemsGridFilterQuery ? itemsGridFilterQuery :  '';
  
    // this is to save the filter value, reason is to retain the filter value when user did the sorting.
    localStorage.setItem('itemsGridFilterQuery', filterQuery);

    const sortQuery = sortedColumn != 'undefined' ? `&$orderby=${sortedColumn.replaceAll('.','/')} ${
      stateAction.direction == 'Descending' ? 'desc' : ''
    }` : '';
    
        //Query to select the parent
        const getTreeGridQuery = () => {
            const selectQuery = 'DisplayName,DateCreated,DateModified,Status,RepositoryUrl';
            switch (String(itemGridType)) {
                case ItemGridQuery.ChildWithStatus:
                    return `$filter=((MemberOf/Any(x:x/ID eq ${disposalRef?.current?.id}) or AttachmentOfId eq ${disposalRef?.current?.id}) and Status ne 'None')&$select=${selectQuery}&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems,CreatedBy,MemberOf,Members($count=true),DisposalRequests,DisposalStates($select=DisposalStatus,RetentionClassId)${sortQuery}`;
                case ItemGridQuery.Child:
                    return `$filter=(MemberOf/Any(x:x/ID eq ${disposalRef?.current?.id}) or AttachmentOfId eq ${disposalRef?.current?.id})&$select=${selectQuery}&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems,CreatedBy,MemberOf,Members($count=true),DisposalRequests,DisposalStates($select=DisposalStatus,RetentionClassId)${sortQuery}`;
                case ItemGridQuery.RootWithStatus:
                    return `$filter=((MemberOf/Any(x:x/ID eq ${disposalRef?.current?.id}) or AttachmentOfId eq ${disposalRef?.current?.id}) and Status ne 'None')${!!filter ? ' and (' + filter + ')' : ''
                        }&$select=${selectQuery}&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems,CreatedBy,MemberOf,Members($count=true),DisposalRequests,DisposalStates($select=${selectDisposalStatesQuery})${sortQuery}`;
                case ItemGridQuery.DestroyItems: {
                    let _filter;

                    if (String(filter) === DisposalRequestStatus.ItemDestroyInProgress)
                        _filter = `&$filter=DisposalStates/Any(dr:dr/DisposalStatus eq 'DestructionActioned') AND DisposalDetails/Any(ds:ds/FailureDetail eq 'None' AND ds/CompletedDate eq null)`;
                    else if (String(filter) === DisposalRequestStatus.ItemDestroyComplete)
                        _filter = `&$filter=Status eq 'Destroyed'`;
                    else
                        _filter = `&$filter=DisposalStates/Any(dr:dr/DisposalStatus eq 'DestructionActioned') AND ${failureDetailQuery}${filterQuery}`;

                    return `$count=true&$select=${selectQuery}&$expand=Repository,Classifications,TypeDef,BusinessType,StorageObject,AttachedItems,CreatedBy,MemberOf,Members($count=true),DisposalRequests($select=${selectDisposalRequestsQuery}),DisposalStates($select=${selectDisposalStatesQuery}),DisposalDetails($expand=DisposalRequest)${_filter}${sortQuery}`;
                }
                case ItemGridQuery.Root:
                default:
                    return `$filter=(DisposalRequests/ID eq ${disposalRef?.current?.id})${!!filter ? ' and (' + filter + ')' : ''
                        }&$select=${selectQuery}&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems,CreatedBy,MemberOf,Members($count=true),DisposalRequests,DisposalStates($select=${selectDisposalStatesQuery})&${sortQuery}`;
            }
        };

        /// filter query for fetching only the root level records
        const treegridQuery = getTreeGridQuery();

        try {
            const response = await fetch(
                `${BASE_URL}?${pageQuery}&${treegridQuery}&$inlinecount=allpages&$format=json`,
                {
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                }
            );
            let data = await response.json();

            //TODO: Add when treegrid nesting is required
            // if (String(itemGridType) != ItemGridQuery.Child) {
            //   (data?.value ?? []).map((x: any) => {
            //     //Is Parent
            //     if (x.Members || x.AttachedItems) {
            //       x.HasMember = true;
            //     } else {
            //       x.HasMember = false;
            //     }

            //     //ParentId Mapping
            //     if (x.MemberOf !== null || x.AttachmentOfId !== null) {
            //       x.ParentId = x.MemberOf.value[0].ID;
            //     } else {
            //       x.ParentId = null;
            //     }

            //     return x;
            //   });
            // }

            const storedSelectedRecordsForRemoval = JSON.parse(localStorage.getItem("storedSelectedRecordsForRemoval")) ?? [];
            const itemsNotMarkedForRemoval = (data.value ?? []).filter(x => !(storedSelectedRecordsForRemoval.some(y => x.ID == y.ID)));
            const result = itemGridType === ItemGridQuery.DestroyItems ? data?.value ?? [] : itemsNotMarkedForRemoval;

            return {
                count: data['@odata.count'] - ((data?.value?.length ?? 0) - (itemsNotMarkedForRemoval?.length ?? 0)),
                result
            };
        } catch (error) {
            console.error(error);
        }
        return {
            count: 0,
            result: [],
        };
    };

    const getData = async (
        state: DataStateChangeEventArgs
    ): Promise<DataResult> => {
        const token = !!data ? '' : await acquireToken();
        const pageQuery = `$skip=${state.skip}&$top=${state.take}`;
        const itemsGridFilterQuery = localStorage.getItem('itemsGridFilterQuery') ?? '';

        const getTreeGridQuery = () => {
            const selectQuery = 'DisplayName,DateCreated,DateModified,Status,RepositoryUrl';
            switch (String(itemGridType)) {
                case ItemGridQuery.ChildWithStatus:
                    return `$filter=((MemberOf/Any(x:x/ID eq ${disposalRef?.current?.id}) or (AttachmentOfId eq ${disposalRef?.current?.id})) and Status ne 'None')&$select=${selectQuery}&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems,CreatedBy,MemberOf,Members($count=true),DisposalRequests,DisposalStates($select=${selectDisposalStatesQuery})`;
                case ItemGridQuery.Child:
                    return `$filter=(MemberOf/Any(x:x/ID eq ${disposalRef?.current?.id}) or AttachmentOfId eq ${disposalRef?.current?.id})&$select=${selectQuery}&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems,CreatedBy,MemberOf,Members($count=true),DisposalRequests,DisposalStates($select=${selectDisposalStatesQuery})`;
                case ItemGridQuery.RootWithStatus:
                    return `$filter=((MemberOf/Any(x:x/ID eq ${disposalRef?.current?.id}) or (AttachmentOfId eq ${disposalRef?.current?.id})) and Status ne 'None')${
                        !!filter ? ' and (' + filter + ')' : ''
                        }&$select=${selectQuery}&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems,CreatedBy,MemberOf,Members($count=true),DisposalRequests,DisposalStates($select=${selectDisposalStatesQuery})`;
                case ItemGridQuery.DestroyItems: {
                    let _filter;

                    if (String(filter) === DisposalRequestStatus.ItemDestroyInProgress)
                    
                        _filter = `&$filter=DisposalStates/Any(dr:dr/DisposalStatus eq 'DestructionActioned') and DisposalDetails/Any(ds:ds/FailureDetail in ('None','Src_DeletionSuccessful') AND ds/CompletedDate eq null)`;
                    else if (String(filter) === DisposalRequestStatus.ItemDestroyComplete)
                        _filter = `&$filter=Status eq 'Destroyed'`;
                    //Failed To Destroy
                    else
                        _filter = `&$filter=(DisposalDetails/Any(dd:startswith(dd/FailureDetail,'Src_') or startswith(dd/FailureDetail,'Enc_')))${itemsGridFilterQuery}`;

                    return `$count=true&$select=${selectQuery}&$expand=Repository,Classifications,TypeDef,BusinessType,StorageObject,AttachedItems,CreatedBy,MemberOf,Members($count=true),DisposalRequests($select=${selectDisposalRequestsQuery}),DisposalStates($select=${selectDisposalStatesQuery}),DisposalDetails($expand=DisposalRequest)${_filter}`;
                }
                case ItemGridQuery.Root:
                default:
                    return `$filter=(DisposalRequests/ID eq ${disposalRef?.current?.id} and Status ne 'None')${
                      !!filter ? ' and (' + filter + ')' : ''
                        }&$select=${selectQuery}&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems,CreatedBy,MemberOf,Members($count=true),DisposalRequests($select=${selectDisposalRequestsQuery}),DisposalStates($select=${selectDisposalStatesQuery})`;
            }
        };

        /// filter query for fetching only the root level records
        const treegridQuery = getTreeGridQuery();
        try {
            const response = await fetch(
                `${BASE_URL}?${pageQuery}&${treegridQuery}&$inlinecount=allpages&$format=json`,
                {
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                }
            );
            let data = await response.json();

            //TODO: Add when treegrid nesting is required
            // if (String(itemGridType) != ItemGridQuery.Child) {
            //   (data?.value ?? []).map((x: any) => {
            //     //Is Parent
            //     if (x.Members || x.AttachedItems) {
            //       x.HasMember = true;
            //     } else {
            //       x.HasMember = false;
            //     }

            //     //ParentId Mapping
            //     if (x.MemberOf !== null || x.AttachmentOfId !== null) {
            //       x.ParentId = x.MemberOf.value[0].ID;
            //     } else {
            //       x.ParentId = null;
            //     }

            //     return x;
            //   });
            // }

            const storedSelectedRecordsForRemoval = JSON.parse(localStorage.getItem("storedSelectedRecordsForRemoval")) ?? [];
            const itemsNotMarkedForRemoval = (data.value ?? []).filter(x => !(storedSelectedRecordsForRemoval.some(y => x.ID == y.ID)));
            const result = itemGridType === ItemGridQuery.DestroyItems ? data?.value ?? [] : itemsNotMarkedForRemoval;

            return {
                count: data['@odata.count'] - ((data?.value?.length ?? 0) - (itemsNotMarkedForRemoval?.length ?? 0)),
                result,
            };
        } catch (error) {
            console.error(error);
        }
        return {
            count: 0,
            result: [],
        };
    };

    const getChildData = async (
        state: DataStateChangeEventArgs
    ): Promise<DataResult> => {
        const token = !!data ? '' : await acquireToken();

        const getTreeGridQuery = () => {
            const selectQuery = '*'; //$select=*
            switch (String(itemGridType)) {
                case ItemGridQuery.ChildWithStatus:
                    return `$select=${selectQuery}&$expand=Classifications,TypeDef,StorageObject,BusinessType,AttachedItems($count=true),CreatedBy,MemberOf,Members($count=true),DisposalStates($select=${selectDisposalStatesQuery}),DisposalRequests&$filter=(MemberOf eq ${
                      (state.data as any).ID == null ? '-1' : (state.data as any).ID
                        }) or (AttachmentOfId eq ${
                          (state.data as any).ID == null ? '-1' : (state.data as any).ID
                        }) and Status ne 'None'`;
                case ItemGridQuery.Child:
                    return `$select=${selectQuery}&$expand=Classifications,TypeDef,StorageObject,BusinessType,AttachedItems($count=true),CreatedBy,MemberOf,Members($count=true),DisposalStates($select=${selectDisposalStatesQuery}),DisposalRequests&$filter=(MemberOf eq ${
                      (state.data as any).ID == null ? '-1' : (state.data as any).ID
                        }) or (AttachmentOfId eq ${
                          (state.data as any).ID == null ? '-1' : (state.data as any).ID
                        })`;
                case ItemGridQuery.RootWithStatus:
                    return `$select=${selectQuery}&$expand=Classifications,TypeDef,StorageObject,BusinessType,AttachedItems($count=true),CreatedBy,MemberOf,Members($count=true),DisposalStates($select=${selectDisposalStatesQuery}),DisposalRequests&$filter=(MemberOf eq ${
                      (state.data as any).ID == null ? '-1' : (state.data as any).ID
                        } and Status ne 'None') or (AttachmentOfId eq ${
                          (state.data as any).ID == null ? '-1' : (state.data as any).ID
                        } and Status ne 'None')`;
                case ItemGridQuery.Root:
                default:
                    return `$select=${selectQuery}&$expand=Classifications,TypeDef,StorageObject,BusinessType,AttachedItems($count=true),CreatedBy,MemberOf,Members($count=true),DisposalStates($select=${selectDisposalStatesQuery}),DisposalRequests&$filter=(MemberOf eq ${
                      (state.data as any).ID == null ? '-1' : (state.data as any).ID
                        }) or (AttachmentOfId eq ${
                          (state.data as any).ID == null ? '-1' : (state.data as any).ID
                        })`;
            }
        };

        const treegridQuery = getTreeGridQuery();

        try {
            const response = await fetch(
                `${BASE_URL}?&${treegridQuery}&$inlinecount=allpages&$format=json`,
                {
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                }
            );
            let data = await response.json();
            (data?.value ?? []).map((x: any) => {
                //Is Parent
                if (x.Members || x.AttachedItems) {
                    x.HasMember = true;
                } else {
                    x.HasMember = false;
                }

                //TODO: Add when treegrid nesting is required
                //ParentId Mapping
                // if (x.MemberOf !== null || x.AttachmentOfId !== null) {
                //   x.ParentId =
                //     x.MemberOf?.value.filter((x) => x.ID == x.ID).length > 0
                //       ? x.ID
                //       : null;
                // } else {
                //   x.ParentId = null;
                // }

                return x;
            });

      return data.value;
    } catch (error) {
      console.error(error);
    }
  };
  const getDataSource = () => {
    if (
      gridRef?.current &&
      gridRef?.current.dataSource instanceof Array &&
      !(gridRef?.current.dataSource as object[]).length
    ) {
      const admin = () => {
        if (gridRef?.current) {
          if (itemGridType === ItemGridQuery.DestroyItems) {
            switch(filter) {
              case DisposalRequestStatus.ItemDestroyInProgress: {
                gridRef.current.hideColumns([
                  'Checkbox',
                  'Extension',
                  'Created By',
                  'Date Created',
                  'Date Modified',
                  'Date Destroyed',
                  'Retention Classes',
                  'Business Type',
                  'Item Status',
                  'Destruction Failure',
                  'Disposal Status',
                  'Disposal Requests',
                ]);

                gridRef.current.showColumns([
                  'Repository URL'
                ]);

                return;
              }

              case DisposalRequestStatus.Failed: {
                gridRef.current.hideColumns([
                  'Checkbox',
                  'Extension',
                  'Created By',
                  'Date Created',
                  'Date Modified',
                  'Date Destroyed',
                  'Retention Classes',
                  'Business Type',
                  'Item Status',
                  'Disposal Status',
                ]);

                gridRef.current.showColumns([
                  'Disposal Requests',
                  'Destruction Failure',
                  'Repository URL'
                ]);

                return;
              }

              case DisposalRequestStatus.ItemDestroyComplete: {
                gridRef.current.hideColumns([
                  'Checkbox',
                  'Extension',
                  'Created By',
                  'Date Created',
                  'Date Modified',
                  'Destruction Failure',
                  'Disposal Status',
                  'Business Type',
                  'Repository URL',
                  'Disposal Requests',
                ]);

                gridRef.current.showColumns([
                  'Item Status',
                  'Date Destroyed',
                  'Retention Classes',
                ]);

                return;
              }

              default: 
                return null
            }
          } else {
            /** show by HeaderText */
            const toHideColumns = [
              'Extension',
              'Created By',
              'Date Created',
              'Date Modified',
              'Disposal Requests',
              'Date Destroyed',
              'Destruction Failure',
            ];
            const toShowColumns = [
              'Disposal Status',
              'Item Status',
              'Business Type',
              'Status',
            ];

            if (showCheckbox && itemGridType !== ItemGridQuery.Child) toShowColumns.push('Checkbox');
            else toHideColumns.push('Checkbox');

            gridRef.current.hideColumns(toHideColumns);
            gridRef.current.showColumns(toShowColumns);
          }
        }
      };
      const normal = () => {
        if (gridRef?.current) {
          /** hide by HeaderText */
          gridRef.current.hideColumns([
            'Disposal Status',
            'Item Status',
            'Business Type',
            'Status',
            'Checkbox',
            'Date Destroyed',
            'Disposal Requests', 
            'Disposal Status',
            'Item Status',
            'Destruction Failure',
            'Disposal Requests'
          ]);
          gridRef.current.showColumns([
            'Extension',
            'Created By',
            'Date Created',
            'Date Modified',
          ]);
        }
      };
      if (adminMode && !isItemPanel) {
        admin();
      } else {
        normal();
      }
      let state;
      if (!stateValue && levels?.length > 0 && levels?.[levels?.length - 1]?.pageIndex) {
        const initPage = levels[levels.length - 1]?.pageIndex;
        state = {
          skip: (initPage - 1) * pageSize,
          take: pageSize,
          ...initialSortValue && ({
            action: {
              requestType: StateActionRequestTypes.Sorting,
              columnName: initialSortValue[0]?.field,
              direction: initialSortValue[0]?.direction,
            }
          })
        };
      }
      else state = { skip: 0, take: pageSize };
      dataStateChange(state);
    }
  };

  const dataStateChange = (state: any): void => {
    if (state.requestType === 'expand') {
        execute(state).then((childData: any) => {
            state.childData = childData;
            state.childDataBind();
        });
        return;
    }

    execute(state).then((treedata) => {
      if (gridRef?.current) {
        gridRef.current.dataSource = treedata;
      }
    });
  };
  
  const [comment, setComment] = useState<string | null>(null);
  const [contextAnchor, setContextAnchor] = useState<Element | null>(null);
  const [contextItem, setContextItem] = useState<Item | undefined>();

  const { data: failureDetailData } = useGetFailureDetailQuery({});

  const failureDetailsLookup = failureDetailData?.value[0]?.Members?.value;

  const rowSelected = () => {
      if (gridRef?.current) {
          /** Get the selected records. */
          if (onSelect) {
              const selectedrecords = gridRef.current.getSelectedRecords();
              onSelect(selectedrecords);
          }
      }
  };
  const rowDeselected = () => {
      if (gridRef?.current) {
          /** Get the selected records. */
          if (onSelect) {
              const selectedrecords = gridRef.current.getSelectedRecords();
              console.log('selectedrecords', selectedrecords);
              onSelect(selectedrecords);
          }
      }
  };

  useEffect(() => {
    // disposalRef is an object in case more properties needs to be added to it
    disposalRef.current = {
      id: disposalId,
    };
  },[disposalId]);

  return {
      comment,
      setComment,
      contextAnchor,
      setContextAnchor,
      contextItem,
      setContextItem,
      dataStateChange,
      getDataSource,
      gridRef,
      rowSelected,
      key,
      rowDeselected,
      failureDetailsLookup,
  };
};

export default useItemsGrid;
