import { Copy20Regular } from '@fluentui/react-icons';
import { useState } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import { Tooltip } from './Tooltip';

export type CopyToClipboardButtonProps = {
  text?: string;
  copyMessage?: string;
  copiedMessage?: string;
  copyErrorMessage?: string;
  color?: string;
};

export const CopyToClipboardButton = ({
  text,
  copyMessage = 'Click to copy',
  copiedMessage = 'Copied',
  copyErrorMessage = 'Copy is not supported',
  color,
}: CopyToClipboardButtonProps) => {
  const [tooltipTitle, setTooltipTitle] = useState(copyMessage);

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setTooltipTitle(copiedMessage);
    } catch (err) {
      setTooltipTitle(copyErrorMessage);
    }
  };

  const handleMouseLeave = () => {
    setTooltipTitle(copyMessage);
  };

  return (
    <Tooltip title={tooltipTitle} onOpen={handleMouseLeave}>
      <IconButton
        onClick={handleClick}
        size='small'
        disableRipple
        sx={{
          color: { color },
        }}
      >
        <Copy20Regular />
      </IconButton>
    </Tooltip>
  );
};
