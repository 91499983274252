import { useUser } from '../../hooks';
import { config } from '../../config';

type ProtectedRouteProps = {
  homepageUrl: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const ProtectedRoute = ({ homepageUrl, children }: ProtectedRouteProps) => {
  const user = useUser();

  let isUserAllowedAccess = true;

  if (user?.localId && !user?.isUserApiFetching && user?.ConfiguredApps) {
    const currApp = user?.ConfiguredApps
      .find((app) => app.DisplayName === config.TITLE);

    isUserAllowedAccess = currApp?.IsAuthorised;

    if (isUserAllowedAccess) return <>{children}</>;
    else {
      window.location.href = homepageUrl;
      return <></>;
    }
  } else if (user?.localId && !user?.isUserApiFetching && !user?.ConfiguredApps) {
    window.location.href = homepageUrl;
    return <></>;
  } else return <></>;
};
