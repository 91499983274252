import { SVGProps } from 'react';
const SvgDestructionActioned24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10 5H14C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5ZM8.5 5C8.5 3.067 10.067 1.5 12 1.5C13.933 1.5 15.5 3.067 15.5 5H21.25C21.6642 5 22 5.33579 22 5.75C22 6.16421 21.6642 6.5 21.25 6.5H19.9309L18.7589 18.6112C18.5729 20.5334 16.9575 22 15.0263 22H8.97369C7.04254 22 5.42715 20.5334 5.24113 18.6112L4.06908 6.5H2.75C2.33579 6.5 2 6.16421 2 5.75C2 5.33579 2.33579 5 2.75 5H8.5ZM6.73416 18.4667C6.84577 19.62 7.815 20.5 8.97369 20.5H15.0263C16.185 20.5 17.1542 19.62 17.2658 18.4667L18.4239 6.5H5.57608L6.73416 18.4667Z'
      fill='#697186'
    />
    <path
      d='M14.966 13.4529C15.0036 13.2047 15.2035 13 15.4545 13C15.7558 13 16.0039 13.2456 15.9633 13.5441C15.6979 15.4958 14.0246 17 12 17C9.79086 17 8 15.2091 8 13C8 10.7909 9.79086 9 12 9C13.1947 9 14.2671 9.52375 15 10.3542V9.5C15 9.22386 15.2239 9 15.5 9C15.7761 9 16 9.22386 16 9.5V11.5C16 11.7761 15.7761 12 15.5 12H13.5C13.2239 12 13 11.7761 13 11.5C13 11.2239 13.2239 11 13.5 11H14.2361C13.6868 10.3863 12.8885 10 12 10C10.3431 10 9 11.3431 9 13C9 14.6569 10.3431 16 12 16C13.5029 16 14.7477 14.8949 14.966 13.4529Z'
      fill='#697186'
    />
  </svg>
);
export default SvgDestructionActioned24;
