import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';

export const DateTimeField = ({ value, onChange }) => {
  const theme = useTheme();

  return (
    <>
      <DateTimePickerComponent
        style={{
          width: '100%',
          fontFamily: 'Avenir Book',
          fontWeight: '400',
          fontSize: '0.875rem',
          lineHeight: '1.195rem',
          color: theme.palette.info.dark,
        }}
        cssClass='custom-datepicker'
        placeholder='Select a date and time'
        value={value ? new Date(value) : null}
        showClearButton={false}
        showTodayButton={false}
        onChange={(event) => onChange(event.value ? dayjs(event.value) : null)}
        format="MM/dd/yyyy hh:mm"
      />
    </>
  );
};
