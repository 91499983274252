import { Add12Regular, DismissSquare24Regular } from '@fluentui/react-icons';
import { Divider } from '@mui/material';
import React, { useState } from 'react';
import { useUpdateDisposalRequestPropsMutation } from '../services';
import { DisposalRequest, DisposalRequestStatus, User } from '../types';
import { formatDate } from '../util/dateTime';
import { Box } from './Box';
import { CommentText } from './CommentText';

import { BannerNotice } from './BannerNotice';
import { DetailsRow } from './DetailsRow';
import { DisposalRequestApproversPanel } from './DisposalRequestApprovers';
import { DisposalRequestProgressIndicator } from './DisposalRequestProgressIndicator';
import { DisposalRequestResponses } from './DisposalRequestResponses';
import { InfoPanel } from './InfoPanel';
import { DisposalRequestStatusAlert, getMessageForStatus } from './alert';
import { TextIconButton } from './button';
import { RetentionClassChip, UserChip } from './chip';
import { DisposalRequestStatusChip } from './chip/DisposalRequestStatusChip';
import { DetailDialog, EditTextDialog } from './dialog';

export type DisposalRequestDetailsPanelProps = {
  request: DisposalRequest;
  adminMode?: boolean;
  setApprovalErrorMessage?: (message: string) => void;
  setWarningAlertOpen?: () => void;
  setChangeApproversDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditApprovers?: React.Dispatch<React.SetStateAction<boolean>>;
  multipleRetentionClasses?: boolean;
  currentSelectedApprovers?: any;
  warnings?: any;
};

export const DisposalRequestDetailsPanel = ({
  request,
  adminMode = false,
  setApprovalErrorMessage,
  setWarningAlertOpen,
  setChangeApproversDialogOpen,
  setIsEditApprovers,
  multipleRetentionClasses = false,
  warnings = {},
  currentSelectedApprovers,
}: DisposalRequestDetailsPanelProps) => {
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [commentDialogText, setCommentDialogText] = useState('');

  const [changeNameDialogOpen, setChangeNameDialogOpen] = useState(false);
  const [changeCommentDialogOpen, setChangeCommentDialogOpen] = useState(false);

  const withWarning = Object.values(warnings).some((x: boolean) => x === true);
  const isNewOrRejected = adminMode && (request?.Status !== DisposalRequestStatus.New && request?.Status !== DisposalRequestStatus.Rejected);

  const closeChangeNameDialogHandler = () => {
    setChangeNameDialogOpen(false);
  };

  const closeChangeCommentDialogHandler = () => {
    setChangeCommentDialogOpen(false);
  };
  const commentBoxOpenHandler = (text: string) => {
    setCommentDialogOpen(true);
    setCommentDialogText(text);
  };

  const infoDialogCloseHandler = () => {
    setCommentDialogOpen(false);
    setCommentDialogText('');
  };

  const [
    showExceptionRejectedInOtherRequest,
    setShowExceptionRejectedInOtherRequest,
  ] = useState(true);
  const [showExceptionRetentionConflict, setShowExceptionRetentionConflict] =
    useState(true);

  const [showApproved, setShowApproved] = useState(true);

  const [updateDisposalRequestProps] = useUpdateDisposalRequestPropsMutation();

  const message = getMessageForStatus(request, multipleRetentionClasses);
  const exceptionText = request.Exception;
  const approvedText =
    'This Disposal Request has been fully approved and is ready to dispose.';
  const rejectedText = 'Disposal of Items in this request have been rejected.';

  // TODO: is this code needed? it's not used so commented out for now.
  // const { data: approvers, isSuccess: approversIsSuccess } =
  //   useGetApproversListQuery();

  return (
    <Box background='none' style={{ overflow: 'auto', height: '100%' }}>
      {/* TODO: refactor this part, modify BannerNotice styling */}
      {!!warnings.RejectedInOtherRequest &&
        showExceptionRejectedInOtherRequest &&
        adminMode && (
          <BannerNotice
            type='warning'
            text={
              'Items in this Request have reject responses in other Requests that they are assigned to.'
            }
            onClose={() => setShowExceptionRejectedInOtherRequest(false)}
            isRelative={true}
          />
        )}
      {!!warnings.RetentionConflict &&
        showExceptionRetentionConflict &&
        adminMode && (
          <BannerNotice
            type='warning'
            text={
              'This Disposal Request contains Items with Retention Conflicts.  Items with conflicts will be deferred, when disposal is actioned.'
            }
            onClose={() => setShowExceptionRetentionConflict(false)}
            isRelative={true}
          />
        )}
      {request.Status == 'Approved' && showApproved && adminMode && (
        <BannerNotice
          type='info'
          text={approvedText}
          onClose={() => setShowApproved(false)}
          isRelative={true}
        />
      )}
      {request.Status == 'Rejected' && adminMode && (
        <BannerNotice
          type='warning'
          text={rejectedText}
          onClose={() => setShowApproved(false)}
          isRelative={true}
          icon={<DismissSquare24Regular className='ecs-banner-notice-icon' />}
        />
      )}
      <DetailDialog
        open={commentDialogOpen}
        title='Comment'
        onClose={infoDialogCloseHandler}
      >
        {commentDialogText}
      </DetailDialog>

      {adminMode && (
        <>
          {!(
            message === exceptionText ||
            message === approvedText ||
            message === rejectedText
          ) && (
            <DisposalRequestStatusAlert
              request={request}
              multipleRetentionClasses={multipleRetentionClasses}
            />
          )}

          <InfoPanel style={{ padding: '2.5rem 0rem 2.5rem 7rem' }}>
            <DisposalRequestProgressIndicator
              requestStatus={request.Status as DisposalRequestStatus}
              multipleRetentionClasses={multipleRetentionClasses}
              withWarning={withWarning}
            />
          </InfoPanel>
          <Divider
            style={{
              margin: '0 0 1rem 7rem',
              width: '43.625rem',
            }}
          />
        </>
      )}

      <EditTextDialog
        text={request?.RequestComments ?? ''}
        label='Comment'
        open={changeCommentDialogOpen}
        multiline
        onClose={closeChangeCommentDialogHandler}
        onSave={async (comment) => {
          try {
            const response = await updateDisposalRequestProps({
              requestId: request.ID,
              body: { RequestComments: comment },
            });

            if ('error' in response) {
              const _response = response as {
                error: {
                  data: {
                    FriendlyMessage: string;
                  };
                };
              };
              setApprovalErrorMessage(_response.error.data.FriendlyMessage);
              setWarningAlertOpen();
            }
          } catch (err) {
            setWarningAlertOpen();
          } finally {
            setChangeCommentDialogOpen(false);
          }
        }}
      />

      <EditTextDialog
        text={request?.Name ?? ''}
        label='Name'
        open={changeNameDialogOpen}
        minLength={1}
        onClose={closeChangeNameDialogHandler}
        onSave={async (name) => {
          try {
            const response = await updateDisposalRequestProps({
              requestId: request.ID,
              body: { name: name },
            });

            if ('error' in response) {
              const _response = response as {
                error: {
                  data: {
                    FriendlyMessage: string;
                  };
                };
              };
              setApprovalErrorMessage(_response?.error?.data?.FriendlyMessage);
              setWarningAlertOpen();
            }
          } catch (err) {
            setWarningAlertOpen();
          } finally {
            setChangeNameDialogOpen(false);
          }
        }}
      />

      <InfoPanel title='Request details' inset={'7rem'} width={46}>
        <DetailsRow
          label='Name'
          data={request.Name}
          onEdit={
            adminMode &&
            (request?.Status === DisposalRequestStatus.New ||
              request?.Status === DisposalRequestStatus.Rejected)
              ? () => setChangeNameDialogOpen(true)
              : null
          }
        />

        <DetailsRow label='Disposal Action' data={request.DisposalAction} />

        <DetailsRow
          label='Requested By'
          data={
            request.RequestedBy?.DisplayName && (
              <UserChip
                border={true}
                user={{ name: request.RequestedBy?.DisplayName } as User}
              />
            )
          }
          alignData
        />

        <DetailsRow
          label='Status'
          data={<DisposalRequestStatusChip status={request.Status} />}
          alignData
        />

        <DetailsRow
          label='Approvers'
          data={
            ((request?.ApproverResponses?.value?.length > 0) && (currentSelectedApprovers?.length > 0)) ? (
              <DisposalRequestApproversPanel
                approvers={currentSelectedApprovers}
                disposalRequest={request}
              />
            ) : (
              adminMode && (
                <>
                  <DisposalRequestApproversPanel
                    approvers={currentSelectedApprovers}
                    disposalRequest={request}
                  />
                  <TextIconButton
                    size='small'
                    textVariant='body2'
                    startIcon={<Add12Regular />}
                    onClick={() => {
                      setIsEditApprovers(false);
                      setChangeApproversDialogOpen(true);
                    }}
                    text='Add approvers'
                  />
                </>
              )
            )
          }
          onEdit={
            request?.ApproverResponses?.value?.length > 0 &&
            currentSelectedApprovers?.length > 0 &&
            adminMode &&
            (request?.Status === DisposalRequestStatus.New ||
              request?.Status === DisposalRequestStatus.Rejected)
              ? () => {
                setIsEditApprovers(true);
                setChangeApproversDialogOpen(true);
              }
              : null
          }
          alignData={
            (request?.ApproverResponses?.value?.length ?? 0) === 0 && adminMode
          }
        />

        <DetailsRow
          label='Date Requested'
          data={
            (adminMode && request?.Status === DisposalRequestStatus.New) ||
            (request?.ApproverResponses?.value?.length ?? 0) === 0
              ? ''
              : formatDate(request?.DateRequested!)
          }
        />

        <DetailsRow
          label='Date Created'
          data={formatDate(request?.DateCreatedInEnc!)}
        />

        <DetailsRow
          label='Retention Class'
          data={<RetentionClassChip classification={request.RetentionClass} />}
          alignData
        />

        {(request?.RequestComments || isNewOrRejected) ? (
          <DetailsRow
            label='Comment'
            data={
              <CommentText
                comment={request?.RequestComments ?? ''}
                onClick={commentBoxOpenHandler}
              />
            }
            onEdit={
              adminMode &&
              (request?.Status === DisposalRequestStatus.New ||
                request?.Status === DisposalRequestStatus.Rejected)
                ? () => setChangeCommentDialogOpen(true)
                : null
            }
          />
        ) : (adminMode ? (
          <DetailsRow
            label='Comment'
            data={
              <TextIconButton
                size='small'
                textVariant='body2'
                startIcon={<Add12Regular />}
                onClick={() => setChangeCommentDialogOpen(true)}
                text='Add comment'
              />
            }
            alignData
          />
        ) : (
          <></>
        ))}

        <Divider style={{ margin: '1.25rem 0 1.25rem 0' }} />
      </InfoPanel>
      {request.ApproverResponses?.value && (
        <InfoPanel title='Responses' inset={'7rem'} width={46}>
          <DisposalRequestResponses
            responses={request.ApproverResponses?.value}
          />
        </InfoPanel>
      )}
    </Box>
  );
};
