import { DisposalApproval, Disposal, Imc } from '../icons';


export const appLogos = {
  disposalapproval: DisposalApproval,
  disposal: Disposal,
  imc: Imc,
};

// gets the logo for the specified app
export const getLogoForApp = (appName) => {
  if (appName == null) {
    return null;
  }
  let formattedAppName = appName.replace(/\s/g, '').toLowerCase();
  let logo = appLogos[formattedAppName];
  return logo;
};
