import { Typography } from '@mui/material';
import { PageSettingsModel } from '@syncfusion/ej2-react-grids';
import { TreeGridComponent } from '@syncfusion/ej2-react-treegrid';
import { memo, useEffect, useState } from 'react';
import { Box } from '../../Box';
import { MemoizedPageSelector, MemoizedPageSizeDropdown } from '../components';

// Todo: check why totalPages does not appear in PageSettingsModel
export type PagerData = PageSettingsModel & {
  totalPages: number;
};

export const PagerTemplate = ({
  pagerData,
  grid,
  pageSizes,
}: {
  pagerData: PagerData;
  grid: TreeGridComponent;
  pageSizes: number[];
}) => {
  const [currPage, setCurrPage] = useState<number>(pagerData?.currentPage ?? 1);
  const moveToPage = (page: number) => {
    // refresh is needed to get the updated data after the initial different page render
    grid.refresh();
    grid.pageSettings.currentPage = page;
    setCurrPage(page);
  };

  const changePageSize = (size: number) => {
    grid.pageSettings.pageSize = size;
  };

  const totalPages = pagerData.totalPages;
  const pageSize = pagerData.pageSize;
  const start = currPage * pageSize - pageSize + 1;
  const end =
    currPage === totalPages
      ? pagerData.totalRecordsCount ?? 0
      : currPage * pageSize;

  useEffect(() => {
    if (grid) grid.pageSettings.currentPage = currPage;
  },[currPage]);

  return pagerData.totalRecordsCount == 0 ? (
    <></>
  ) : (
    <Box
      background='none'
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      padding='large'
      height={3}
      style={{ flex: 1 }}
    >
      <Typography variant='body1'>
        Showing {start} - {end} of {pagerData.totalRecordsCount} items
      </Typography>
      <MemoizedPageSelector
        currentPage={currPage}
        totalPages={totalPages}
        onSelect={moveToPage}
      />
      <MemoizedPageSizeDropdown
        options={pageSizes}
        onChange={changePageSize}
        pageSize={pageSize}
      />
    </Box>
  );
};

export const MemoizedPagerTemplate = memo(PagerTemplate);
