import { Box } from '../../../Box';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { ItemBusinessClass, ItemIconValue } from '../../../../types';
import { OptionSelect } from '../../../OptionSelect';

const StyledImage = styled('img')``;

export type PerspectiveClassFieldProps = {
  iconDetails: ItemIconValue;
  handleDelete?: () => void;
  perspectiveClasses: ItemBusinessClass[];
  selectedPerspectiveClass: ItemBusinessClass;
  handleSelect: (perspectiveClass: ItemBusinessClass) => void;
};

export const PerspectiveClassField = ({
  iconDetails,
  handleDelete,
  handleSelect,
  perspectiveClasses,
  selectedPerspectiveClass,
}: PerspectiveClassFieldProps) => {
  return (
    <Box width='29rem' background='none'>
      <OptionSelect
        handleSelect={handleSelect}
        handleDelete={handleDelete}
        options={perspectiveClasses}
        placeholder='- Select a perspective class -'
        selectedOption={
          selectedPerspectiveClass ? (
            <Box background='none' alignItems='center' direction='row'>
              {iconDetails ? (
                <StyledImage
                  alt={iconDetails?.AltText}
                  style={{ height: '1.5rem' }}
                  src={`${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`}
                />
              ) : null}
              <Typography variant='body2' ml={1}>
                {selectedPerspectiveClass?.Name}
              </Typography>
            </Box>
          ) : null
        }
      />
    </Box>
  );
};
