import { Person20Regular } from '@fluentui/react-icons';
import { useTheme } from '@mui/material';
import { User } from '../../types';
import { Chip, ChipProps } from './Chip';

export type UserChipProps = {
  user: User;
  onClick?: (user: User) => void;
} & Omit<ChipProps, 'onClick'>;

export const UserChip = ({
  user,
  onClick = undefined,
  sx,
  ...props
}: UserChipProps) => {
  const theme = useTheme();
  return (
    <Chip
      {...props}
      color='default'
      variant='outlined'
      icon={<Person20Regular color={theme.palette.info[700]} />}
      title={user?.name ?? ''}
      onClick={onClick && (() => onClick(user))}
      sx={{
        width: '100%',
        maxWidth: 'fit-content',
        '& > .MuiChip-icon': {
          margin: '0 0.25rem 0 0',
          minWidth: '1.25rem',
        },
        ...sx
      }}
    />
  );
};
