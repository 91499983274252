import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { User, App, UserServiceAccess } from '../types';
import { prepareHeaders } from '../auth';
import { config } from '../config';

const ENDPOINT = 'User';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders,
  }),

  endpoints: (builder) => ({
    getCurrentUser: builder.query<User, void>({
      query: () => {
        return {
          url: 'Me',
          method: 'GET',
        };
      },
      transformResponse: (data: any) => {
        return {
          ...data,
          ConfiguredApps: data.ConfiguredApps
            ? (data.ConfiguredApps.value.map((app: any) => ({...app, ...app.App})) as App[])
            : [],
        };
      },
    }),
    getUser: builder.query<User, User>({
      query: (user) => {
        const selectQuery = 'ID,DisplayName,Mail,Surname,GivenName';
        return {
          url: `${ENDPOINT}?$select=${selectQuery}}&$filter=AuthIdentity eq '${user.username.toLowerCase()}'`,
          method: 'GET',
        };
      },
      //return the first user in the list (should only be 1)
      transformResponse: ({ value: userList }: { value: User[] }) =>
        userList?.[0],
    }),
    getUserServiceAccess: builder.query<UserServiceAccess, void>({
      query: () => {
        return {
          url: 'UserServiceAccess',
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useGetCurrentUserQuery, useGetUserQuery, useGetUserServiceAccessQuery } = userApi;
