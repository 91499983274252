import { memo, useEffect, useState } from 'react';
import { Item, RetentionClass } from '../../../types/itemTypes';
import { Box } from '../../Box';
import {
  CombinedItemStatus,
  ItemDisposalStatusChip,
} from '../../chip/ItemDisposalStatusChip';
import { DisposalRequest, DisposalRequestStatus } from '../../../types';

export const DisposalStatusTemplate = ({
  item,
  disposalRequest,
}: {
  item?: Item;
  disposalRequest?: DisposalRequest;
}) => {
  const [retentionClassLookup, setRetentionClassLookup] = useState<
    Record<string, RetentionClass>
  >({});

  const renderDisposalStatusChip = () => {
    let disposalRequestIndex = 0;

    return (item.DisposalStates?.value ?? []).map((disposalState, i) => {
      let status = '';

      // If DisposalStates' DisposalStatus is AssignedToRequest,
      // DisposalRequest' Status will be checked. If Status is Approved or Rejected,
      // this value will be used
      if (disposalState?.DisposalStatus === 'AssignedToRequest') {
        if (
          item?.DisposalRequests?.value[disposalRequestIndex]?.Status ===
            DisposalRequestStatus.Approved ||
          item?.DisposalRequests?.value[disposalRequestIndex]?.Status ===
            DisposalRequestStatus.Rejected
        ) {
          status = item?.DisposalRequests?.value[disposalRequestIndex]?.Status;
        } else status = disposalState?.DisposalStatus;
        disposalRequestIndex++;
      } else status = disposalState?.DisposalStatus;

      return (
        <ItemDisposalStatusChip
          status={status as CombinedItemStatus}
          subTitle={
            retentionClassLookup[disposalState.RetentionClassId ?? 'unknown']
              ?.DisplayName ?? '-'
          }
          key={i}
        />
      );
    });
  };

  useEffect(() => {
    const _lookup = {};
    item.Classifications?.value?.forEach(
      (rc: RetentionClass) => (_lookup[rc.ID] = rc)
    );
    setRetentionClassLookup(_lookup);
  }, [item.Classifications?.value]);

  return (
    <Box background='none' alignItems='start'>
      {renderDisposalStatusChip()}
    </Box>
  );
};

export const MemoizedDisposalStatusTemplate = memo(DisposalStatusTemplate);
