import { Approver, DisposalRequestResponseStatus } from '../types';
import { Box, BoxProps } from './Box';
import { Typography, useTheme } from '@mui/material';
import { DisposalRequestResponseStatusText } from './DisposalRequestResponseStatusText';
import { useEffect, useState } from 'react';

export type DisposalRequestTempApproversProps = {
  approvers?: Approver[];
  startNumber?: number;
  width?: BoxProps['width'];
};

export const DisposalRequestTempApprovers = ({
  approvers,
  startNumber = 0,
  width,
}: DisposalRequestTempApproversProps) => {
  const [currentApprovers, setCurrentApprovers] = useState(approvers);

  const [key, setKey] = useState('key');

  useEffect(() => {
    setCurrentApprovers(approvers);
    // setKey('new key');
  }, [approvers]);

  return (
    <Box
      key={key}
      background='none'
      style={{
        overflow: 'visible',
        minWidth: '14rem',
        maxWidth: '20rem',
      }}
      width={width}
      gap='small'
      shrink
    >
      {currentApprovers?.map((app, index) => {
        return (
          <Box
            direction='row'
            background='none'
            key={app._Display}
            style={{
              width: '20rem',
            }}
          >
            <Typography
              variant='body2'
              sx={{
                minWidth: '2.25rem',
                maxWidth: '2.25rem',
                fontWeight: '800',
              }}
            >
              {(index + startNumber + 1).toString().padStart(2, '0')}
            </Typography>
            <Box
              background='none'
              alignItems='start'
              style={{ alignSelf: 'stretch' }}
            >
              <Typography variant='body2' noWrap>
                {app._Display}
              </Typography>
            </Box>
            <DisposalRequestResponseStatusText
              status={app.Status ?? ''}
              width={'5rem'}
            />
          </Box>
        );
      })}
    </Box>
  );
};
