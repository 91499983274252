import { Link, Typography, useTheme } from '@mui/material';
import { Item, User } from '../../../types';
import { getFormattedDateTime } from '../../../util';
import { DetailsRow } from '../../DetailsRow';
import { UserChip } from '../../chip';
import { Tooltip } from '../../Tooltip';
import { useTooltip } from '../../../hooks';

export type ItemTypeVideoPanelProps = {
  item: Item;
};

export const ItemTypeVideoPanel = ({ item }: ItemTypeVideoPanelProps) => {
  const theme = useTheme();

  const itemType = item?.TypeDef?._Display;
  const repositoryUrl = item?.RepositoryUrl;
  const contentDetails = item?.StorageObject ?? item?.Content;
  const fileSize = contentDetails?.FileSize
    ? `${(contentDetails?.FileSize / (1024 * 1024)).toFixed(1)} MB`
    : null;

  const { dataRef, title: tooltipTitle } = useTooltip(repositoryUrl);

  // TODO: Refactor this component, make the property names dynamic.
  return (
    <>
      <DetailsRow
        label='Name'
        data={item?.DisplayName}
        key={`${itemType}-name`}
      />
      <DetailsRow
        label='Date Captured'
        data={getFormattedDateTime(item?.MediaDateCaptured)}
        key={`${itemType}-date-captured`}
      />
      <DetailsRow
        label='Length'
        data={item?.MediaLength ? `${item?.MediaLength}` : null}
        key={`${itemType}-length`}
      />
      <DetailsRow
        label='File Extension'
        data={contentDetails?.FileExtension}
        key={`${itemType}-extension`}
      />
      <DetailsRow label='Size' data={fileSize} key={`${itemType}-size`} />
      <DetailsRow
        label='Created By'
        data={
          item?.CreatedBy?.DisplayName ? (
            <UserChip user={{ name: item?.CreatedBy?.DisplayName } as User} />
          ) : null
        }
        key={`${itemType}-created-by`}
      />
      <DetailsRow
        label='Date Created'
        data={getFormattedDateTime(item?.DateCreated)}
        key={`${itemType}-date-created`}
      />
      <DetailsRow
        label='Date Modified'
        data={getFormattedDateTime(item?.DateModified)}
        key={`${itemType}-date-modified`}
      />
      {/* https://encompaas.visualstudio.com/EncompaaS/_workitems/edit/54146 */}
      {/* <DetailsRow
        label='Description'
        data={<Typography variant='body1'>{item?.Description}</Typography>}
        key={`${itemType}-description`}
      /> */}
      <DetailsRow
        label='Repository URL'
        data={
          <Link
            href={item?.RepositoryUrl}
            target='_blank'
            sx={{ textDecoration: 'none' }}
          >
            <Tooltip title={tooltipTitle} arrow>
              <Typography
                ref={dataRef}
                variant='body1'
                sx={{
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                  maxWidth: '29.313rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  wordBreak: 'break-all',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  display: '-webkit-box',
                }}
              >
                {item?.RepositoryUrl}
              </Typography>
            </Tooltip>
          </Link>
        }
        key={`${itemType}-repository-url`}
      />
    </>
  );
};
