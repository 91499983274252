type boxColorProp =
  | 'default'
  | 'light'
  | 'dark'
  | 'alt'
  | 'alt-dark'
  | 'none'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'alt-light';

type boxSizeProp =
  | 'none'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge';

export type BoxProps = {
  children?: React.ReactNode;
  background?: boxColorProp;
  rounded?: 'none' | 'all' | 'top' | 'bottom' | 'round';
  direction?: 'column' | 'row';
  border?: boxSizeProp | number;
  borderColor?: boxColorProp;
  borderStyle?: 'none' | 'solid' | 'dashed' | 'solid';
  padding?: boxSizeProp;
  gap?: boxSizeProp | number;
  alignItems?: 'start' | 'center' | 'end' | 'stretch';
  justifyContent?:
    | 'start'
    | 'center'
    | 'end'
    | 'space-between'
    | 'space-around';
  shrink?: boolean;
  blur?: boolean;
  shadow?: boolean;
  width?: number | string;
  height?: number | string;
} & React.HTMLAttributes<HTMLDivElement>;

//create super component for div with layout
export const Box = ({
  children,
  background = 'default',
  rounded = 'none',
  direction = 'column',
  border = 'none',
  borderColor = 'dark',
  borderStyle,
  padding = 'none',
  gap = 'none',
  width,
  height,
  alignItems,
  justifyContent,
  shrink,
  className,
  style,
  blur,
  shadow,
  ...props
}: BoxProps) => {
  let _className = `ecs-box ecs-background-${background} ecs-rounded-${rounded} ecs-box-${direction} ecs-padding-${padding} ecs-gap-${gap} ecs-border-${border} ecs-border-color-${borderColor} `;

  if (blur) _className += 'ecs-box-blur ';
  if (shadow) _className += 'ecs-box-shadow ';

  //can apply custom className
  _className = _className + (className ?? '');

  const _width = width && (typeof width === 'number' ? `${width}rem` : width);
  const _height =
    height && (typeof height === 'number' ? `${height}rem` : height);

  let _style = {
    ...(alignItems && { alignItems }),
    ...(justifyContent && { justifyContent }),
    ...(width && { width: _width }),
    ...(width && { flex: `0 0 auto` }),
    ...(height && { height: _height }),
    ...(height && { flex: `0 0 auto` }),
    ...(width && height && { flex: 'none' }),
    ...(style && style),
    ...(shrink && { flex: `0 0 auto` }),
    ...(typeof gap === 'number' && { gap: `${gap}rem` }),
    ...(typeof border === 'number' && { borderWidth: `${border}rem` }),
    ...(border && { borderStyle: borderStyle ?? 'solid' }),
  };

  return (
    <div className={_className} {...props} style={_style}>
      {children}
    </div>
  );
};
