import { useEffect, useRef } from "react";

const getFormValue = (IsCollection, propertyValue) => {
    if (IsCollection) {
      const formattedValues = propertyValue?.value?.map((value) => {
        const { ID, TypeDefId } = value;

        const ctProperties = Object.fromEntries(
          Object.entries(value).filter(([key]) => key.startsWith('CT_'))
        );

        return {
          ID,
          TypeDefId,
          "@enweb.Save": value['@enweb.Save'],
          ...ctProperties,
        };
      });

      return formattedValues || [];
    }

    return propertyValue;
};

export type FormDetailsObject = {
    BusinessObject: {
      ID: string;
      TypeDefId: string;
      [key: string]: any;
    };
    RepositoryId: string;
};

export const useBusinessTypePanel = ({ item, businessTypeProperties, businessTypePropertyValues }) => {
  const formDetailsRef = useRef<FormDetailsObject>({
      BusinessObject: {
          ID: "",
          TypeDefId: ""
      },
      RepositoryId: ""
  })

    useEffect(() => {
        if (!!item && !!businessTypeProperties && !!businessTypePropertyValues) {
            const formData = {
                BusinessObject: {
                    ID: item?.BusinessObject?.ID,
                    TypeDefId: item?.BusinessObject?.TypeDefId
                },
                RepositoryId: item?.RepositoryId
            }
          
            // get the editable fields
            businessTypeProperties?.PropDefs?.value?.forEach((formProperty) => {
                const propertyName = formProperty.Name;
                const IsCollection = formProperty.IsCollection;
                const propertyValue = businessTypePropertyValues[formProperty.Name];
                const formValue = getFormValue(IsCollection, propertyValue);

                // dynamically assign the property and value to formDataObj variable
                formData.BusinessObject[propertyName] = formValue;
            });

            formDetailsRef.current = formData;
        }
    }, [item, businessTypeProperties, businessTypePropertyValues]);

    return { formDetails: formDetailsRef.current };
}