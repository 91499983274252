import { ReactNode } from 'react';
import { Box, BoxProps } from './Box';
import { Typography } from '@mui/material';
import { ApplicationButton } from './ApplicationButton';
import { Application } from '../types/applicationTypes';
import { getLogoForApp } from '../util/appLogos';

export type AppsSectionProps = {
  title?: string;
  children?: ReactNode;
  background?: BoxProps['background'];
  style?: React.CSSProperties;
  applications: Application[];
};

export const AppsSection = ({
  title,
  children,
  background = 'none',
  style,
  applications,
  ...props
}: AppsSectionProps) => {
  return (
    <Box
      background={background}
      gap='xsmall'
      justifyContent='start'
      alignItems='start'
      style={{ justifyContent: 'start', flexGrow: 0 }}
      {...props}
    >
      <Box style={{ display: 'flex', alignSelf: 'start', background: 'none' }}>
        <Typography
          style={{
            padding: '0 0 0.625rem 0',
            fontSize: '0.875rem',
            fontWeight: 'normal',
          }}
        >
          {title}
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
            alignSelf: 'start',
            flexWrap: 'wrap',
            gap: '1rem',
          }}
        >
          {applications.map((app) => {
            return (
              <ApplicationButton
                name={app.DisplayName}
                key={app.DisplayName}
                size='small'
                Logo={getLogoForApp(app.DisplayName)}
                url={app.Homepage}
              ></ApplicationButton>
            );
          })}
        </div>
      </Box>
    </Box>
  );
};
