import {
  Add20Regular,
  ArrowClockwise20Regular,
  ChevronDownFilled,
  ColumnEdit20Regular,
  HandRight20Regular,
} from '@fluentui/react-icons';
import { Typography } from '@mui/material';
import { DataUtil } from '@syncfusion/ej2-data';
import {
  Column,
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Filter,
  Freeze,
  GridComponent,
  Inject,
  Page,
  Reorder,
  Resize,
  Sort,
  SortDirection,
  SortSettingsModel,
} from '@syncfusion/ej2-react-grids';
import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
  ApproverResponse,
  DisposalRequest,
  DisposalRequestResponseStatus,
  DisposalRequestResponseStatusCaption,
  DisposalRequestStatus,
  User,
} from '../../types';
import { Box } from '../Box';
import { DisposalRequestApprovers } from '../DisposalRequestApprovers';
import { Button } from '../button';
import { RetentionClassChip, UserChip } from '../chip';
import DetailDialog from '../dialog/DetailDialog';
import useData from './DataManagerHook';
import {
  OpenInContextMenu,
  OpenInContextMenuTarget,
} from './components/OpenInContextMenu';
import {
  CommentTemplate,
  PagerData,
  PagerTemplate,
  SummaryTemplate,
} from './templates';
import { ResponseCommentsTemplate } from './templates/ResponseCommentsTemplate';
import DetailTabDialog from '../dialog/DetailTabDialog';
import { TabPanelTab } from '../TabPanel';
import _ from 'lodash';
import { FilterSettingsModel } from '@syncfusion/ej2-react-treegrid';

export type DisposalRequestGridProps = {
  user?: User;
  data?: DisposalRequest[];
  filter?: DisposalRequestStatus | DisposalRequestResponseStatus;
  onOpen?: (request: DisposalRequest, target?: OpenInContextMenuTarget) => void;
  onNew?: () => void;
  onSelect?: (requests: DisposalRequest[]) => void;
  onRead?: (requests: DisposalRequest[]) => void;
  key?: string;
  pageSize?: number;
  pageSizes?: number[];
  title?: string;
  adminMode?: boolean;
};

export const DisposalRequestGrid = ({
  user,
  data,
  filter,
  onOpen,
  onSelect,
  onRead,
  pageSize = 30,
  pageSizes = [30, 60, 100],
  title,
  adminMode = false,
}: DisposalRequestGridProps) => {
  const gridRef = useRef(null);

  const [selected, setSelected] = useState<DisposalRequest[] | undefined>();

  const { dataManager } = useData({
    status: filter,
    user,
    gridData: data,
    adminMode,
  });

  const key = Math.random();
  const filterSettings: FilterSettingsModel = {
    type: 'Menu',
  };

  const [comment, setComment] = useState<string | null>(null);
  const [responseCommentTabs, setResponseCommentTabs] = useState<
    TabPanelTab[] | null
  >(null);

  const [contextAnchor, setContextAnchor] = useState<Element | null>(null);
  const [contextRequest, setContextRequest] = useState<
    DisposalRequest | undefined
  >();

  const dateFormat = { type: 'date', format: 'dd MMMM yyy' };

  //allow for multiple select in case it is used in future
  const handleSelect = () => {
    const _selected =
      gridRef?.current?.getSelectedRecords() as DisposalRequest[];

    setSelected(_selected);
    onSelect && onSelect(_selected);
  };

  useEffect(handleSelect, []);
  //TODO: Remove or add this pending business decision
  // useEffect(() => console.log('data', dataManager), []);

  const handleRead = () => {
    if (selected?.length && onRead) onRead(selected);
  };

  const showColumnChooser = () => {
    if (gridRef?.current) {
      gridRef.current.openColumnChooser(0, 0);
    }
  };

  //TODO: Remove or add this pending business decision
  // const filterTemplate = (props) => {
  //   console.log('props', props);
  //   const handleTest = () => {
  //     if (gridRef?.current) {
  //       gridRef.current.clearFiltering();
  //       console.log('gridRef.current', gridRef.current);

  //       // gridRef.current.filterByColumn('CustomerID', 'equal', '');
  //     }
  //   };
  //   // dropdata.push('Clear');
  //   /** The enabled attributes will be added based on the column property. */
  //   return (
  //     // <DropDownListComponent
  //     //   enabled={props.column.allowFiltering}
  //     //   id={props.column.field}
  //     //   popupHeight='250px'
  //     //   dataSource={dropdata}
  //     //   change={onChange}
  //     // />
  //     <div onClick={handleTest}>Hello</div>
  //   );
  // };
  const gridData = DataUtil.parse.parseJson(JSON.stringify(data));

  const getSortingOptions: () => SortSettingsModel | undefined = () => {
    switch (true) {
      case adminMode && filter === DisposalRequestStatus.New:
        return {
          columns: [
            {
              field: 'DateCreatedInEnc',
              direction: 'Descending' as SortDirection,
            },
          ],
        };
      case adminMode && filter === DisposalRequestStatus.InApproval:
        return {
          columns: [
            {
              field: 'DateRequested',
              direction: 'Descending' as SortDirection,
            },
          ],
        };
      case adminMode && filter === DisposalRequestStatus.Approved:
        return {
          columns: [
            {
              field: 'DateRequested',
              direction: 'Descending' as SortDirection,
            },
          ],
        };
      case filter === DisposalRequestResponseStatus.Delegated ||
        filter === DisposalRequestResponseStatus.Approved ||
        filter === DisposalRequestResponseStatus.Pending ||
        filter === DisposalRequestResponseStatus.Rejected:
        return {
          columns: [
            {
              field: 'DateRequested',
              direction: 'Descending' as SortDirection,
            },
          ],
        };
      default:
        return undefined;
    }
  };

  const sortingOptions = getSortingOptions();

  //column widths only work if they are numbers so they have been set to pixels
  const Grid = useMemo(
    () =>
      !!gridData || !!dataManager ? (
        <GridComponent
          key={key}
          dataSource={gridData ?? dataManager}
          allowResizing={true}
          enablePersistence={true}
          allowSorting={true}
          allowReordering={true}
          frozenColumns={1}
          enableStickyHeader={true}
          allowFiltering={true}
          rowSelected={handleSelect}
          sortSettings={sortingOptions}
          rowDeselected={handleSelect}
          showColumnChooser={true}
          width={'100%'}
          height={'100%'}
          //need the id to maintain persisted data
          id={`DisposalRequestGrid${adminMode ? '_admin' : ''}_${filter}`}
          data-testid={`DisposalRequestGrid${
            adminMode ? '_admin' : ''
          }_${filter}`}
          ref={gridRef}
          allowPaging={true}
          pageSettings={{ pageSize, pageSizes }}
          pagerTemplate={(pagerData: PagerData) => {
            const pagerDataWithTotalPages = {
              ...pagerData,
              totalPages: Math.ceil(
                pagerData.totalRecordsCount / pagerData.pageSize
              ),
            };
            return (
              <PagerTemplate
                key={key}
                pagerData={pagerDataWithTotalPages}
                grid={gridRef?.current}
                pageSizes={pageSizes}
              />
            );
          }}
          filterSettings={filterSettings}
        >
          <Inject
            services={[
              Resize,
              Sort,
              Freeze,
              Page,
              Reorder,
              ColumnChooser,
              Filter,
            ]}
          />
          <ColumnsDirective>
            <ColumnDirective
              field='Name'
              key='Name'
              headerText='Disposal Request'
              template={(request: DisposalRequest) => {
                let _information =
                  request.RequestedBy &&
                  `Requested by ${request.RequestedBy?.DisplayName}`;
                if (adminMode) {
                  if (
                    [
                      DisposalRequestStatus.InApproval,
                      DisposalRequestStatus.Approved,
                      DisposalRequestStatus.Rejected,
                    ].includes(filter as DisposalRequestStatus)
                  ) {
                  } else {
                    _information = undefined;
                  }
                }
                return (
                  <SummaryTemplate
                    adminMode={adminMode}
                    title={request.Name}
                    disposalId={request.ID}
                    information={_information}
                    disposalRequestStatus={request.Status}
                    isUnread={adminMode && !request.IsRead}
                    isDestructionActioned={
                      adminMode &&
                      (
                        filter === DisposalRequestStatus.InProgress ||
                        filter === DisposalRequestStatus.Complete
                      )
                    }
                    onClick={() => onOpen && onOpen(request)}
                    onContextMenu={(e: MouseEvent) => {
                      setContextRequest(request);
                      setContextAnchor(e.currentTarget);
                    }}
                  />
                );
              }}
              width={400}
              minWidth={200}
              allowReordering={false}
              showInColumnChooser={false}
            />

            {adminMode && filter !== DisposalRequestStatus.Failed && (
              <ColumnDirective
                field='RetentionClass.DisplayName'
                key={`RetentionClass_${filter}`}
                headerText='Retention Class'
                width={280}
                minWidth={180}
                template={(request: DisposalRequest) => (
                  <RetentionClassChip
                    classification={request.RetentionClass}
                    border={false}
                    style={{ marginTop: '-0.25rem' }}
                  />
                )}
                allowEditing={false}
              />
            )}

            {adminMode && (
              filter === DisposalRequestStatus.InProgress ||
              filter === DisposalRequestStatus.Complete
            ) && (
                <ColumnDirective
                  field='ActionedBy'
                  key='ActionedBy'
                  headerText='Actioned By'
                  width={280}
                  minWidth={200}
                  allowFiltering={false}
                  allowEditing={false}
                  template={(request: DisposalRequest) => {
                    const actionedById = request.ActionedById ?? '';
                    let approverName = '';
                    request.ApproverResponses.value.forEach((approvers) => {
                      if (approvers.ApproverId === actionedById)
                        approverName = approvers.Approver.DisplayName;
                    });
                    return (
                      <UserChip
                        user={
                          {
                            name: approverName,
                          } as User
                        }
                        border={false}
                        style={{
                          marginTop: '-0.25rem',
                          marginLeft: '-0.25rem',
                        }}
                      />
                    );
                  }}
                />
            )}

            {adminMode && filter === DisposalRequestStatus.Complete && (
              <ColumnDirective
                field='DateCompleted'
                key='DateCompleted'
                headerText='Date Completed'
                width={200}
                minWidth={100}
                type='date'
                format={dateFormat}
                allowEditing={false}
                allowSorting={false}
                allowFiltering={false}
              />
            )}

            {adminMode && filter === DisposalRequestStatus.New && (
              <ColumnDirective
                field='DateCreatedInEnc'
                key={`DateCreatedInEnc_${filter}`}
                headerText='Date Created'
                width={200}
                minWidth={100}
                type='date'
                format={dateFormat}
                allowEditing={false}
              />
            )}

            {adminMode &&
              [
                DisposalRequestStatus.InApproval,
                DisposalRequestStatus.Approved,
                DisposalRequestStatus.Rejected,
              ].includes(filter as DisposalRequestStatus) && [
                <ColumnDirective
                  type='string'
                  field='Approvers.Status'
                  key={`ApproverResponses_${filter}`}
                  headerText='Approvers'
                  width={280}
                  minWidth={200}
                  allowFiltering={false}
                  //TODO: Remove or add this pending business decision
                  // filterTemplate={filterTemplate}
                  template={(request: DisposalRequest) => (
                    <DisposalRequestApprovers
                      responses={request.ApproverResponses?.value}
                    />
                  )}
                  allowEditing={false}
                  allowSorting={false}
                />,
                <ColumnDirective
                  field='ApproverResponses.ResponseComments'
                  key={`ApproverResponseComments_${filter}`}
                  headerText='Response Comments'
                  width={400}
                  minWidth={200}
                  template={(request: DisposalRequest) => (
                    <ResponseCommentsTemplate
                      responses={request.ApproverResponses?.value}
                      onClick={(responses: ApproverResponse[]) =>
                        setResponseCommentTabs(
                          responses?.map(
                            (response: ApproverResponse) =>
                              ({
                                value: response.Approver?.DisplayName,
                                children: response.ResponseComments,
                              } as TabPanelTab)
                          )
                        )
                      }
                    />
                  )}
                  allowEditing={false}
                  allowSorting={false}
                  allowFiltering={false}
                />,
                filter === DisposalRequestStatus.InApproval ? (
                  <ColumnDirective
                    field='DateRequested'
                    key={`DateRequested_${filter}`}
                    headerText='Date Of Request'
                    width={200}
                    minWidth={100}
                    type='date'
                    format={dateFormat}
                    allowEditing={false}
                  />
                ) : (
                  <ColumnDirective
                    field='ApproverResponses.DateResponded'
                    key={`ApproverResponses.DateResponded_${filter}`}
                    headerText={`Date ${DisposalRequestResponseStatusCaption[filter]}`}
                    width={200}
                    minWidth={100}
                    valueAccessor={(
                      field: string,
                      request: DisposalRequest,
                      column: Column
                    ) => {
                      const date = request?.ApproverResponses?.value?.find(
                        (response) =>
                          (response.Status as unknown as DisposalRequestStatus) ===
                          request.Status
                      )?.DateResponded;
                      return date ?? '-';
                    }}
                    type='date'
                    format={dateFormat}
                    allowEditing={false}
                    allowSorting={false}
                    allowFiltering={false}
                  />
                ),
              ]}

            {!adminMode && (
              <ColumnDirective
                field='DateRequested'
                key={`DateRequested_${filter}`}
                headerText='Date Requested'
                width={200}
                minWidth={100}
                type='date'
                format={dateFormat}
                allowEditing={false}
              />
            )}

            {!adminMode &&
              filter !== DisposalRequestResponseStatus.Pending && [
                <ColumnDirective
                  field='Response.DateResponded'
                  key={`Response.DateResponded_${filter}`}
                  headerText={`Date ${DisposalRequestResponseStatusCaption[filter]}`}
                  width={200}
                  minWidth={100}
                  type='date'
                  format={dateFormat}
                  allowEditing={false}
                  allowSorting={false}
                  allowFiltering={false}
                />,
                <ColumnDirective
                  field='Response.ResponseComments'
                  key='Response.ResponseComments'
                  headerText={`${
                    filter === DisposalRequestResponseStatus.Delegated
                      ? 'Delegation'
                      : 'Response'
                  } Comments`}
                  width={400}
                  minWidth={200}
                  template={(request: DisposalRequest) => (
                    <CommentTemplate
                      variant='body1'
                      comment={request.Response?.ResponseComments}
                      onClick={setComment}
                      numberOfLines={4}
                    />
                  )}
                  allowEditing={false}
                  allowSorting={false}
                  allowFiltering={false}
                />,
              ]}

            {filter === DisposalRequestResponseStatus.Delegated && (
              <ColumnDirective
                field='DelegatedTo.DisplayName'
                key='DelegatedTo.DisplayName'
                headerText='Delegated To'
                width={200}
                minWidth={100}
                template={(request: DisposalRequest) => (
                  <UserChip
                    user={
                      {
                        name: request.DelegatedTo?.Approver?.DisplayName ?? '',
                      } as User
                    }
                    border={false}
                    sx={{ marginTop: '-0.25rem', marginLeft: '-0.25rem' }}
                  />
                )}
                allowEditing={false}
                allowSorting={false}
                allowFiltering={false}
              />
            )}
            {filter === DisposalRequestResponseStatus.Pending && (
              <ColumnDirective
                field='RequestComments'
                key='RequestComments'
                headerText='Request Comments'
                width={400}
                minWidth={200}
                template={(request: DisposalRequest) => (
                  <CommentTemplate
                    comment={request.RequestComments}
                    onClick={setComment}
                    numberOfLines={4}
                  />
                )}
                allowEditing={false}
              />
            )}

            {!adminMode &&
              filter !== DisposalRequestResponseStatus.Delegated && (
                <ColumnDirective
                  field='RequestedBy.DisplayName'
                  key='RequestedBy.DisplayName'
                  headerText='Requested By'
                  width={200}
                  minWidth={100}
                  template={(request: DisposalRequest) => (
                    <UserChip
                      user={
                        {
                          name: request.RequestedBy?.DisplayName ?? '',
                        } as User
                      }
                      border={false}
                      style={{ marginTop: '-0.25rem', marginLeft: '-0.25rem' }}
                    />
                  )}
                  allowEditing={false}
                />
              )}
          </ColumnsDirective>
        </GridComponent>
      ) : (
        <></>
      ),

    [gridData, dataManager, filter, sortingOptions]
  );

  return (
    //wrap with a box so that scrolling in the grid works

    <Box background='none' style={{ minHeight: '25rem' }}>
      <Box
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        background='none'
        height={6}
      >
        <Typography variant='h1'>{title}</Typography>

        <Box
          shrink
          direction='row'
          gap='xlarge'
          alignItems='center'
          background='none'
        >
          {/* TODO: remove comments if we need the read / unread button */}
          {/* Temporary Removal - Marking requests as read and unread using the button */}
          {/* https://encompaas.visualstudio.com/EncompaaS/_workitems/edit/58122 */}
          {/* {filter !== DisposalRequestStatus.Failed && (
            <Button
              variant='text'
              disabled={!selected?.length}
              onClick={() => handleRead()}
            >
              Read / unread
            </Button>
          )} */}
          {/* {adminMode && filter === DisposalRequestStatus.New && (
            <Button
              variant='text'
              startIcon={<Add20Regular />}
              onClick={() => onNew && onNew()}
            >
              New Request
            </Button>
          )} */}
          <Button
            variant='text'
            startIcon={<ColumnEdit20Regular />}
            endIcon={<ChevronDownFilled />}
            onClick={showColumnChooser}
          >
            Change Columns
          </Button>
        </Box>
      </Box>

      {Grid}

      <DetailDialog
        title='Comment'
        open={!!comment}
        onClose={() => setComment(null)}
      >
        {comment}
      </DetailDialog>

      <DetailTabDialog
        title={'Response Comments'}
        tabs={responseCommentTabs ?? []}
        height={40}
        width={54}
        open={!!responseCommentTabs}
        onClose={() => setResponseCommentTabs(null)}
      />

      <OpenInContextMenu
        anchor={contextAnchor}
        options={['new tab', 'new window']}
        onClose={() => {
          setContextAnchor(null);
        }}
        onOpen={(target) => {
          setContextAnchor(null);
          onOpen && contextRequest && onOpen(contextRequest, target);
        }}
      />
    </Box>
  );
};
export default DisposalRequestGrid;
