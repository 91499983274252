import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

type applicationStateType = {
  ui: {
    sideMenuCollapsed: boolean;
    sideMenuGroupCollapsedState?: boolean[];
    selectedSideMenuItemValue?: string | number;
  };
};

export const initialState: applicationStateType = {
  ui: {
    sideMenuCollapsed: false,
  },
} as applicationStateType;

export const applicationSlice = createSlice({
  name: 'application',
  initialState: initialState as applicationStateType,
  reducers: {
    setSideMenuCollapsed: (
      state: applicationStateType,
      action: PayloadAction<boolean>
    ) => {
      state.ui.sideMenuCollapsed = action.payload;
    },
    setSideMenuGroupCollapsedState: (
      state: applicationStateType,
      action: PayloadAction<boolean[]>
    ) => {
      state.ui.sideMenuGroupCollapsedState = action.payload;
    },
    setSelectedSideMenuItemValue: (
      state: applicationStateType,
      action: PayloadAction<string | number | undefined>
    ) => {
      state.ui.selectedSideMenuItemValue = action.payload;
    },
    reset: (state: applicationStateType) => {
      state = initialState;
    },
  },
});

export const {
  setSideMenuCollapsed,
  setSideMenuGroupCollapsedState,
  setSelectedSideMenuItemValue,
  reset,
} = applicationSlice.actions;

export default applicationSlice.reducer;
