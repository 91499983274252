import * as React from 'react';
import { SVGProps } from 'react';
const SvgCloseRequest24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g fill='currentColor'>
      <path d='M12.721 2.196a1.427 1.427 0 0 0-1.442 0l-8.573 5.02a1.426 1.426 0 0 0 .002 2.463l8.334 4.855c.196-.475.447-.922.746-1.333a1.425 1.425 0 0 1-.507-.178L3.427 8.446l7.852-4.597c.445-.261.997-.261 1.442 0l7.852 4.597-3.624 2.112h.032c.758 0 1.486.132 2.161.373l2.15-1.252c.943-.55.944-1.912.002-2.463l-8.573-5.02ZM2.115 11.514l8.482 4.772a6.486 6.486 0 0 0 .034 1.642l-7.923-4.616a1.426 1.426 0 0 1-.593-1.797ZM12.738 21.794a6.448 6.448 0 0 1-1.276-1.536 1.416 1.416 0 0 1-.162-.078l-9.185-5.167a1.426 1.426 0 0 0 .593 1.797l8.573 4.996c.445.259.993.259 1.438 0l.02-.012ZM21.03 11.995c.287.234.554.492.796.77.21-.384.23-.85.06-1.25l-.856.48Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.975 16.977a4.993 4.993 0 0 1-4.994 4.992 4.993 4.993 0 1 1 4.994-4.992Zm-3.143-1.145a.5.5 0 1 0-.706-.706l-1.145 1.145-1.145-1.145a.5.5 0 0 0-.706.706l1.145 1.145-1.145 1.144a.5.5 0 1 0 .706.706l1.145-1.144 1.145 1.144a.5.5 0 0 0 .706-.706l-1.145-1.144 1.145-1.145Z'
      />
    </g>
  </svg>
);
export default SvgCloseRequest24;
