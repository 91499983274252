import {
  BusinessTypeProperties,
  BusinessTypePropertyValues,
  DisposalRequest,
  Item,
  ItemIconValue,
  TreeGridProperties,
} from '../types';
import { Box } from './Box';
import {
  ItemBusinessTypePanel,
  ItemTypePanel,
  ItemGovernancePanel,
} from './item';
import { OpenInContextMenuTarget } from './grid/components/OpenInContextMenu';

export type ItemSummaryPanelProps = {
  item: Item;
  hasEditPermission?: boolean;
  disposalRequest?: DisposalRequest;
  treeGridProperties: TreeGridProperties;
  businessTypeIconDetails?: ItemIconValue;
  businessTypeProperties?: BusinessTypeProperties;
  businessTypePropertyValues?: BusinessTypePropertyValues;
  onOpen?: (item: Item, target?: OpenInContextMenuTarget) => void;
};

export const ItemSummaryPanel = ({
  item,
  disposalRequest,
  hasEditPermission,
  treeGridProperties,
  businessTypeProperties,
  businessTypeIconDetails,
  businessTypePropertyValues,
  onOpen,
}: ItemSummaryPanelProps) => {
  return (
    <Box
      padding='none'
      background='none'
      direction='row'
      gap='none'
      height={'100%'}
    >
      <Box
        background='none'
        width='100%'
        style={{ overflow: 'overlay', paddingRight: '1rem', overflowY: 'auto' }}
      >
        <ItemBusinessTypePanel
          item={item}
          hasEditPermission={hasEditPermission}
          disposalRequest={disposalRequest}
          treeGridProperties={treeGridProperties}
          businessTypeProperties={businessTypeProperties}
          businessTypeIconDetails={businessTypeIconDetails}
          businessTypePropertyValues={businessTypePropertyValues}
        />
        <ItemTypePanel item={item} />
        <ItemGovernancePanel
          item={item}
          disposalRequest={disposalRequest}
          hasEditPermission={hasEditPermission}
          onOpen={onOpen}
        />
      </Box>
    </Box>
  );
};
