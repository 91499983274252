import { Note16Regular } from '@fluentui/react-icons';
import { MouseEvent, useEffect, useState } from 'react';
import {
  ApproverResponse,
  DisposalRequestResponseStatus,
} from '../../../types';
import { Box, BoxProps } from '../../Box';
import { CommentText } from '../../CommentText';
import { TextIconButton } from '../../button';

export type ResponseCommentsTemplateProps = {
  responses?: ApproverResponse[];
  onClick?: (responses: ApproverResponse[]) => void;
} & Omit<BoxProps, 'onClick'>;

export const ResponseCommentsTemplate = ({
  responses,
  onClick,
  ...boxprops
}: ResponseCommentsTemplateProps) => {
  const [commentResponses, setCommentResponses] = useState<
    ApproverResponse[] | undefined
  >();

  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    const _responses = responses?.filter(
      (response: ApproverResponse) =>
        [
          DisposalRequestResponseStatus.Approved,
          DisposalRequestResponseStatus.Rejected,
          DisposalRequestResponseStatus.Delegated,
        ].includes(response.Status) &&
        (response.ResponseComments?.length ?? 0) > 0
    );
    setCommentResponses(_responses);
    setLength(_responses?.length ?? 0);
  }, [responses]);

  return (
    <Box
      background='none'
      alignItems='start'
      justifyContent='start'
      style={{
        wordWrap: 'break-word',
        whiteSpace: 'break-spaces',
      }}
      {...boxprops}
    >
      {length === 0 && <></>}
      {length === 1 && (
        <CommentText
          comment={responses[0]?.ResponseComments}
          variant='body2'
          numberOfLines={5}
          onClick={onClick ? () => onClick(commentResponses) : undefined}
        />
      )}
      {length > 1 && (
        <TextIconButton
          color='info'
          hoverColor='primary'
          textVariant='body2'
          startIcon={<Note16Regular />}
          onClick={
            onClick
              ? (e: MouseEvent) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClick(commentResponses);
                }
              : undefined
          }
          text={`${length} Comments`}
          sx={{ marginTop: '-0.5rem' }}
        />
      )}
    </Box>
  );
};
