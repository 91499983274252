import { Tooltip, Typography as MUITypography } from '@mui/material';
import { ReactNode } from 'react';
import { useTooltip } from '../hooks';

type MuiTypographyProps = React.ComponentProps<typeof MUITypography>;

export type TypographyProps = {
  children?: ReactNode;
  WebkitLineClamp?: number;
} & Omit<MuiTypographyProps, 'children'>;

export const Typography = ({
  sx,
  children,
  WebkitLineClamp = 1,
  ...props
}: TypographyProps) => {
  const content = typeof children === 'string' ? children : '';

  const { dataRef, title } = useTooltip(content);

  return (
    <Tooltip title={title} arrow>
      <MUITypography
        {...props}
        ref={dataRef}
        sx={{
          width: 'auto',
          WebkitLineClamp,
          overflow: 'hidden',
          wordBreak: 'break-all',
          display: '-webkit-box',
          textOverflow: 'ellipsis',
          whiteSpace: 'break-spaces',
          WebkitBoxOrient: 'vertical',
          ...sx,
        }}
      >
        {children}
      </MUITypography>
    </Tooltip>
  );
};
