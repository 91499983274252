import * as React from 'react';
import { SVGProps } from 'react';
const SvgReadyToDestroy24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g fill='currentColor'>
      <path d='M10 5h4a2 2 0 1 0-4 0ZM8.5 5a3.5 3.5 0 1 1 7 0h5.75a.75.75 0 0 1 0 1.5h-1.32L18.76 18.611A3.75 3.75 0 0 1 15.026 22H8.974a3.75 3.75 0 0 1-3.733-3.389L4.07 6.5H2.75a.75.75 0 0 1 0-1.5H8.5ZM6.734 18.467a2.25 2.25 0 0 0 2.24 2.033h6.052a2.25 2.25 0 0 0 2.24-2.033L18.424 6.5H5.576l1.158 11.967Z' />
      <path d='M15.863 10.16a.612.612 0 0 1 0 .771l-4.2 4.91a.42.42 0 0 1-.66 0l-1.866-2.183a.613.613 0 0 1 0-.771.42.42 0 0 1 .66 0l1.536 1.796 3.87-4.523a.42.42 0 0 1 .66 0Z' />
    </g>
  </svg>
);
export default SvgReadyToDestroy24;
