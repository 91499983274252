import {
  DisposalRequestStatus,
  DisposalRequestStatusCaption,
} from '../../types';
import { ChipProps } from './Chip';
import { Indicator, IndicatorProps } from './Indicator';

export type DisposalRequestStatusIndicatorProps = {
  status: DisposalRequestStatus;
} & Omit<IndicatorProps, 'label' | 'color'>;

export const DisposalRequestStatusIndicator = ({
  status,
  ...props
}: DisposalRequestStatusIndicatorProps) => {
  // can make these global if needed
  const _color: Record<DisposalRequestStatus, ChipProps['color']> = {
    [DisposalRequestStatus.New]: 'default',
    [DisposalRequestStatus.InApproval]: 'default',
    [DisposalRequestStatus.Approved]: 'primary',
    [DisposalRequestStatus.Rejected]: 'warning',
    [DisposalRequestStatus.InProgress]: 'default',
    [DisposalRequestStatus.Complete]: 'default',
    [DisposalRequestStatus.InProgressOrComplete]: 'default',
    [DisposalRequestStatus.Failed]: 'error',
    [DisposalRequestStatus.ItemDestroyInProgress]: 'primary',
    [DisposalRequestStatus.ItemDestroyComplete]: 'primary',
  };

  return (
    <Indicator
      {...props}
      label={DisposalRequestStatusCaption[status]}
      color={_color[status]}
    />
  );
};
