import { ItemTypePropertiesValue } from '../../types';
import { getFormattedDateTime } from '../../util/dateTime';

export const isBusinessType = (propertyValue) =>
  propertyValue?.hasOwnProperty('IsBusinessType') &&
  propertyValue?.IsBusinessType;
export const isPerspectiveClass = (propertyValue) =>
  propertyValue?.hasOwnProperty('_Display') &&
  propertyValue?.hasOwnProperty('FullPath') &&
  (propertyValue?.hasOwnProperty('_ImageId') ||
    propertyValue?.hasOwnProperty('TypeDefId'));
export const hasDisplayProperty = (propertyValue) =>
  propertyValue?.hasOwnProperty('_Display');
export const hasRetentionClasses = (propertyValue) =>
  propertyValue?.hasOwnProperty('value');
export const isStringALink = (str) => {
  try {
    const url = new URL(str);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (error) {
    return false;
  }
};

export const transformPropertyName = (str) => {
  if (!str.startsWith('CT_')) return;
  // Remove "CT_" prefix
  const withoutPrefix = str.substring(3);

  // Remove the index number at the end
  const withoutIndex = withoutPrefix.substring(
    0,
    withoutPrefix.lastIndexOf('_')
  );

  // Replace underscores with spaces
  const transformedString = withoutIndex.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  const capitalizedString = transformedString.replace(/\b\w/g, (match) =>
    match.toUpperCase()
  );

  return capitalizedString;
};

export const getItemTypeProperties = (item: ItemTypePropertiesValue, propLinks: any) => {

  const refPropLinks = propLinks?.value.filter((obj) => obj?.PropDef?.IsRefProp === true);

  const findPropertyValue = (obj, propName, propDef) => {
    for (let key in obj) {
      if (key === propName) {
        return processDataToRender(propDef, obj[key]);
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        const propValue = findPropertyValue(obj[key], propName, propDef);
        if (propValue !== undefined) {
          if (propValue) {
            return processDataToRender(propDef, propValue);
          }
        }
      }
    }
    return null;
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (isNaN(bytes)) return bytes;

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  const processDataToRender = (propDef, propValue) => {
    if (propDef?.TypeKind === 'Primitive') {
      switch (propDef?.PrimitiveDataType) {
        case 'Date':
        case 'TimeOfDay':
        case 'DateTimeOffset':
          return getFormattedDateTime(propValue);
        case 'Boolean':
          return propValue ? 'true' : 'false';
        default:
          return propDef?.Name === 'FileSize' ? formatBytes(propValue) : propValue;
      }
    }

    if (propDef?.TypeKind === 'Enum') {
      return propValue;
    }

    if (propDef.TypeKind === 'Entity') {
      return propValue;
    }
  }

  const gridData = refPropLinks?.map(link => {
    if (link.PropDef?.PropTypeDef?.Name !== 'IContent') {
      const propertyName = link?.PropDef?.Caption;
      const propNameToSearch = link?.PropDef?.Name;
      const value = findPropertyValue(item, propNameToSearch, link?.PropDef);
      return { propertyName, value, highlights: '', propDef: link?.PropDef };
    }
  });

  const removeDuplicateObjects = (arr) => {
    const uniqueObjects = [];
    const seenPropertyNames = new Set();

    for (const obj of arr) {
      if (!seenPropertyNames.has(obj?.propertyName)) {
        uniqueObjects.push(obj);
        seenPropertyNames.add(obj?.propertyName);
      }
    }

    return uniqueObjects;
  }
  return gridData?.length ? removeDuplicateObjects(gridData?.filter(item => item)) : [];
};

export const calculateMenuItemWidth = (item) => {
  // Create a temporary hidden element to calculate the width
  const tempElement = document.createElement('span');
  tempElement.style.position = 'absolute';
  tempElement.style.visibility = 'hidden';
  tempElement.innerText = item.label;

  // Append the temporary element to the body
  document.body.appendChild(tempElement);

  // Get the calculated width
  const width = tempElement.offsetWidth;

  // Remove the temporary element from the body
  document.body.removeChild(tempElement);

  return width;
};

export const sortPropertiesByName = (properties) =>
  properties?.sort(function (a, b) {
    var propertyNameA = a.propertyName.toUpperCase(); // Convert name to uppercase for case-insensitive comparison
    var propertyNameB = b.propertyName.toUpperCase();

    if (propertyNameA < propertyNameB) {
      return -1;
    }
    if (propertyNameA > propertyNameB) {
      return 1;
    }
    return 0; // Property Names are equal
  });

export const getParentNameFromObject = (obj) => {
  // If propDef property is not found, recursively call the function on the parentItem property
  if (obj?.parentItem) {
    return getParentNameFromObject(obj?.parentItem);
  }

  // Check if the object has a propDef property
  if (obj?.propDef && obj?.propDef.Name) {
    return obj?.propDef?.Name;
  }

  // Return null if the property is not found
  return null;
};
