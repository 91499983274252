import {
  Button as MuiButton,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
  Typography as MuiTypography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Button, ButtonProps } from './Button';

type MuiButtonProps = React.ComponentProps<typeof MuiButton>;
type MuiTypographyProps = React.ComponentProps<typeof MuiTypography>;

//general purpose texticon button
// will display as square if only one icon present
// additional variant 'white' overrides MUI theme to display white text

export type TextIconButtonProps = {
  text?: string;
  textVariant?: MuiTypographyProps['variant'];
  hoverVariant?: 'underline' | 'highlight';
  textFontSize?: MuiTypographyProps['fontSize'];
  color?: MuiButtonProps['color'] | 'white';
  hoverColor?: MuiButtonProps['color'] | 'white';
} & Omit<ButtonProps, 'color' | 'variant' | 'children'>;

/**
 * TextIconButton - general purpose texticon button
 * @param text text to display
 * @param textVariant variant of Typography text component that displays the text
 * @param hoverVariant variant of Typography text component that displays the text
 * @param textFontSize fontSize of Typography text component that displays the text, set to undefined to use variant fontSize
 * @param color color variant of the text
 * @param hoverColor hover color variant of the text, overrides MUI hover
 * @extends Button
 */
export const TextIconButton = ({
  text,
  textVariant = 'button',
  textFontSize,
  color,
  hoverVariant = 'highlight',
  hoverColor,
  startIcon,
  endIcon,
  sx,
  onClick,
  ...props
}: TextIconButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      color={color === 'white' ? 'info' : color}
      variant='text'
      shape={text || (startIcon && endIcon) ? 'default' : 'square'}
      startIcon={startIcon && startIcon}
      endIcon={endIcon && endIcon}
      onClick={onClick && onClick}
      disableRipple={!onClick}
      sx={{
        background: 'none',
        cursor: onClick ? 'pointer' : 'default',
        ...(color === 'white' && {
          color: theme.palette.common.white,
          '&:hover':
            hoverVariant == 'highlight'
              ? {
                  background: 'rgba(255,255,255,0.2)',
                }
              : {
                  background: 'none',
                  textDecoration: 'underline',
                },
        }),
        ...(!onClick && { '&:hover': { background: 'none' } }),
        ...(onClick &&
          hoverColor && {
            '&:hover':
              hoverVariant == 'highlight'
                ? {
                    background: 'none',
                    color:
                      theme.palette[hoverColor]?.main ??
                      theme.palette.primary.main,
                  }
                : {
                    background: 'none',
                    textDecoration: 'underline',
                  },
          }),
        ...sx,
      }}
      {...props}
    >
      {text && (
        <MuiTypography
          variant={textVariant}
          color='inherit'
          noWrap
          fontSize={textFontSize}
          textTransform='none'
        >
          {text}
        </MuiTypography>
      )}
    </Button>
  );
};
