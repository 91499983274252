import { ChevronDownFilled, ChevronUpFilled } from '@fluentui/react-icons';
import { IconButton, InputAdornment, TextField, useTheme } from '@mui/material';

type NumberTextFieldProps = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  handleIncrement: () => void;
  handleDecrement: () => void;
};

export const NumberTextField = ({
  value,
  onChange,
  handleIncrement,
  handleDecrement,
}: NumberTextFieldProps) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  return (
    <TextField
      sx={{
        width: '100%',
        maxWidth: '21.75rem',
        height: '3rem',

        '& input[type=number]': {
          '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '-moz-appearance': 'textfield', // Firefox
        },
      }}
      value={value}
      onChange={onChange}
      type='number'
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton edge='end' onClick={handleIncrement}>
              <ChevronUpFilled style={{ color }} />
            </IconButton>
            <IconButton edge='end' onClick={handleDecrement}>
              <ChevronDownFilled style={{ color }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
