
import {

  useGetItemImageQuery,
} from '../services';

export const useItemImage = ({ id }: { id?: string }) => {
  const { data: itemImage, isLoading: isFetchingItemImage } =
    useGetItemImageQuery(
      {
        itemId: id,
      },
      { skip: !id }
    );

  return {
    itemImage,
    isFetching:
      isFetchingItemImage,
  };
};
