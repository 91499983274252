import {
  ChevronDown20Regular,
  ChevronUp20Regular,
} from '@fluentui/react-icons';
import { ListItemButton, Typography } from '@mui/material';
import { memo } from 'react';
import { useIcon } from '../hooks';
import { CatalogNodeChildrenValue } from '../types';
import { getOptionIcon } from './CollapsibleGroupedItem';

interface CollapsibleGroupedParentItem {
  groupName: string;
  expandedGroups: any[];
  handleGroupToggle: (group: string) => void;
  businessCatalogNodeValues?: CatalogNodeChildrenValue[];
}

export const CollapsibleGroupedParentItem = memo(
  ({
    groupName,
    expandedGroups,
    handleGroupToggle,
    businessCatalogNodeValues,
  }: CollapsibleGroupedParentItem) => {
    // Get parent details
    // we can get image id and other details here.
    const parentDetails = businessCatalogNodeValues?.find(
      (parent) => parent._Display === groupName
    );

    const { iconDetails } = useIcon({
      imageId: parentDetails?._ImageId,
    });

    return (
      <ListItemButton
        onClick={() => handleGroupToggle(groupName)}
        sx={{ gap: 0.625 }}
      >
        {expandedGroups?.includes(groupName) ? (
          <ChevronDown20Regular style={{ color: '#212121' }} />
        ) : (
          <ChevronUp20Regular style={{ color: '#212121' }} />
        )}
        {getOptionIcon(iconDetails)}
        <Typography variant='body2'>{groupName ?? 'All'}</Typography>
      </ListItemButton>
    );
  }
);
