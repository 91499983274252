import { Chip as MuiChip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Tooltip } from '../Tooltip';
import { palette } from '../../themes';

type MuiChipProps = React.ComponentProps<typeof MuiChip>;

export type IndicatorProps = {
  label: string;
  isRejected?: boolean;
} & Omit<MuiChipProps, 'label' | 'children' | 'icon'>;

export const Indicator = ({
  label,
  size,
  variant,
  color = 'default',
  sx,
  isRejected = false,
  ...props
}: IndicatorProps) => {
  const dataRef = useRef(null);

  const [hasTooltip, setHasTooltip] = useState(false);

  const toggleTooltip = () => {
    if (!dataRef.current) return;
    const labelElement = dataRef.current.querySelector('.MuiChip-label');

    const clientWidth = labelElement.clientWidth;
    const scrollWidth = labelElement.scrollWidth;

    if (scrollWidth > clientWidth) setHasTooltip(true);
    else setHasTooltip(false);
  };

  useEffect(() => {
    toggleTooltip();
    window.addEventListener('resize', toggleTooltip);
    return () => window.removeEventListener('resize', toggleTooltip);
  }, [label]);

  const getIndicatorStyling = () => {
    if (color === 'primary')
      return {
        ...sx,
        backgroundColor: palette.aqua[800],
        color: palette.purewhite,
      };
    else if (color === 'warning' && isRejected)
      return {
        ...sx,
        backgroundColor: palette.juice[900],
        color: palette.purewhite,
      };
    else
      return {
        ...sx,
      };
  };

  return (
    <Tooltip title={hasTooltip ? label : ''} arrow>
      <MuiChip
        {...props}
        sx={getIndicatorStyling()}
        clickable={!!props.onClick}
        onDelete={undefined}
        label={label}
        color={color}
        variant='filled'
        size='small'
        ref={dataRef}
      />
    </Tooltip>
  );
};
