import {
  AddCircle24Regular,
  CompassNorthwest24Regular,
  Delete24Regular,
} from '@fluentui/react-icons';
import { memo } from 'react';
import { RetentionClass24 } from '../../icons';
import { ItemManagementStatus } from '../../types';
import { Chip, ChipProps } from './Chip';

export type ItemManagementStatusChipProps = {
  status: ItemManagementStatus;
} & Omit<ChipProps, 'label' | 'icon' | 'color'>;

export const ItemManagementStatusChip = ({
  status,
  border = false,
  ...props
}: ItemManagementStatusChipProps) => {
  // can make these global if needed
  const _icon = {
    [ItemManagementStatus.Discovered]: <CompassNorthwest24Regular />,
    [ItemManagementStatus.Managed]: <RetentionClass24 />,
    [ItemManagementStatus.Disposed]: <Delete24Regular />,
    [ItemManagementStatus.Destroyed]: <Delete24Regular />,
    [ItemManagementStatus.Created]: <AddCircle24Regular />,
    [ItemManagementStatus.None]: <></>,
  };

  return (
    <Chip
      {...props}
      border={border}
      title={ItemManagementStatus[status]}
      icon={_icon[status]}
    />
  );
};

export const MemoizedItemManagementStatusChip = memo(ItemManagementStatusChip);
