import { DocumentHeaderFooter24Filled } from '@fluentui/react-icons';
import { Avatar } from '@mui/material';
import React from 'react';

type MuiAvatarProps = React.ComponentProps<typeof Avatar>;

export type ImageWithFallbackProps = {
  size?: 'default' | 'large';
} & MuiAvatarProps;

export const ImageWithFallback = ({
  variant = 'square',
  children,
  size = 'default',
  color = 'none',
  sx,
  ...props
}: ImageWithFallbackProps) => {
  return (
    <Avatar
      variant={variant}
      {...props}
      className='MuiAvatar-item'
      sx={{
        color: 'unset',
        background: 'none',
        minWidth: '1.5rem',
        maxWidth: '1.5rem',
        minHeight: '1.5rem',
        maxHeight: '1.5rem',
        ...(size === 'large' && {
          minWidth: '2rem',
          maxWidth: '2rem',
          minHeight: '2rem',
          maxHeight: '2rem',
        }),
        ...sx,
      }}
    >
      {children ?? <DocumentHeaderFooter24Filled />}
    </Avatar>
  );
};
