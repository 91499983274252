import {
  ArrowCurveUpLeft20Filled,
  ChevronLeftFilled,
  ChevronRightFilled,
  DismissCircle20Regular,
  DismissFilled,
  Edit20Regular,
  Grid20Filled,
  HomeRegular,
  QuestionCircleRegular,
} from '@fluentui/react-icons';
import { IconButton as MuiIconButton, useTheme } from '@mui/material';
import React from 'react';
import { CollapseLeft24, ExpandLeft24, History24 } from '../../icons';
import { TextIconButton, TextIconButtonProps } from './TextIconButton';

type MuiIconButtonProps = React.ComponentProps<typeof MuiIconButton>;

//prebuilt buttons
export type shapedIconButtonProps = MuiIconButtonProps & {
  shape?: 'square';
  border?: boolean;
};
export const ShapedCloseButton = ({
  shape,
  border,
  ...props
}: shapedIconButtonProps) => {
  const theme = useTheme();
  const borderColor = theme.palette.info[600];
  const buttonStyle =
    shape === 'square'
      ? { borderRadius: '8px', border: `1px solid ${borderColor}` }
      : {};

  return (
    <MuiIconButton {...props} sx={buttonStyle} color='info'>
      <DismissFilled></DismissFilled>
    </MuiIconButton>
  );
};

export const CloseButton = (props: MuiIconButtonProps) => {
  return (
    <MuiIconButton {...props}>
      <DismissFilled />
    </MuiIconButton>
  );
};

export const PreviousButton = (props: MuiIconButtonProps) => {
  return (
    <MuiIconButton {...props}>
      <ChevronLeftFilled></ChevronLeftFilled>
    </MuiIconButton>
  );
};

export const NextButton = (props: MuiIconButtonProps) => {
  return (
    <MuiIconButton {...props}>
      <ChevronRightFilled />
    </MuiIconButton>
  );
};

export const AboutButton = (props: MuiIconButtonProps) => {
  return (
    <MuiIconButton {...props}>
      <QuestionCircleRegular />
    </MuiIconButton>
  );
};

export const GridButton = (props: MuiIconButtonProps) => {
  const theme = useTheme();
  return (
    <MuiIconButton
      size='medium'
      color='info'
      sx={{
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info[600],
        borderRadius: '0.75rem',
        '&:hover': {
          bgcolor: theme.palette.common.white,
          color: '#57F7FE',
        },
      }}
      {...props}
    >
      <Grid20Filled />
    </MuiIconButton>
  );
};

export const MenuButton = (props: MuiIconButtonProps) => {
  return (
    <MuiIconButton {...props}>
      <h2>⋮</h2>
    </MuiIconButton>
  );
};

export const EditButton = (props: MuiIconButtonProps) => {
  return (
    <MuiIconButton color='primary' {...props}>
      <Edit20Regular />
    </MuiIconButton>
  );
};

export const DeleteButton = (props: MuiIconButtonProps) => {
  return (
    <MuiIconButton {...props}>
      <DismissCircle20Regular />
    </MuiIconButton>
  );
};

export const CollapseButton = ({
  direction = 'left',
  color = 'primary',
  ...props
}: { direction?: 'left' | 'right' } & React.ComponentProps<
  typeof MuiIconButton
>) => {
  return (
    <MuiIconButton color={color} {...props} aria-label='collapse'>
      {direction === 'left' ? <CollapseLeft24 /> : <ExpandLeft24 />}
    </MuiIconButton>
  );
};

export const RefreshButton = ({
  indicator = false,
  color = 'primary',
  ...props
}: { indicator?: boolean } & MuiIconButtonProps) => {
  //todo: add an indicator overlay
  return (
    <MuiIconButton color={color} {...props} aria-label='refresh'>
      <History24 />
    </MuiIconButton>
  );
};

export const HomeButton = (props: TextIconButtonProps) => (
  <TextIconButton startIcon={<HomeRegular />} textVariant='body2' {...props} />
);

export const ParentNavigationButton = (props: TextIconButtonProps) => (
  <TextIconButton
    startIcon={<ArrowCurveUpLeft20Filled />}
    textVariant='body2'
    {...props}
  />
);
