import { useState } from 'react';

export type AlertState = {
  isOpen: boolean;
  title: string;
  message: string;
};

export type AlertActions = {
  setTitle: (title: string) => void;
  setMessage: (message: string) => void;
  openAlert: () => void;
  closeAlert: () => void;
};

export const useAlert = (initialOpen: boolean): AlertState & AlertActions => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const openAlert = () => setIsOpen(true);
  const closeAlert = () => setIsOpen(false);

  return {
    isOpen,
    title,
    message,
    setTitle,
    setMessage,
    openAlert,
    closeAlert,
  };
};

