import { Item, User } from '../../../types';
import { DetailsRow } from '../../DetailsRow';
import { Chip, UserChip } from '../../chip';
import { Box } from '../../Box';
import styled from '@emotion/styled';
import { useGetEmailCopyQuery } from '../../../services';
import { AttachedItems } from './components/AttachedItems';

export type ItemTypeEmailPanelProps = {
  item: Item;
};

const StyledImage = styled('img')``;

export const ItemTypeEmailPanel = ({ item }: ItemTypeEmailPanelProps) => {
  const itemType = item?.TypeDef?._Display;

  const { data: emailCopy } = useGetEmailCopyQuery({ itemId: item.ID });

  // TODO: Refactor this component, make the property names dynamic.
  return (
    <>
      <DetailsRow
        label='Name'
        data={item?.DisplayName}
        key={`${itemType}-name`}
      />
      <DetailsRow
        label='Subject'
        data={item?.Subject}
        key={`${itemType}-subject`}
      />
      <DetailsRow
        label='From'
        data={
          item?.From?.DisplayName ? (
            <UserChip user={{ name: item?.From?.DisplayName } as User} />
          ) : null
        }
        key={`${itemType}-from`}
      />
      <DetailsRow
        label='To'
        data={
          <Box background='none' direction='column'>
            {item?.ToRecipients?.value?.map((user) => (
              <UserChip
                user={{ name: user?.DisplayName } as User}
                sx={{ marginBottom: '0.5rem' }}
              />
            ))}
          </Box>
        }
        key={`${itemType}-to`}
      />
      <DetailsRow
        label='CC'
        data={
          <Box background='none' direction='column'>
            {item?.CCRecipients?.value?.map((user) => (
              <UserChip
                user={{ name: user?.DisplayName } as User}
                sx={{ marginBottom: '0.5rem' }}
              />
            ))}
          </Box>
        }
        key={`${itemType}-cc`}
      />
      <DetailsRow
        label='BCC'
        data={
          <Box background='none' direction='column'>
            {item?.BCCRecipients?.value?.map((user) => (
              <UserChip
                user={{ name: user?.DisplayName } as User}
                sx={{ marginBottom: '0.5rem' }}
              />
            ))}
          </Box>
        }
        key={`${itemType}-bcc`}
      />
      <DetailsRow
        label='Attachments'
        data={
          <Box background='none' direction='column'>
            {item?.AttachedItems?.value?.map((item) => (
              <AttachedItems item={item} />
            ))}
          </Box>
        }
        key={`${itemType}-attachments`}
      />
      <DetailsRow
        label='Copies'
        data={
          <Box background='none' direction='column'>
            {emailCopy?.value?.map((item) => {
              const parentFolder = item?.ParentFolder?.DisplayName;
              const emailDetails =
                parentFolder === 'Inbox'
                  ? item?.Mailbox?.Owner
                  : item?.Email.From;

              return (
                <Box
                  background='none'
                  width='100%'
                  style={{
                    maxWidth: 'fit-content',
                  }}
                >
                  <Chip
                    icon={
                      <StyledImage
                        src={`${process.env.REACT_APP_ENC_IMC_URL}email_1.svg`}
                        style={{ height: '1.5rem' }}
                      />
                    }
                    title={emailDetails?.DisplayName ?? 'No email address'}
                    subTitle={`${parentFolder} (${
                      emailDetails?.Mail ?? 'No email address'
                    })`}
                    sx={{ justifyContent: 'start', marginBottom: '0.5rem' }}
                  />
                </Box>
              );
            })}
          </Box>
        }
        key={`${itemType}-copies`}
      />
    </>
  );
};
