import { TabContext as MUITabContext, TabPanel as MUITabPanel } from '@mui/lab';
import { Divider, Tab as MUITab, Tabs as MUITabs } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { Box } from './Box';

export type TabPanelTab = {
  value: string;
  title?: string;
  children?: ReactNode;
};

export type TabPanelProps = {
  selected?: string;
  tabs?: TabPanelTab[];
  inset?: string | number;
  divider?: boolean;
  onSelect?: (tab: string) => void;
};

export const TabPanel = ({
  selected,
  tabs = [],
  inset = 0,
  divider = true,
  onSelect,
}: TabPanelProps) => {
  const [_selected, setSelected] = useState<string | undefined>(
    tabs?.[0]?.value
  );

  //selected can be controlled by parent
  useEffect(() => {
    setSelected(selected ?? tabs?.[0]?.value);
  }, [selected, tabs]);

  const handleSelect = (value: string) => {
    setSelected(value);
    onSelect && onSelect(value);
  };

  return (
    <Box background='none'>
      <Box
        background='none'
        height={2}
        justifyContent='end'
        style={{ paddingLeft: inset }}
      >
        <MUITabs
          value={_selected ?? false}
          onChange={(e, value) => handleSelect(value)}
        >
          {tabs?.map((tab, index) => (
            <MUITab
              key={index}
              label={tab.title ?? tab.value}
              value={tab.value}
            />
          ))}
        </MUITabs>
      </Box>
      {divider && <Divider style={{ width: '100%' }} />}
      <Box background='light'>
        <MUITabContext value={_selected ?? ''}>
          {tabs?.map((tab, index) => (
            <MUITabPanel
              key={index}
              value={tab.value}
              sx={{
                padding: 0,
                overflow: 'hidden',
                height: '100%',
              }}
            >
              {tab.children}
            </MUITabPanel>
          ))}
        </MUITabContext>
      </Box>
    </Box>
  );
};
