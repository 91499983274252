import {
  ChevronDoubleLeftFilled,
  ChevronDoubleRightFilled,
  ChevronLeftFilled,
  ChevronRightFilled,
} from '@fluentui/react-icons';
import { Typography, useTheme } from '@mui/material';
import { memo } from 'react';
import { Box } from '../../Box';
import { Button } from '../../button';

export const PageSelector = ({
  currentPage,
  totalPages,
  pagesToShow = 5,
  onSelect,
}: {
  currentPage: number;
  totalPages: number;
  pagesToShow?: number;
  onSelect?: (page: number) => void;
}) => {
  const theme = useTheme();

  const moveToPage = (page: number) => onSelect && onSelect(page);

  const pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1).filter(
    (i) =>
      i === 1 ||
      i === totalPages ||
      (i >= currentPage - pagesToShow && i <= currentPage + pagesToShow)
  );

  if (totalPages <= 1) return <></>;

  return (
    <Box
      background='none'
      direction='row'
      alignItems='center'
      justifyContent='center'
      data-testid='page-selector'
    >
      <Box background='none' direction='row' justifyContent='end'>
        {currentPage > 1 && (
          <>
            <Button variant='text' shape='square' onClick={() => moveToPage(1)}>
              <ChevronDoubleLeftFilled />
            </Button>
            <Button
              variant='text'
              shape='square'
              onClick={() => moveToPage(currentPage - 1)}
              data-testid='prev-page'
            >
              <ChevronLeftFilled />
            </Button>
          </>
        )}
      </Box>

      <Box
        background='none'
        direction='row'
        alignItems='center'
        justifyContent='center'
        style={{ flex: '0 1 min-content' }}
      >
        {pagesArray.map((page) => (
          <Button
            key={page}
            variant='text'
            onClick={() => moveToPage(page)}
            style={{ minWidth: '1.5rem' }}
          >
            <Typography
              variant='body1'
              sx={
                currentPage === page
                  ? {
                      color: theme.palette.info.dark,
                      textDecoration: `${theme.palette.primary.main} underline 0.125rem`,
                      textUnderlineOffset: '0.5rem',
                    }
                  : {}
              }
            >
              {page.toString().padStart(2, '0')}
            </Typography>
          </Button>
        ))}
      </Box>

      <Box background='none' direction='row' justifyContent='start'>
        {currentPage < totalPages && (
          <>
            <Button
              variant='text'
              color='primary'
              shape='square'
              onClick={() => moveToPage(currentPage + 1)}
              data-testid='next-page'
            >
              <ChevronRightFilled />
            </Button>
            <Button
              variant='text'
              color='primary'
              shape='square'
              onClick={() => moveToPage(totalPages)}
            >
              <ChevronDoubleRightFilled />
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export const MemoizedPageSelector = memo(PageSelector);
