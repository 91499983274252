import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { ReactNode } from 'react';
import { Button } from '../button';

export type CustomActionModalProps = {
  title?: string;
  icon?: ReactNode;
  open?: boolean;
  children?: string | ReactNode;
  actionButton?: ReactNode;
  size?: 'small' | 'medium' | 'large';
  onClose?: () => void;
  style?: React.CSSProperties;
};

export const CustomActionModal = ({
  title,
  icon,
  open,
  children,
  actionButton,
  size = 'medium',
  onClose,
  style
}: CustomActionModalProps) => {
  const _minHeights: Record<CustomActionModalProps['size'], string> = {
    small: '20rem',
    medium: '30rem',
    large: '45rem',
  };
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          minWidth: '37.125rem',
          maxWidth: '45rem',
          minHeight: _minHeights[size],
          maxHeight: '60rem',
          ...style,
        },
      }}
    >
      <DialogTitle>
        {!!icon && icon}
        {title}
      </DialogTitle>
      <DialogContent>
        {typeof children === 'string' ? (
          <Typography variant='body1'>{children}</Typography>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions>
        {actionButton}
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CustomActionModal;
